export const environment = {
  production: true,
  log: true,
  application: {
    name: "storefront.web",
    ssrEnabled: true,
    defaultStoreDomain: 'iplugn.store',
    localServerPrivateIPs: '18.142.84.114,10.10.21.136',
    localServerUrls: 'localhost:4200,localhost:4000,,localhost:33982'
  },
  oAuthConfig: {
    issuer: 'https://plugin-auth-sz.azurewebsites.net/',
    clientId: 'Whyzr_App',
    scope: 'offline_access Whyzr',
  },
  apis: {
    default: {
      url: 'https://plugin-pro-sz.azurewebsites.net',
      rootNamespace: 'Whyzr',
    },
  },
  firebase: {
    apiKey: "AIzaSyDr9QShLb7VVs9jPwdMNG3BGr-DWQE2i0E",
    authDomain: "emenubranch-e8f26.firebaseapp.com",
    projectId: "emenubranch-e8f26",
    storageBucket: "emenubranch-e8f26.appspot.com",
    messagingSenderId: "67900254731",
    appId: "1:67900254731:web:69eb65f28f0b93fa1f750e",
    measurementId: "G-987C4C5JVJ"
  },
  storeId: '',
  googleAPI: '',
  ignoredefaultStoreConfig: true,
  provider: {
    name: 'iplugn',
    nameAr: 'منصة إي بلجن',
    logo: 'https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_200,h_72/https://iplugn.com/wp-content/uploads/2022/01/cropped-tg-site-header-bottom.png',
    website: 'https://iplugn.com',
    registrationUrl: 'https://my.iplugn.com/account/register'
  }
};
