import { Injectable } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';
import { CartLineStoreDto, CartService, CartStoreDto } from '@tajer/api';
import { CustomerAppService } from './customer-app.service';
import { Subject } from 'rxjs';
import { CurrencyAppService } from './currency-app.service';

@Injectable({
  providedIn: 'root'
})
export class CartAppService {
  //#region Cart
  private myShoppingCart: CartStoreDto;
  //rewardPoints
  loadingPoints: boolean;
  rewardPoints = 0;
  openGuestAlert$: Subject<number>= new Subject<number>();
  sideMenuCartOpened: boolean;
  //#endregion

  constructor(private cartService: CartService,
    private customerAppService: CustomerAppService,
    private currencyAppService: CurrencyAppService,
    private storeAppService: StoreAppService) { }

  getMyCart() {
    return this.cartService.getMy({
      token: this.storeAppService.storeConfig?.token,
      currencyId: this.currencyAppService.requestCurrencyId,
    }).toPromise();
  }

  getMyCartMarketplace(tenantId: string) {
    return this.cartService.getMarketplace({
      tenantId: tenantId,
      token: this.storeAppService.storeConfig?.token,
      currencyId: this.currencyAppService.requestCurrencyId,
    }
    ).toPromise()
  }

  public get shoppingCart(): CartStoreDto {
    let shoppingCart = null;
    if (this.myShoppingCart) {
        shoppingCart = this.myShoppingCart;
    }
    return shoppingCart;
  }

  public get shoppingCartTotal(): number {
      let total = 0;
      if (this.myShoppingCart) {
          total = this.myShoppingCart.total;
      }
      return total;
  }

  public set shoppingCart(shoppingCart: CartStoreDto) {
    this.myShoppingCart = shoppingCart;
  }

  public get shoppingCartCount(): number {
    let shoppingCartCount = 0;
    if (this.shoppingCart && this.shoppingCart.cartLines) {
        shoppingCartCount = this.getTotalQuantity(this.shoppingCart.cartLines);
    }
    return shoppingCartCount;
  }

  private getTotalQuantity(lines: CartLineStoreDto[]): number {
    if (lines.length == 0) {
        return 0;
    }
    return lines.map(x => x.quantity).reduce((a, b) => (a + b));
  }

  getItemQuantity(itemId: string): number {
    if (!itemId || !this.shoppingCart || !this.shoppingCart.cartLines) {
        return 0;
    }
    const cartLines = this.shoppingCart.cartLines.filter(x => x.item.id == itemId);
    return this.getTotalQuantity(cartLines);
  }

  getEstimateRewordPoints(isSideMenu: boolean) {
    this.cartService.getEstimatedEarnedPoints({
      token: this.storeAppService.storeConfig?.token,
      currencyId: this.currencyAppService.requestCurrencyId,
    }).toPromise().then((res) => {
      this.rewardPoints = res.points;
      this.loadingPoints = false;
      if (!this.customerAppService.hasLoggedIn && this.rewardPoints > 0 && this.storeDesignOptions?.showLoyaltyProgrameAlertForGuest && !isSideMenu) {
        this.openGuestAlert$.next(this.rewardPoints);
      };
    }, async (error) => {
      this.loadingPoints = false;
      //await this.utility.showError(error);
    });
  }

  private get storeDesignOptions(): any {
    return this.storeAppService?.storeConfig?.theme?.options;
  }
}
