<ion-app *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'">
    <ion-content id=ion-main-content>
        <app-header [showMenu]="true" (search)="onSearch($event)"></app-header>
        <router-outlet></router-outlet>
        <!--replaced content <app-footer *ngIf="!isPlatformHybrid && !templateConfig.hideFooter"></app-footer> -->
        <app-footer *ngIf="!storeDesignOptions?.hideFooter"></app-footer>
    </ion-content>
</ion-app>

<ng-container *ngIf="currentTheme && currentTheme != 'ionic' && currentTheme != 'customizedIonic'">
    <app-header [showMenu]="true" (search)="onSearch($event)" [enableBack]="true"></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!storeDesignOptions?.hideFooter"></app-footer>
</ng-container>

