import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemWideCardComponent } from './item-wide-card.component';
import {
  IonicItemWideCardModule,
  MerchItemWideCardModule,
  RestaurantsItemWideCardModule,
} from '@storefront/ng.themes';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@NgModule({
  declarations: [ItemWideCardComponent],
  imports: [
    CommonModule,
    IonicItemWideCardModule,
    MerchItemWideCardModule,
    RestaurantsItemWideCardModule,
    SharedModule,
  ],
  exports: [ItemWideCardComponent],
})
export class ItemWideCardModule {}
