import { Injectable, Injector } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';
import { AddressService, CountryDto, CountryOptionsDto, CountryService, DhlExpressAddressValidateRequestDto, DhlExpressDeliveryMethodService, GeoAreaDto, GeoAreaService, GeoAreasRequestDto, OutletStoreDto } from '@tajer/api';
import { CustomerAppService } from './customer-app.service';
import { TranslateAppService } from './translate-app.service';

@Injectable({
  providedIn: 'root'
})
export class AddressAppService {

  //#region variables
  _selectedCountryOptions: CountryOptionsDto;
  _selectedCountry: CountryDto;
  _selectedState: GeoAreaDto;
  _selectedCity: GeoAreaDto;
  _selectedDistrict: GeoAreaDto;
  defaultChanged: boolean = false;
  tempObj: any = {
    selectedCountryOptions: null,
    selectedCountry: null,
    selectedState: null,
    selectedCity: null,
    selectedDistrict: null
  };
  //#endregion

  constructor(
    injector: Injector,
    private addressService: AddressService,
    private dhlExpressDeliveryMethodService: DhlExpressDeliveryMethodService,
    private geoAreaService: GeoAreaService,
    private countryService: CountryService,
    private storeAppService: StoreAppService,
    private customerAppService: CustomerAppService,
    private translateAppService: TranslateAppService
  ) {
  }


  public get selectedCountryOptions(): CountryOptionsDto {
    return this.tempObj['selectedCountryOptions'] ? this.tempObj['selectedCountryOptions'] : this._selectedCountryOptions;
  }
  public get selectedCountry(): CountryDto {
    return this.tempObj['selectedCountry'] ? this.tempObj['selectedCountry'] : this._selectedCountry;
  }
  public get selectedState(): GeoAreaDto {
    return this.tempObj['selectedState'] ? this.tempObj['selectedState'] : this._selectedState;
  }
  public get selectedCity(): GeoAreaDto {
    return this.tempObj['selectedCity'] ? this.tempObj['selectedCity'] : this._selectedCity;
  }
  public get selectedDistrict(): GeoAreaDto {
    return this.tempObj['selectedDistrict'] ? this.tempObj['selectedDistrict'] : this._selectedDistrict;
  }


  public set selectedCountryOptions(v: CountryOptionsDto) {
    this.tempObj['selectedCountryOptions'] = v;
  }
  public set selectedCountry(v: CountryDto) {
    this.tempObj['selectedCountry'] = v;
  }
  public set selectedState(v: GeoAreaDto) {
    this.tempObj['selectedState'] = v;
  }
  public set selectedCity(v: GeoAreaDto) {
    this.tempObj['selectedCity'] = v;
  }
  public set selectedDistrict(v: GeoAreaDto) {
    this.tempObj['selectedDistrict'] = v;
  }

  dhlAddressValidate(addressData: DhlExpressAddressValidateRequestDto){
    return this.dhlExpressDeliveryMethodService.getAddressValidate(addressData);
  }

  getAddresses() {
    if (this.customerAppService?.customer) {
      return this.addressService.getList({
        customerId: this.customerAppService?.customer?.id,
        maxResultCount: 999
      }).toPromise();
    }
    else {
      return new Promise(async (resolve, reason) => {
        resolve([]);
      }) as any;
    }
  }

  getGeoAreas(input: GeoAreasRequestDto) {
    if (!input.countryId) {
      return null;
    }
    return this.geoAreaService.getList({
      disabled: false,
      maxResultCount: 999,
      ...input
    } as GeoAreasRequestDto);
  }

  getCountries() {
    return this.countryService.getAll({ disabled: false });
  }

  isAddressValid(address: any) {
    let result = false;
    if (address?.id) {
      result = true;
    } else if (this.selectedCountryOptions) {
      if (address?.countryId
        && (!this.selectedCountryOptions.stateOrProvinceFieldRquired || (this.selectedCountryOptions.stateOrProvinceFieldRquired && (address?.stateProvinceId || address?.stateProvinceName)))
        && (!this.selectedCountryOptions.cityFieldRquired || (this.selectedCountryOptions.cityFieldRquired && (address?.cityId || address?.cityName)))
        && (!this.selectedCountryOptions.districtFieldRquired || (this.selectedCountryOptions.districtFieldRquired && (address?.districtId || address?.zipPostalCode)))
        && (!this.selectedCountryOptions.address1FieldRquired || (this.selectedCountryOptions.address1FieldRquired && address?.address1))
        && (!this.selectedCountryOptions.address2FieldRquired || (this.selectedCountryOptions.address2FieldRquired && address?.address2))
        && (!this.selectedCountryOptions.address3FieldRquired || (this.selectedCountryOptions.address3FieldRquired && address?.address3))
        && (!this.selectedCountryOptions.firstNameFieldRquired || (this.selectedCountryOptions.firstNameFieldRquired && address?.firstName))
        && (!this.selectedCountryOptions.middleNameFieldRquired || (this.selectedCountryOptions.middleNameFieldRquired && address?.middleName))
        && (!this.selectedCountryOptions.lastNameFieldRquired || (this.selectedCountryOptions.lastNameFieldRquired && address?.lastName))
        && (!this.selectedCountryOptions.phoneNumberFieldRquired || (this.selectedCountryOptions.phoneNumberFieldRquired && address?.phoneNumber))
        && (!this.selectedCountryOptions.emailFieldRquired || (this.selectedCountryOptions.emailFieldRquired && address?.email))
        && (!this.selectedCountryOptions.companyFieldRquired || (this.selectedCountryOptions.companyFieldRquired && address?.company))
      ) {
        result = true;
      }
    }
    return result;
  }
  isAddressValidPartially(address: any) {
    let result = false;
    if (address?.id) {
      result = true;
    } else if (this.selectedCountryOptions) {
      if (address?.countryId
        && (!this.selectedCountryOptions.stateOrProvinceFieldRquired || (this.selectedCountryOptions.stateOrProvinceFieldRquired && (address?.stateProvinceId || address?.stateProvinceName)))
        && (!this.selectedCountryOptions.cityFieldRquired || (this.selectedCountryOptions.cityFieldRquired && (address?.cityId || address?.cityName)))
        && (!this.selectedCountryOptions.districtFieldRquired || (this.selectedCountryOptions.districtFieldRquired && (address?.districtId || address?.zipPostalCode)))
      ) {
        result = true;
      }
    }
    return result;
  }

  getLocalName(fieldName: any, fieldLocalName: string): string {
    let name = fieldName;
    let storeLangs = this.storeAppService.storeConfig?.country?.languages;
    if (storeLangs && storeLangs.length > 0 && storeLangs[0] == this.translateAppService.currentLang && fieldLocalName) {
      name = fieldLocalName;
    }
    return name;
  }

  getAddressRecipient(address: any): string {
    return (address?.firstName ? address.firstName : '') +
      (address?.middleName ? ' ' + address.middleName : '') +
      (address?.lastName ? ' ' + address.lastName : '');
  }

  getLocalNameForEntity(entity: any): string {
    let name = entity?.name;
    let storeLangs = this.storeAppService.storeConfig?.country?.languages;
    if (storeLangs && storeLangs.length > 0 && storeLangs[0] == this.translateAppService.currentLang && entity?.localName) {
      name = entity?.localName;
    }
    return name;
  }

  getAddressSummary(address: any): string {
    const addressArr = [
      (this.getLocalName(address?.countryName, address?.countryLocalName) ? this.getLocalName(address?.countryName, address?.countryLocalName) :
        this.getLocalNameForEntity(this.selectedCountry) ? this.getLocalNameForEntity(this.selectedCountry) : ''),
      (this.getLocalName(address?.stateProvinceName, address?.stateProvinceLocalName) ? this.getLocalName(address?.stateProvinceName, address?.stateProvinceLocalName) :
        this.getLocalNameForEntity(this.selectedState) ? this.getLocalNameForEntity(this.selectedState) : ''),
      (this.getLocalName(address?.cityName, address?.cityLocalName) ? this.getLocalName(address?.cityName, address?.cityLocalName) :
        this.getLocalNameForEntity(this.selectedCity) ? this.getLocalNameForEntity(this.selectedCity) : ''),
      (address?.districtId ? this.getLocalName(address?.districtName, address?.districtLocalName) : ''),
      (address?.zipPostalCode ? address?.zipPostalCode :
        this.getLocalNameForEntity(this.selectedDistrict) ? this.getLocalNameForEntity(this.selectedDistrict) : '')
    ];
    return addressArr.filter(a => !!a).join(', ');
  }

  getAddressShortSummary(address: any): string {
    var addressArr = [
      (this.getLocalName(address?.stateProvinceName, address?.stateProvinceLocalName) ? this.getLocalName(address?.stateProvinceName, address?.stateProvinceLocalName) :
        this.getLocalNameForEntity(this.selectedState) ? this.getLocalNameForEntity(this.selectedState) : ''),
      (this.getLocalName(address?.cityName, address?.cityLocalName) ? this.getLocalName(address?.cityName, address?.cityLocalName) :
        this.getLocalNameForEntity(this.selectedCity) ? this.getLocalNameForEntity(this.selectedCity) : ''),
      (address?.districtId ? this.getLocalName(address?.districtName, address?.districtLocalName) :
        this.getLocalNameForEntity(this.selectedDistrict) ? this.getLocalNameForEntity(this.selectedDistrict) : '')
    ];
    if (addressArr.every(el => el === '')) {
      addressArr = [
        (this.getLocalName(address?.countryName, address?.countryLocalName) ? this.getLocalName(address?.countryName, address?.countryLocalName) :
          this.getLocalNameForEntity(this.selectedCountry) ? this.getLocalNameForEntity(this.selectedCountry) : '')
      ]
    }
    return addressArr.filter(a => !!a).join(', ');
  }

  resetAddress() {
    this._selectedCountryOptions = null;
    this._selectedCountry = null;
    this._selectedState = null;
    this._selectedCity = null;
    this._selectedDistrict = null;
    this.tempObj = {
      selectedCountryOptions: null,
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      selectedDistrict: null
    };
  }

  setTemp(save?) {

    if (save) {
      this.defaultChanged = true;
      this._selectedCountryOptions = this.tempObj['selectedCountryOptions'];
      this._selectedCountry = this.tempObj['selectedCountry'];
      this._selectedState = this.tempObj['selectedState'];
      this._selectedCity = this.tempObj['selectedCity'];
      this._selectedDistrict = this.tempObj['selectedDistrict'];
    }

    this.tempObj = {
      selectedCountryOptions: null,
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      selectedDistrict: null
    };
  }

  getOutletAddressSummary(outlet: OutletStoreDto): string {
    if (outlet) {
      var addressArr = [
        (this.getLocalName(outlet.address?.cityName, outlet.address?.cityLocalName) ?
          this.getLocalName(outlet.address?.cityName, outlet.address?.cityLocalName) : ''),
        (this.getLocalName(outlet.address?.stateProvinceName, outlet.address?.stateProvinceLocalName) ?
          this.getLocalName(outlet.address?.stateProvinceName, outlet.address?.stateProvinceLocalName) : ''),
        (outlet.address?.address1 ? outlet.address?.address1 : '')
      ];
      if (addressArr.every(el => el === '')) {
        addressArr = [
          (this.getLocalName(outlet?.address?.countryName, outlet?.address?.countryLocalName) ?
            this.getLocalName(outlet?.address?.countryName, outlet?.address?.countryLocalName) : ''),
        ]
      }
      return addressArr.filter(a => !!a).join(', ');
    }
  }

  getOutletLocationLink(outlet: OutletStoreDto): string {
    let googleMapLink: string = '';
    if (outlet?.address?.latitude && outlet?.address?.longitude) {
        googleMapLink = 'https://www.google.com/maps/place/' + outlet.address.latitude + ',' + outlet.address.longitude;
    }
    return googleMapLink;
  }

}
