import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, Optional, Output, SimpleChanges } from '@angular/core';
import { AddressFormConfiguration, AddressFormDesignOptions } from '@storefront/ng.themes/lib/themes/default/default-address-form/models';
import { AddressDto, AddressTarget, CountryDto, CountryIso2Code, CountryOptionsDto, GeoAreaDto, GeoAreaFieldType, GeoAreaType, GeoAreasRequestDto, StoreSupportedCountriesType } from '@tajer/api';
import { GoogleMapsAppService, IPositionMap } from '@tajer/ng.core';
import { DialogRef, overlayConfigFactory } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { take } from 'rxjs';
import { AddressAppService } from 'src/app/shared/app-services/address-app.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { environment } from 'src/environments/environment';
import { GoogleMapComponent, GoogleMapComponentContext } from '../google-map/google-map.component';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent extends BaseComponent {
  //region variables
  desginOptions: AddressFormDesignOptions;
  configuration: AddressFormConfiguration;

  @Input() submited: boolean;
  @Input() isModal: boolean;
  /**
   * if adding address from delivery methods (true) save address in address app service
   */
  @Input() setSelectedAddress: boolean = true;
  @Input() editAddress: any; // AddressDto | OrderAddressDto;
  @Input() getDetailedAddress: boolean;

  @Output() updateAddress = new EventEmitter<AddressDto>();
  address = {
    countryId: null,
    countryName: '',
    stateProvinceId: null,
    cityId: null,
    districtId: null,
    label: 'Home'
  } as AddressDto;
  countryListItems?: CountryDto[];
  countryName = '';
  stateListItems?: Array<any>;
  cityListItems?: Array<any>;
  districtListItems?: Array<any>;
  loadingCountry = false;
  loadingState = false;
  loadingCity = false;
  loadingDistrict = false;

  viewLabel = false;

  fieldOptions: CountryOptionsDto;
  fieldType = GeoAreaFieldType;
  geoAreaType = GeoAreaType;
  storeSupportedCountriesType = StoreSupportedCountriesType;
  //end region variables

  constructor(injector: Injector,
    private addressAppService: AddressAppService,
    private googleMapApp: GoogleMapsAppService,
    private cdr: ChangeDetectorRef,
    @Optional() public dialog: DialogRef<AddressFormComponentContext>
  ) {
    super(injector);
  }

  ngOnInit() {
    this.setIonicThemeOptions();
    this.setModalContext();
    //initial map
    if (this.storeOptions.addressOnMapEnabled) {
      this.googleMapApp.init(environment.googleAPI);

      this.googleMapApp.somethingWrongAlert$.subscribe(
        data => {
          this.openSomethingWrongAlert(this.trsnalteApp.translate(data))
        }
      )
    }

    if (!this.editAddress) {
      this.getCountries();
    }
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions.color,
      frontColor: this.storeDesignOptions.frontColor,
      merchQuickCheckout: this.merchQuickCheckout,
    };
    this.configuration = {
      addressOnMapEnabled: this.storeOptions.addressOnMapEnabled
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['editAddress']?.currentValue) {
      this.address = await changes['editAddress'].currentValue;
      this.getCountries();
      this.cdr.detectChanges();
      if (this.address.longitude && this.address.latitude && this.storeOptions.addressOnMapEnabled) {
        this.address.districtName = this.address.zipPostalCode;
        this.cdr.detectChanges();
        this.initialMap();
      }
    }
  }

  showGoogleMapModel() {
    const componentContext = <GoogleMapComponentContext>{
      countries: this.countryListItems,
      size: 'sm',
    };

    const dialogRef = this.modal.open(
      GoogleMapComponent,
      overlayConfigFactory(componentContext)
    );

    dialogRef.result.then(
      (result) => {
        if (result) {
          result.target = AddressTarget.Customer;
          this.address = { ...this.address, ...result };
          this.overrideFieldOptions();
          this.cdr.detectChanges();
          this.initialMap();
        }
      },
      () => {
        return;
      }
    );
  }

  initialMap(): void {
    const map = document.getElementById('mapView');
    const position: IPositionMap = { lat: +this.address.latitude, lng: +this.address.longitude };
    this.googleMapApp
      .getLibraryStatus()
      .pipe(take(1))
      .subscribe(value => {
        if (value) {
          this.googleMapApp.viewMapWithPosition(position, this.address.accuracy, map);
        }
      });
  }

  overrideFieldOptions() {
    if (this.address.longitude != null && this.address.latitude != null) {
      this.fieldOptions.stateOrProvinceFieldType = this.fieldType.Text;
      this.fieldOptions.cityFieldType = this.fieldType.Text;
      this.fieldOptions.districtFieldType = this.fieldType.Text;
      this.cdr.detectChanges();
    }
  }

  resetAddressPosition() {
    this.address.longitude = null;
    this.address.latitude = null;
  }

  onSelectCountry() {
    // debugger
    if (!this.address.countryId) {
      this.stateListItems = [];
      this.cityListItems = [];
      this.districtListItems = [];
      return;
    }
    this.fieldOptions = { ...this.fieldOptions, ...this.countryListItems?.find(cn => cn.id === this.address.countryId)?.options };

    //override on map
    this.overrideFieldOptions();
    // reset hidden fields values because fields options changed
    this.resetFields();

    this.addressAppService.selectedCountryOptions = this.fieldOptions;
    this.addressAppService.selectedCountry = this.countryListItems?.find(cn => cn.id === this.address.countryId);
    this.countryName = this.addressAppService.getLocalNameForEntity(this.addressAppService.selectedCountry);
    if (this.fieldOptions?.showStateOrProvinceField && this.fieldOptions?.stateOrProvinceFieldType === this.fieldType.Dropdown) {
      this.getStates();
    }
  }

  onSelectedState() {
    if (this.setSelectedAddress) {
      if (this.fieldOptions?.stateOrProvinceFieldType === this.fieldType.Dropdown) {
        this.addressAppService.selectedState = this.stateListItems?.find(cn => cn.id === this.address.stateProvinceId);
      } else {
        this.addressAppService.selectedState = {} as GeoAreaDto;
        this.addressAppService.selectedState.name = this.address.stateProvinceName;
      }
    }
    if (this.fieldOptions?.showCityField && this.fieldOptions?.cityFieldType === this.fieldType.Dropdown) {
      this.getCities();
    }
  }

  onSelectedCity() {
    if (this.setSelectedAddress) {
      if (this.fieldOptions?.cityFieldType === this.fieldType.Dropdown) {
        this.addressAppService.selectedCity = this.cityListItems?.find(cn => cn.id === this.address.cityId);
      } else {
        this.addressAppService.selectedCity = {} as GeoAreaDto;
        this.addressAppService.selectedCity.name = this.address.cityName;
      }
    }
    if (this.fieldOptions?.showDistrictField && this.fieldOptions?.districtFieldType === this.fieldType.Dropdown) {
      this.getDistrict();
    }
  }

  onSelectedDistrict() {
    if (this.setSelectedAddress) {
      if (this.fieldOptions?.districtFieldType === this.fieldType.Dropdown) {
        this.addressAppService.selectedDistrict = this.districtListItems?.find(cn => cn.id === this.address.districtId);
      } else {
        this.addressAppService.selectedDistrict = {} as GeoAreaDto;
        this.addressAppService.selectedDistrict.name = this.address.zipPostalCode;
      }
    }
  }

  getCountries() {
    this.loadingCountry = true;
    this.addressAppService.getCountries().subscribe((response) => {
      this.loadingCountry = false;
      if (response.items?.length > 0) {
        if (this.storeOptions.deliverySupportedCountriesType === this.storeSupportedCountriesType.AllCountries) {
          this.countryListItems = response.items;
        } else if (this.storeOptions.deliverySupportedCountriesType === this.storeSupportedCountriesType.StoreCountryOnly) {
          if (this.storeAppService.storeConfig.country) {
            this.countryListItems = response.items.filter(country => country.id === this.storeAppService.storeConfig.country?.id);
          }
        } else if (this.storeOptions.deliverySupportedCountriesType === this.storeSupportedCountriesType.OutletCountryOnly) {
          if (this.currentOutlet?.address) {
            this.countryListItems = response.items.filter(country => country.id === this.currentOutlet.address?.countryId);
          }
        }
        if (!this.address.countryId) {
          this.address.countryId = this.countryListItems[0].id;
          this.countryName = this.addressAppService.getLocalNameForEntity(this.countryListItems[0]);
        }
        if (this.router.url.includes('edit')) {
          this.countryName = this.address.countryName;
        }
        this.setAddress();
        this.onSelectCountry();
      }
    }, () => {
      this.loadingCountry = false;
    });
  }

  getStates() {
    this.loadingState = true;
    this.addressAppService.getGeoAreas({
      countryId: this.address.countryId,
      type: this.geoAreaType.StateOrProvince
    } as GeoAreasRequestDto)?.subscribe((response) => {
      this.loadingState = false;
      this.stateListItems = response.items;
      if (response.items.length === 1) {
        this.address.stateProvinceId = response.items[0].id;
        this.setAddress();
        this.onSelectedState();
      }
      if (this.address.cityId) { this.getCities(); }
      if (!this.stateListItems.find(ds => ds.id === this.address.stateProvinceId)) {
        this.address.stateProvinceId = null;
      }
    });
  }

  getCities() {
    this.loadingCity = true;
    this.addressAppService.getGeoAreas({
      countryId: this.address?.countryId,
      parentId: this.address?.stateProvinceId ? this.address?.stateProvinceId : null,
      type: this.geoAreaType.City,
    } as GeoAreasRequestDto)?.subscribe((response) => {
      this.loadingCity = false;
      this.cityListItems = response.items;
      if (response.items.length === 1) {
        this.address.cityId = response.items[0].id;
        this.setAddress();
        this.onSelectedCity();
      }
      if (this.address.districtId) { this.getDistrict(); }
      if (!this.cityListItems.find(ds => ds.id === this.address.cityId)) {
        this.address.cityId = null;
      }
    });
  }

  getDistrict() {
    this.loadingDistrict = true;
    this.addressAppService.getGeoAreas({
      countryId: this.address.countryId,
      parentId: this.address.cityId ?? this.address.cityId,
      type: this.geoAreaType.District
    } as GeoAreasRequestDto)?.subscribe((response) => {
      this.loadingDistrict = false;
      this.districtListItems = response.items;
      if (response.items.length === 1) {
        this.address.districtId = response.items[0].id;
        this.setAddress();
        this.onSelectedDistrict();
      }
      if (!this.districtListItems.find(ds => ds.id === this.address.districtId)) {
        this.address.districtId = null;
      }
    });
  }

  setAddress() {
    if (!this.isModal) {
      if(this.configFeatures.dhlExpressFeature || this.getDetailedAddress){
        this.populateExtraAddressDetails();
      }
      this.updateAddress.emit(this.address);
    }else{
      this.populateExtraAddressDetails();
    }
  }

  populateExtraAddressDetails(){
    if(this.address.countryId){
      let selectedCountry= this.countryListItems.find(c => c.id == this.address.countryId);
      this.address.countryName = selectedCountry?.name;
      this.address.countryTwoLetterIsoCode= CountryIso2Code[selectedCountry?.twoLetterIsoCode];
    }
    if(this.address.cityId){
      let selectedCity =this.cityListItems.find(c => c.id == this.address.cityId);
      this.address.cityName =selectedCity?.name;
      this.address.cityLocalName =selectedCity?.name;
    }
    if(this.address.stateProvinceId){
      let selectedState =this.stateListItems?.find(cn => cn.id === this.address.stateProvinceId);
      this.address.stateProvinceName =selectedState?.name;
      this.address.stateProvinceLocalName =selectedState?.localName;
    }
    if(this.address.districtId){
      let selectedDistrict =this.districtListItems?.find(cn => cn.id === this.address.districtId)
      this.address.districtName =selectedDistrict?.name;
      this.address.districtLocalName =selectedDistrict?.localName;
    }
  }

  input() {
    this.submited = false;
    if (this.addressAppService.isAddressValid(this.address)) {
      if(this.configFeatures.dhlExpressFeature){
        if(this.address.countryId){
          let selectedCountry= this.countryListItems.find(c => c.id == this.address.countryId);
          this.address.countryName = selectedCountry?.name;
          this.address.countryTwoLetterIsoCode= CountryIso2Code[selectedCountry?.twoLetterIsoCode];
        }
        if(this.address.cityId){
          this.address.cityName =this.cityListItems.find(c => c.id == this.address.cityId)?.name;
        }
      }
      this.dialog?.close(this.address);
      if(this.isBrowser){
        document.body.classList.remove("modal-open");
      }
      this.resetForm();
    } else {
      this.submited = true;
    }
  }

  close() {
    this.dialog?.close();
    if(this.isBrowser){
      document.body.classList.remove("modal-open");
    }
    this.resetForm();
  }

  resetForm() {
    this.address = {
      countryId: null,
      stateProvinceId: null,
      cityId: null,
      districtId: null,
      label: 'Home'
    } as AddressDto;
    this.loadingCountry = false;
    this.loadingState = false;
    this.loadingCity = false;
    this.loadingDistrict = false;
    this.countryName = '';
  }

  resetFields() {
    if (!this.fieldOptions?.showStateOrProvinceField) {
      this.address.stateProvinceId = null;
      this.address.stateProvinceName = null;
      this.addressAppService.selectedState = null;
    } else {
      if (this.fieldOptions?.stateOrProvinceFieldType === this.fieldType.Dropdown) {
        this.address.stateProvinceName = null;
      } else {
        this.address.stateProvinceId = null;
      }
    }
    if (!this.fieldOptions?.showCityField) {
      this.address.cityId = null;
      this.address.cityName = null;
      this.addressAppService.selectedCity = null;
    } else {
      if (this.fieldOptions?.cityFieldType === this.fieldType.Dropdown) {
        this.address.cityName = null;
      } else {
        this.address.cityId = null;
      }
    }
    if (!this.fieldOptions?.showDistrictField) {
      this.address.districtId = null;
      this.address.zipPostalCode = null;
      this.addressAppService.selectedDistrict = null;
    } else {
      if (this.fieldOptions?.districtFieldType === this.fieldType.Dropdown) {
        this.address.zipPostalCode = null;
      } else {
        this.address.districtId = null;
      }
    }

    if (!this.fieldOptions?.showAddress1Field) {
      this.address.address1 = null;
    }
    if (!this.fieldOptions?.showAddress2Field) {
      this.address.address2 = null;
    }
    if (!this.fieldOptions?.showAddress3Field) {
      this.address.address3 = null;
    }
    if (!this.fieldOptions?.showFirstNameField) {
      this.address.firstName = null;
    }
    if (!this.fieldOptions?.showMiddleNameField) {
      this.address.middleName = null;
    }
    if (!this.fieldOptions?.showLastNameField) {
      this.address.lastName = null;
    }
    if (!this.fieldOptions?.showCompanyField) {
      this.address.company = null;
    }
    if (!this.fieldOptions?.showEmailField) {
      this.address.email = null;
    }
    if (!this.fieldOptions?.showPhoneNumberField) {
      this.address.phoneNumber = null;
    }
  }

  private async openSomethingWrongAlert(message: string, header: string = null) {
    const dialogRef = this.modal
      .alert()
      .title(header == null ? this.trsnalteApp.translate('SomethingWrongAlertHeader') : header)
      .okBtn(this.trsnalteApp.translate('Ok'))
      .okBtnClass('modal-ok-btn')
      .isBlocking(true)
      .showClose(false)
      .body(this.trsnalteApp.translate('TryAgain') + '\n' + message)
      .dialogClass("modal-x-sm")
      .open();

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  setModalContext() {
    if (this.dialog) {
      this.isModal = this.dialog?.context.isModal;
    }
  }

}

export class AddressFormComponentContext extends BSModalContext {
  constructor() {
    super();
  }

  isModal: boolean;
}
