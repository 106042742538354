import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  NgModule,
} from '@angular/core';

import {
  BrowserModule,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';
import { DatePipe, Location } from '@angular/common';

import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieModule } from 'ngx-cookie';

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { Localize } from 'src/assets/languages/localize';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MainLayoutModule } from './shared/components/main-layout/main-layout.module';
import { initializeApp } from './shared/app-services/initialize-app';
import { ApiInterceptor } from './shared/interceptors';
import { TajerApiModule } from '@tajer/api';
import { environment } from 'src/environments/environment';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppAuthStorage } from './shared/app-services/app-auth.storage';
import { ModalModule } from 'ngx-modialog-7';
import { BootstrapModalModule } from 'ngx-modialog-7/plugins/bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxNavDrawerModule } from '@ngx-lite/nav-drawer';
import { SideMenuCartModule } from './components/side-menu-cart/side-menu-cart.module';
import { ControlPanelSideBarModule } from './components/control-panel-side-bar/control-panel-side-bar.module';
import { SideMenuModule } from './components/side-menu/side-menu.module';
import { ItemQuickViewModule } from './components/item-quick-view/item-quick-view.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/languages/locales/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TajerApiModule.forRoot({
      environment: environment,
      registerLocaleFn: null,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LocalizeRouterModule.forRoot(routes, {
      initialNavigation: false,
      alwaysSetPrefix: false,
      parser: {
        provide: LocalizeParser,
        useFactory: localizeRouterFactory(),
        deps: [
          TranslateService,
          Location,
          LocalizeRouterSettings,
          TransferState,
        ],
      },
    }),
    OAuthModule.forRoot(),
    MainLayoutModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
    NgxNavDrawerModule,
    SideMenuCartModule,
    SideMenuModule,
    ControlPanelSideBarModule,
    ItemQuickViewModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: ApiInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [Injector],
      useFactory: initializeApp,
    },
    { provide: OAuthStorage, useClass: AppAuthStorage },
    DatePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

function localizeRouterFactory(): Function {
  return (
    translate: TranslateService,
    location: Location,
    settings: LocalizeRouterSettings,
    transferState: TransferState
  ) => {
    settings.defaultLangFunction = (
      languages: any,
      cachedLang: any,
      browserLang: any
    ) => {
      const dataKey = makeStateKey('app_basic_configs'); // with SSR only
      const appConfigs = transferState.get<any>(dataKey, null); // with SSR only
      //const appConfigs = null;
      const storeLanguages = appConfigs?.languages
        ? appConfigs?.languages
        : Localize.locales;
      // Get cached language if there is any. Owhterise, pick the first lang in the store config
      // cachedLang || // TODO: fix getting cachedLang in browser to enable it or remote the code
      return (
        (cachedLang || storeLanguages && storeLanguages[0]) || languages[0] || browserLang
      );
    };

    return new ManualParserLoader(
      translate,
      location,
      settings,
      Localize.locales,
      Localize.prefix
    );
  };
}
