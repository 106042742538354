<ng-container *ngIf="!isModal">
  <ng-content *ngTemplateOutlet="mainContent"></ng-content>
</ng-container>

<ng-container *ngIf="isModal">
  <div class="modal-content">
    <!--header-->
    <div class="modal-header">
      <button type="button" [class]="isRtl ? 'close close-left' : 'close'" (click)="dismiss(null)">&times;</button>
      <h3 class="modal-title">{{translations?.SelectAddress}}</h3>
    </div>
    <!--body-->
    <div class="modal-body">
      <ng-content *ngTemplateOutlet="mainContent"></ng-content>
    </div>
  </div>
</ng-container>

<ng-template #mainContent>
  <lib-default-address-book-card
  [desginOptions]="desginOptions"
  [configuration]="configuration"
  [translations]="translations"
  [addresses]="addresses"
  [viewActions]="viewActions"
  [isModal]="isModal"
  [loading]="loading"
  (dismissAddress)="dismiss($event)"
  [isRtl]="isRtl"
  >
    <ng-template #addressCard let-address>
      <app-address-card [address]="address" [viewActions]="viewActions" (delete)="delete(address.id)"></app-address-card>
    </ng-template>

    <ng-template #loadingPage>
      <app-loading style="display: block;height: 325px;width:100%"></app-loading>
    </ng-template>
  </lib-default-address-book-card>
</ng-template>