import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlPanelSideBarComponent } from './control-panel-side-bar.component';
import { DefaultControlPanelSideBarModule } from '@storefront/ng.themes';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@NgModule({
  declarations: [ControlPanelSideBarComponent],
  imports: [CommonModule, SharedModule, DefaultControlPanelSideBarModule],
  exports: [ControlPanelSideBarComponent],
})
export class ControlPanelSideBarModule {}
