export type EventHandler = (...args: any[]) => void;

export abstract class TajerPlugin {
    options: Record<string, any>;
    appData: Record<string, any>;
    private eventHandlers: Map<string, Set<EventHandler>>;

    constructor(options?: Record<string, any>) {
        this.appData = {};
        this.options = options || {};
        this.eventHandlers = new Map();
    }

    init(appData: Record<string, any>): void {
        this.appData = appData;
    }

    on(eventName: string, handler: EventHandler): void {
        if (!this.eventHandlers.has(eventName)) {
            this.eventHandlers.set(eventName, new Set());
        }
        this.eventHandlers.get(eventName)?.add(handler);
    }

    off(eventName: string, handler: EventHandler): void {
        if (this.eventHandlers.has(eventName)) {
            this.eventHandlers.get(eventName)?.delete(handler);
        }
    }

    trigger(eventName: string, ...args: any[]): void {
        if (this.eventHandlers.has(eventName)) {
            for (const handler of (this.eventHandlers.get(eventName) ?? new Set())) {
                handler(...args);
            }
        }
    }

    // Define other methods and hooks as needed.
}
