import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickupDatePickerComponent } from '../pickup-date-picker/pickup-date-picker.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';



@NgModule({
  declarations: [
    PickupDatePickerComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    PickupDatePickerComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA 
  ],
})
export class PickupDatePickerModule { }
