import { StoreAppService } from "@storefront/ng.core";
import { TajerPlugin } from "./core/tajer-plugin";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { environment } from "src/environments/environment";
import { OrderAppService } from "../shared/app-services/order-app.service";

declare let snaptr: Function;

@Injectable({
    providedIn: 'root',
})
export class SnapChatPlugin extends TajerPlugin {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private orderAppService: OrderAppService,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.addSnapChatScript(this.storeAppService.storeConfig.externalServices?.snapChatId, '');

        // Register event handlers
        this.on('implementPageViewEvent', this.implementPageViewEvent.bind(this));
        this.on('implementPurchaseEvent', this.implementPurchaseEvent.bind(this));
        this.on('implementAddToCartEvent', this.implementAddToCartEvent.bind(this));
        this.on('implementSignupEvent', this.implementSignupEvent.bind(this));
    }

    implementAddToCartEvent() {
        try {
            snaptr('track', 'ADD_CART');
        } catch (error) {
            if (environment.log) { console.log(error); }
        }
    }

    implementPageViewEvent() {
        try {
            snaptr('track', 'PAGE_VIEW');
            snaptr('track', 'VIEW_CONTENT');
        } catch (error) {
            if (environment.log) { console.log(error) }
        }
    }

    implementPurchaseEvent() {
        try {
            snaptr('track', 'PURCHASE', {
                'currency': this.storeAppService.storeConfig?.currency?.isoCode,
                'price': this.orderAppService.shippingCartTotal,
                'transaction_id': ''
            });
        } catch (error) {
            if (environment.log) { console.log(error) }
        }
    }

    implementSignupEvent() {
        try {
            snaptr('track', 'SIGN_UP');
        } catch (error) {
            if (environment.log) { console.log(error) }
        }
    }

    addSnapChatScript(snapChatId: string, userEmail: string) {
        const snapChatScript = `(function(e,t,n){
          if(e.snaptr)return;var a=e.snaptr=function()
          {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
          a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
          r.src=n;var u=t.getElementsByTagName(s)[0];
          u.parentNode.insertBefore(r,u);})(window,document,
          'https://sc-static.net/scevent.min.js');
          snaptr('init', '${snapChatId}', {
            'user_email': '${userEmail}'
          })`;
        var script = this.document.createElement('script');
        script.setAttribute("type", "text/javascript");
        script.innerHTML = snapChatScript;
        this.document.head.appendChild(script);
    }
}
