import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Injectable({
  providedIn: 'root'
})
export class NewsLetterService {
  subscribeLoading: boolean;
  subscribeDone: boolean;
  mailChimpEndpoint: string;
  mailForm: string;
  constructor(private http: HttpClient,
    private storeAppService:StoreAppService) {
    this.mailChimpEndpoint = this.storeAppService.storeConfig.externalServices?.mailChimp?.replace(/\/post\?/, '/post-json?').replace(/&amp;/g, '&');;
  }

  subscribe(email) {
    return new Promise<any>((resolve, reject) => {
      if (this.mailChimpEndpoint) {
        const u = this.mailChimpEndpoint.match(/u=(\w+)/)[1];
        const id = this.mailChimpEndpoint.match(/id=(\w+)/)[1];
        this.subscribeLoading = true;
        this.subscribeDone = false;
        const params = new HttpParams()
          .set('EMAIL', email)
          .set(`b_${u}_${id}`, '')
          .set('subscribe', 'Subscribe'); // hidden input name
        const mailChimpUrl = this.mailChimpEndpoint + '&' + params.toString();

        // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
        this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c')
          .subscribe(
            (response) => {
              this.subscribeLoading = false;
              if (response.result && response.result !== 'error') {
                this.subscribeDone = true;
                resolve(response);
              }
              else {
                reject("SubscribingFailed");
              }
            },
            (error) => {
              this.subscribeLoading = false;
              reject("SubscribingFailed");
            });
      } else {
        reject("MailChimpApiNotFound");
      }
    });
  }
}
