import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FavoriteAppService {
  //#region varibles
  addOrRemoveFavoriteItemId: string;
  addOrRemoveFavoriteLoading: boolean = false;

  //#endregion

  constructor() { }
}
