import { Component, Injector, Optional } from '@angular/core';
import { OutletSectionRequestDto, OutletSectionService, OutletSectionStoreDto, OutletTableDto, OutletTableStoreDto } from '@tajer/api';
import { DialogRef } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-choose-table-modal',
  templateUrl: './choose-table-modal.component.html',
  styleUrls: ['./choose-table-modal.component.scss']
})
export class ChooseTableModalComponent extends BaseComponent {

  //#region variables
  sections: OutletSectionStoreDto[];
  selectedTable: OutletTableStoreDto = null;
  //#endregion

  constructor(
    injector: Injector,
    @Optional() public dialog: DialogRef<ChooseTableModalComponentContext>,
    private outletSectionService: OutletSectionService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getOutletSections();
  }

  getOutletSections() {
    this.outletSectionService.getAll({
      maxResultCount: 999,
      outletId: this.currentOutlet?.id,
      status: 0,
    } as OutletSectionRequestDto).toPromise().then((response) => {
      this.sections = response?.items?.filter(row => row.outletTables?.length != 0);
      this.selectedTable = this.orderAppService.order.outletTable;
    })
  }

  onSelect(event) {
    this.selectedTable= event.detail.value;
  }

  close() {
    this.dialog?.close();
    if(this.isBrowser){
      document.body.classList.remove("modal-open");
    }  
  }

  save() {
    this.orderAppService.order.outletTable = this.selectedTable as OutletTableDto;
    this.dialog?.close();
    if(this.isBrowser){
      document.body.classList.remove("modal-open");
    }
  }
}

export class ChooseTableModalComponentContext extends BSModalContext {
  constructor() {
    super();
  }
}

