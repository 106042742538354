import { Theme } from 'src/app/shared/models/theme';
let theme = new Theme();
theme.name = 'customizedIonic';
theme.addStyleDependency(
  'customizedIonic-css',
  'https://cdn.jsdelivr.net/npm/@ionic/core@7.0.14/css/ionic.bundle.css'
);
theme.addScriptDependency(
  'customizedIonic-script',
  'https://cdn.jsdelivr.net/npm/@ionic/core@7.0.14/dist/ionic/ionic.js'
);
theme.addFontDependency('customizedIonic-font', 'Roboto');
theme.addStyleDependency(
  'swiper-css',
  'https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css'
);

export const customizedIonicTheme = theme;
