<ng-container *ngIf="!isModal">
    <ng-content *ngTemplateOutlet="mainContent"></ng-content>
</ng-container>

<ng-container *ngIf="isModal">
    <div class="modal-content">
      <!--header-->
      <div class="modal-header">
        <button type="button" [class]="isRtl ? 'close close-left' : 'close'"(click)="close()">&times;</button>
        <h3 class="modal-title" *ngIf="editAddress && editDeliveryMethod" [style.color]="storeDesignOptions.color">{{translations?.EnterAddressAndDeliveryMethod}}</h3>
        <h3 class="modal-title" *ngIf="!editAddress"  [style.color]="storeDesignOptions.color">{{translations?.PreferDelievryMethod}}</h3>
      </div>
      <!--body-->
      <div class="modal-body modal-body-flow">
        <ng-content *ngTemplateOutlet="mainContent"></ng-content>
      </div>
      <div class="modal-footer modal-footer-center">
        <button type="submit" [style.background]="storeDesignOptions?.color" [style.color]="storeDesignOptions?.frontColor" type="submit" class="footer-btn" (click)="submit()">{{translations?.Save}}</button>
      </div>
    </div>
</ng-container>

<ng-template #mainContent>
    <lib-default-delivery-methods
    [desginOptions]="desginOptions"
    [translations]="translations"
    [isModal]="isModal"
    [editAddress]="editAddress"
    [editDeliveryMethod]="editDeliveryMethod"
    [loadingAddress]="loadingAddress"
    [submited]="submited"
    [selectedDeliveryMethodId]="selectedDeliveryMethodId"
    [deliveryMethods]="deliveryMethods"
    [deliveryAddress]="deliveryAddress"
    [hasLoggedIn]="customerAppService?.hasLoggedIn"
    [loading]="loading"
    [viewDelivery]="viewDelivery"
    [showAddressForm]="((customerAppService.hasLoggedIn && addresses?.length == 0) || !customerAppService.hasLoggedIn) && (editAddress || !isModal)"
    (submitDel)="submit()"
    >
        <ng-template #progressBar>
            <app-progress-bar *ngIf="!isModal && !merchQuickCheckout"></app-progress-bar>
        </ng-template>

        <ng-template #deliveryAddressCard>
            <app-delivery-address-card [deliveryAddress]="deliveryAddress" (changeAddress)="setDeliveryAddress($event , true)"
            [addresses]="addresses" [loading]="loadingAddress" [submited]="submited" 
            [showAddressForm] ="((customerAppService.hasLoggedIn && addresses?.length == 0) || !customerAppService.hasLoggedIn) && (editAddress || !isModal)" [wideModal]="isModal">
            </app-delivery-address-card>
        </ng-template>

        <ng-template #deliveryMethodCard let-deliveryMethod>
            <app-delivery-method-card (click)="onSelectDeliveryMethod(deliveryMethod)"
                [deliveryMethod]="deliveryMethod" [selected]="selectedDeliveryMethodId === deliveryMethod.id"
                [viewRadio]="true" [viewNote]="true" [showFreeDeliveryLimit]="true">
            </app-delivery-method-card>
        </ng-template>
    </lib-default-delivery-methods>
</ng-template>