import { Injectable } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';
import { Observable, from, switchMap, take, tap } from 'rxjs';
import { CookieAppService } from './cookie-app.service';
import { environment } from 'src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateAppService } from './translate-app.service';
import { OrderAppService } from './order-app.service';
import { CartAppService } from './cart-app.service';
import { CustomerService, DeliveryType } from '@tajer/api';
import { SegmentService } from './segment.service';
import { CustomerAppService } from './customer-app.service';
import { AddressAppService } from './address-app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAppService {

  constructor(
    private storeAppService: StoreAppService,
    private cookieAppService: CookieAppService,
    private orderAppService: OrderAppService,
    private customerService: CustomerService,
    private customerAppService: CustomerAppService,
    private addressAppService: AddressAppService,
    private cartAppService: CartAppService,
    private segmentService: SegmentService,
    private router: Router,
    private translateAppService: TranslateAppService,
    public oAuthService: OAuthService,
  ) { }

  login(username: string, password: string): Observable<any> {

    // handle phone number, remove + or 00
    if (username.startsWith('+')) {
      username = username.substr(1);
    } else if (username.startsWith('00')) {
      username = username.substr(2);
    }

    let tenantId = this.storeAppService.storeConfig?.tenantId;
    // if SSR enabled, in the first request storeConfig is still not populated, so need to get it from cookies
    if (!tenantId) {
      tenantId = this.cookieAppService.getTenantId();
    }
    
    this.oAuthService.configure(environment.oAuthConfig);

    return from(this.oAuthService.loadDiscoveryDocument()).pipe(
      switchMap(() =>
        from(
          this.oAuthService.fetchTokenUsingPasswordFlow(
            username,
            password,
            new HttpHeaders({ ...(tenantId && { tajertenant: tenantId }) }),
          ),
        ),
      ),
      tap((response) => {
        const expires_in = response.expires_in;
        const refresh_token = response.refresh_token;
        if (this.storeAppService.storeConfig?.token && this.cartAppService.shoppingCartCount === 0) {
          this.storeAppService.storeConfig.token = null;
        }

        // call get GetMyCustomerInfo api to popualte user info
        this.customerService.getMyCustomerSummary().toPromise().then((customer) => {
          let storeConfig = this.storeAppService.storeConfig;
          this.customerAppService.customer = customer;
          this.storeAppService.storeConfig = storeConfig;

          if (this.segmentService.isSegmentEnabled){
            this.segmentService.analyticsIdentify(
              storeConfig?.tenantId,
              storeConfig?.name,
              this.customerAppService.customer,
              '',//'https://' + storeConfig?.domains[0],
              storeConfig?.country?.name,
            );
            this.segmentService.implementSignedInEvent(this.customerAppService.customer, storeConfig?.id);
          }
          
          // if success login
          if (this.router.url.includes('checkout')) {
            if (!this.storeAppService.storeConfig.options.enableQuickCheckout) {
              if (this.orderAppService.order.deliveryType != DeliveryType.Delivery) {
                this.router.navigate([this.translateAppService.getUrl('/payment-methods')]);
              } else {
                this.router.navigate([this.translateAppService.getUrl('/delivery-methods')]);
              }
            } else {
              this.router.navigate([this.translateAppService.getUrl('/checkout')]);
            }

          } else {
            this.router.navigate([this.translateAppService.getUrl('/profile')]);
          }
        });

        // if there is cart with token, call api to transfer ownership of cart from token to logged in user (if the user doesn't have already a cart or ask user what to do)
        if (this.cartAppService.shoppingCart && this.cartAppService.shoppingCart.cartLines && this.cartAppService.shoppingCart.cartLines.length > 0) {
          // send token
        }
      }),
      take(1),
    );
  }

  logout(): void {
    if (this.customerAppService.hasLoggedIn) {
        this.oAuthService.logOut();
        // if (this.utility.isPlatformHybrid) {
        //     this.fcmAppService.revokAllTokens();
        // }
    }

    this.orderAppService.resetOrder();
    this.addressAppService.resetAddress();
    this.customerAppService.customer = null;
    this.cartAppService.shoppingCart = null;
    if (!this.storeAppService.storeConfig.token) {
        // if no token it means the user just logged out and we need to get new token
        
        location.reload();
        //commented because in this version we reload in both status.
        // if (environment.application.ssrEnabled) {
        //     // refreshing the page will get new token
        //     location.reload();
        // }
        // else {
        //     // getting new config will get new token
        //     this.storeService.populateAppConfig().then(() => {
        //         this.fcmAppService.getTokenAndUpdateFCMCient();
        //         this.router.navigate([this.translateAppService.getUrl('/')]);
        //     });
        // }
    } else {
        this.router.navigate([this.translateAppService.getUrl('/')]);
    }
  }
  
}
