import { Component, Injector, Input } from '@angular/core';
import {
  DeliveryMethodZoneRateStoreDto,
  OrderStatus,
  ShippingStatus,
} from '@tajer/api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { DeliveryMethodCardDesignOptions } from '@storefront/ng.themes';

@Component({
  selector: 'app-delivery-method-card',
  templateUrl: './delivery-method-card.component.html',
  styleUrls: ['./delivery-method-card.component.scss'],
})
export class DeliveryMethodCardComponent extends BaseComponent {
  //#region variables
  desginOptions: DeliveryMethodCardDesignOptions;
  @Input() deliveryMethod: DeliveryMethodZoneRateStoreDto;
  @Input() selected: boolean;
  @Input() viewRadio: boolean;
  @Input() viewNote: boolean;
  @Input() showFreeDeliveryLimit: boolean;
  @Input() deliveryStatus: ShippingStatus;
  @Input() currentOrderStatus: OrderStatus;
  @Input() deliveredDate: string;
  shippingStatus = ShippingStatus;
  orderStatus = OrderStatus;
  public get showMethodMinimumLimitError(): boolean {
    if (
      this.deliveryMethod &&
      this.cartAppService.shoppingCart &&
      this.deliveryMethod.minimumDeliveryLimit
    ) {
      return (
        this.cartAppService.shoppingCart.subTotal <
        this.deliveryMethod.minimumDeliveryLimit
      );
    }
    return false;
  }
  // //#endregion

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.setIonicThemeOptions();
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };

    // if(this.isBrowser){
    //   let styleSheet = document.styleSheets[0];
    //   styleSheet.insertRule(
    //     `.color-0:before { background-color: ${this.storeDesignOptions.color}; }`,
    //     styleSheet.cssRules.length
    //   );
    // }
  }
}
