<div class="modal-quickview-container">
  <div class='open modal-quickview-block'>
    <div class='open modal-quickview-main py-6' (blur)="closeQuickView()">
      <div class="heading pb-6 flex items-center justify-between relative px-6">
        <div class="heading5">{{translations.QuickView}}</div>
        <div (click)="closeQuickView()"
          [class.left-0]="isRtl" [class.right-0]="!isRtl"
          class="close-btn absolute right-0 top-0 w-6 h-6 rounded-full bg-surface flex items-center justify-center duration-300 cursor-pointer hover:bg-black hover:text-white"
          style="margin: 0 20px!important">
          <i class="ph ph-x text-sm"></i>
        </div>
      </div>
      <div class="flex h-full max-md:flex-col gap-y-6" [ngClass]="isRtl?'reverse-container':''">
        <app-loading *ngIf="loading" style="display: block;height: 325px;width:100%"></app-loading>
        <div *ngIf="!loading" class="lg:w-[388px] md:w-[300px] flex-shrink-0 px-6"
        [ngClass]="isRtl?'right':'left'">
          <div class="list-img max-md:flex items-center gap-4">
            <div *ngFor="let image of images; let i=index"
              class="bg-img w-full aspect-[3/4] max-md:w-[150px] max-md:flex-shrink-0 rounded-[20px] overflow-hidden md:mt-6">
              <img [src]='image' alt='item' class='w-full h-full object-cover' />
            </div>
          </div>
        </div>
        <div *ngIf="!loading" class="w-full px-6" [ngClass]="isRtl?'left':'right'">
          <div class="product-infor">
            <div class="flex justify-between">
              <div>
                <div *ngIf="anvogueDesginOptions?.showItemSkuOnItem"
                  class="category caption2 text-secondary font-semibold uppercase">
                  {{itemDetail?.sku}}
                </div>
                <div class="name heading4 mt-1">
                  {{item?.name}}
                </div>
              </div>

              <div *ngIf="anvogueDesginOptions?.isFavoriteItemsEnabled"
                (click)="$event.stopPropagation();addOrRemoveFavorite()"
                [ngClass]="favoriteAppService.addOrRemoveFavoriteLoading ? 'fav-spinner' : '' "
                [style.background]="anvogueDesginOptions?.color" [style.color]="anvogueDesginOptions?.frontColor"
                class='add-wishlist-btn w-10 h-10 flex-shrink-0 flex items-center justify-center border border-line cursor-pointer rounded-lg duration-300 hoverElement'>
                <i class="ph ph-heart text-xl"></i>
              </div>
            </div>
            <!-- <div class="flex items-center gap-1 mt-3">
                    <div class="rate flex">
                        <i class="ph-fill ph-star text-sm text-yellow"></i>
                        <i class="ph-fill ph-star text-sm text-yellow"></i>
                        <i class="ph-fill ph-star text-sm text-yellow"></i>
                        <i class="ph-fill ph-star text-sm text-yellow"></i><i
                            class="ph-fill ph-star text-sm text-yellow"></i>
                    </div>
                    <span class='caption1 text-secondary'>(1.234 reviews)</span>
                </div> -->
            <div class="flex items-center gap-1 gap-y-3 flex-wrap mt-3">
              <div class="text-xs font-semibold bg-black text-white uppercase py-1 px-3 rounded-full"
              *ngIf="featured">
                <span>{{translations?.FeaturedItem}}</span>
              </div>

              <span class="inline-flex" *ngIf="item?.verified">
                <div class="flex items-center" [style.color]="anvogueDesginOptions?.secondaryColor">
                  <i style="font-size: 22px;" class="fa fa-check-square-o mx-1"></i>
                  <span class="text-base font-bold" [style.color]="anvogueDesginOptions?.frontColor">
                    {{translations?.Verified}}
                  </span>
                </div>
              </span>
            </div>

            <div class="flex items-center gap-3 flex-wrap mt-5 pb-6 border-b border-line">
              <div [style.color]="anvogueDesginOptions?.itemPriceColor" *ngIf="!anvogueDesginOptions?.hidePrices"
                class="product-price heading5">
                <span *ngIf="isCurrnetBefore">
                  {{currnetSymbol}} </span>
                <span *ngIf="itemDetail">
                  {{!hasPriceList?displayCurrency(itemDetail?.price):displayCurrency(priceBasedOnPriceListQuantity)}}</span>
                <span *ngIf="priceRange && !itemDetail">
                  {{displayCurrency(priceRange?.min)}}
                </span>
                <span *ngIf="isCurrnetAfter">
                  {{currnetSymbol}} </span>
              </div>
              <div *ngIf="itemDetail?.oldPrice" class='w-px h-4 bg-line'></div>
              <div *ngIf="itemDetail?.oldPrice" class="product-origin-price font-normal text-secondary2">
                <del>
                  <span *ngIf="isCurrnetBefore">
                    {{currnetSymbol}} </span>
                  <span>{{displayCurrency(itemDetail?.oldPrice)}}</span>
                  <span *ngIf="isCurrnetAfter">
                    {{currnetSymbol}} </span>
                </del>
              </div>
              <div class='desc text-secondary mt-3'>
                <p class='product-description text-secondary mt-1' [innerHtml]="item.description"></p>
              </div>
            </div>

            <div class="list-action mt-6">
              <!-- <div class="choose-size mt-5">
                        <div class="heading flex items-center justify-between">
                            <div class="text-title" style="text-transform: capitalize;">
                              {{translations?.Select}} {{item?.variantOption2Name}}
                               <span class='text-title size'></span>
                            </div>

                        </div>
                        <div class="list-size flex items-center gap-2 flex-wrap mt-3">
                            <div
                                class='size-item w-12 h-12 flex
                                items-center justify-center text-button rounded-full bg-white border border-line'>
                                S
                            </div>
                            <div
                                class='size-item w-12 h-12 flex items-center justify-center text-button rounded-full bg-white border border-line active'>
                                M
                            </div>
                            <div
                                class='size-item w-12 h-12 flex items-center justify-center text-button rounded-full bg-white border border-line'>
                                L
                            </div>
                            <div
                                class='size-item w-12 h-12 flex items-center justify-center text-button rounded-full bg-white border border-line'>
                                XL
                            </div>
                        </div>
                    </div> -->
              <div class="mt-3">
                <h3 *ngIf="hasOption1" class="mb-7 text-xl font-semibold text-black sm:text-2xl mb-3"
                  style="text-transform: capitalize;">
                  {{translations?.Select}} {{item?.variantOption1Name}}
                </h3>
                <div *ngIf="hasOption1" class="py-4 flex flex-wrap items-center">
                  <div [ngStyle]="{'margin' : currentLang !='ar' ? '0 1rem 1rem 0' : '0 0 1rem 1rem'}"
                    *ngFor="let optionValue of item.variantOption1Values; let i=index" style="border-radius: 4px;">
                    <button (click)="option1Id = optionValue;onChangeVariant()">
                      <label [for]="optionValue"
                        [style.border-color]="option1Id == optionValue ? anvogueDesginOptions?.frontColor : ''"
                        [style.background-color]="option1Id == optionValue ? anvogueDesginOptions?.frontColor : ''"
                        [style.color]="option1Id == optionValue ? anvogueDesginOptions?.color : ''"
                        [style.font-weight]="option1Id == optionValue ? 'bold' : 'normal'"
                        class="variant inline-block cursor-pointer rounded border py-2 px-6 hoverElement">
                        {{optionValue}}
                      </label>
                    </button>
                  </div>
                </div>

                <h3 *ngIf="hasOption2" class="mb-7 text-xl font-semibold text-black sm:text-2xl mb-3"
                  style="text-transform: capitalize;">
                  {{translations?.Select}} {{item?.variantOption2Name}}
                </h3>
                <div *ngIf="hasOption2" class="py-4 flex flex-wrap items-center">
                  <div [ngStyle]="{'margin' : currentLang !='ar' ? '0 1rem 1rem 0' : '0 0 1rem 1rem'}"
                    *ngFor="let optionValue of item.variantOption2Values; let i=index">
                    <button (click)="option2Id = optionValue;onChangeVariant()">
                      <label [for]="optionValue"
                        [style.border-color]="option2Id == optionValue ? anvogueDesginOptions?.color : ''"
                        [style.background-color]="option2Id == optionValue ? anvogueDesginOptions?.color : ''"
                        [style.color]="option2Id == optionValue ? anvogueDesginOptions?.frontColor : ''"
                        [style.font-weight]="option2Id == optionValue ? 'bold' : 'normal'"
                        class="variant inline-block cursor-pointer rounded border py-2 px-6 hoverElement">
                        {{optionValue}}
                      </label>
                    </button>
                  </div>
                </div>

                <h3 *ngIf="hasOption3" class="mb-7 text-xl font-semibold text-black sm:text-2xl mb-3"
                  style="text-transform: capitalize;">
                  {{translations?.Select}} {{item?.variantOption3Name}}
                </h3>
                <div *ngIf="hasOption3" class="py-4 flex flex-wrap items-center">
                  <div [ngStyle]="{'margin' : currentLang !='ar' ? '0 1rem 1rem 0' : '0 0 1rem 1rem'}"
                    *ngFor="let optionValue of item.variantOption3Values; let i=index">
                    <button (click)="option3Id = optionValue;onChangeVariant()">
                      <label [for]="optionValue"
                        [style.border-color]="option3Id == optionValue ? anvogueDesginOptions?.color : ''"
                        [style.background-color]="option3Id == optionValue ? anvogueDesginOptions?.color : ''"
                        [style.color]="option3Id == optionValue ? anvogueDesginOptions?.frontColor : ''"
                        [style.font-weight]="option3Id == optionValue ? 'bold' : 'normal'"
                        class="variant inline-block cursor-pointer rounded border py-2 px-6 hoverElement">
                        {{optionValue}}
                      </label>
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap items-center">
                <p style="font-size: .75rem;" class="text-body-color mb-4 font-medium" *ngIf="itemDetail?.weight > 0">
                  <span>{{translations?.Weight}}</span><span>: </span><span>{{itemDetail?.weight}}<span>
                      {{translations?.KG}}</span></span>
                </p>
                <span style="margin:0 10px 1rem" class="text-body-color font-medium"
                  *ngIf="itemDetail?.weight > 0 && itemDetail?.height > 0">-</span>
                <p style="font-size: .75rem;" class="text-body-color mb-4 font-medium" *ngIf="itemDetail?.height > 0">
                  <span>{{translations?.Height}}</span><span>: </span><span>{{itemDetail?.height}}<span>
                      {{translations?.CM}}</span></span>
                </p>
                <span style="margin:0 10px 1rem" class="text-body-color font-medium"
                  *ngIf="(itemDetail?.height > 0 || itemDetail?.weight > 0) && itemDetail?.width > 0">-</span>
                <p style="font-size: .75rem;" class="text-body-color mb-4 font-medium" *ngIf="itemDetail?.width > 0">
                  <span>{{translations?.Width}}</span><span>: </span><span>{{itemDetail?.width}}<span>
                      {{translations?.CM}}</span></span>
                </p>
                <span style="margin:0 10px 1rem" class="text-body-color font-medium"
                  *ngIf="itemDetail?.length > 0 && (itemDetail?.width > 0 || itemDetail?.height > 0 || itemDetail?.weight > 0)">-</span>
                <p style="font-size: .75rem;" class="text-body-color mb-4 font-medium" *ngIf="itemDetail?.length > 0">
                  <span>{{translations?.Length}}</span><span>: </span><span>{{itemDetail?.length}}<span>
                      {{translations?.CM}}</span></span>
                </p>
              </div>

              <div
                *ngIf="itemDetail?.calories > 0 && anvogueDesginOptions?.showCalories && anvogueDesginOptions?.showCaloriesOnItem"
                class="flex flex-wrap items-center">
                <p style="font-size: .75rem;" class="text-body-color mb-4 font-medium" *ngIf="itemDetail?.weight > 0">
                  <span>{{translations?.Calories}}</span><span>:
                  </span><span>{{itemDetail?.calories}}<span>
                      {{translations?.Cal}}</span></span>
                </p>
              </div>
              <div class="text-title mt-5">{{translations.Quantity}}</div>
              <div class="choose-quantity flex items-center max-xl:flex-wrap lg:justify-between gap-5 mt-3">
                <div
                  class="quantity-block flex items-center justify-between rounded-lg border border-line sm:w-[140px] w-[120px] flex-shrink-0 mb-4">
                  <button (click)="decreaseQuantity()" [disabled]="disableDecreaseQuantityBtn" class="md:p-3 max-md:py-1.5 max-md:px-3 rounded-lg hoverElement"
                  style="width: 100%; height: 100%;">
                    <i class="ph-bold ph-minus cursor-pointer body1"></i>
                  </button>
                  <input class="quantity text-center" type="number" max="99" min="1" (input)="onUpdateQuantity($event)"
                    [ngModel]="quantity" (ngModelChange)="quantity=$event" [value]="quantity" readonly />

                  <button (click)="increaseQuantity()" [disabled]="disableIncreaseQuantityBtn" class="md:p-3 max-md:py-1.5 max-md:px-3 rounded-lg hoverElement"
                  style="width: 100%; height: 100%;">
                    <i class="ph-bold ph-plus cursor-pointer body1"></i>
                  </button>
                </div>
              </div>
              <div class="button-main whitespace-nowrap w-full flex align-center justify-center text-center hoverElement" [style.background]="anvogueDesginOptions?.secondaryColor"
                [style.color]="anvogueDesginOptions?.frontColor"
                [ngClass]="(addingToCart || itemDetail?.isOutOfStock ||
                            (!itemDetail?.isOutOfStock && !addingToCart && anvogueDesginOptions?.changeAddToCartToSelectOption && isItemHasMultiVariants && !itemDetail)?'disabledBtn':'')"
                (click)="$event.stopPropagation();addToCart()" *ngIf="!anvogueDesginOptions?.disableAddToCartOnItem">
                <span class="mx-2">
                  <svg *ngIf="addingToCart" xmlns="http://www.w3.org/2000/svg" width="22" height="22"
            fill="currentColor" class="bi bi-arrow-repeat animate-spin px-1" viewBox="0 0 16 16">
            <path
              d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
            <path fill-rule="evenodd"
              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
          </svg>
                </span>
                <span
                  *ngIf="!itemDetail?.isOutOfStock && !addingToCart && !(anvogueDesginOptions?.changeAddToCartToSelectOption && isItemHasMultiVariants && !itemDetail)">{{translations?.AddToCart}}</span>
                <span
                  *ngIf="!itemDetail?.isOutOfStock && !addingToCart && anvogueDesginOptions?.changeAddToCartToSelectOption && isItemHasMultiVariants && !itemDetail">{{translations?.SelectOneOption}}</span>
                <span *ngIf="!itemDetail?.isOutOfStock && addingToCart">{{translations?.Loading}}</span>
                <span *ngIf="itemDetail?.isOutOfStock">{{translations?.OutOfStock}}</span>
              </div>
              <div class="button-block mt-5" *ngIf="anvogueDesginOptions?.showBuyNowBtnOnItem">
                <a [routerLink]="anvogueConfiguration?.cartUrl" class="button-main w-full text-center hoverElement"
                  [style.background-color]="anvogueDesginOptions?.color"
                  [style.--hover-background-color]="anvogueDesginOptions?.frontColor">{{translations?.BuyNow}}</a>
              </div>

            </div>

            <div class="list-action mt-6">
              <div class="choose-size mt-5" *ngIf="hasOption1">
                <div class="heading flex items-center justify-between">
                  <div class="text-title">{{item?.variantOption1Name}}<span class='text-title size'></span>
                  </div>
                  <div class="caption1 size-guide" [style.color]="anvogueDesginOptions.color">
                    <ng-container *ngFor="let optionValue of item.variantOption1Values; let i=index">
                      {{optionValue}}<span *ngIf="i != item.variantOption1Values.length - 1">,</span>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="choose-size mt-5" *ngIf="hasOption2">
                <div class="heading flex items-center justify-between">
                  <div class="text-title">{{item?.variantOption2Name}}<span class='text-title size'></span>
                  </div>
                  <div class="caption1 size-guide" [style.color]="anvogueDesginOptions.color">
                    <ng-container *ngFor="let optionValue of item.variantOption2Values; let i=index">
                      {{optionValue}}<span *ngIf="i != item.variantOption2Values.length - 1">,</span>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="choose-size mt-5" *ngIf="hasOption3">
                <div class="heading flex items-center justify-between">
                  <div class="text-title">{{item?.variantOption3Name}}<span class='text-title size'></span>
                  </div>
                  <div class="caption1 size-guide" [style.color]="anvogueDesginOptions.color">
                    <ng-container *ngFor="let optionValue of item.variantOption3Values; let i=index">
                      {{optionValue}}<span *ngIf="i != item.variantOption3Values.length - 1">,</span>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="choose-size mt-5" *ngIf="item?.brandId">
                <div class="heading flex items-center justify-between">
                  <div class="text-title">{{translations?.Brands}}<span class='text-title size'></span>
                  </div>
                  <div class="caption1 size-guide" [style.color]="anvogueDesginOptions.color"
                    [style.--hover-color]="anvogueDesginOptions.frontColor">
                    <a
                      [routerLink]="entityUtilityService.getBrandUrl(storeAppService.getBrandById(item?.brandId))?.toString()"><span>
                        {{getValue(storeAppService.getBrandById(item?.brandId))}} </span></a>
                  </div>
                </div>
              </div>

              <div class="choose-size mt-5" *ngIf="item?.categoriesIds?.length > 0">
                <div class="flex items-center justify-between">
                  <div class="heading text-title">{{translations?.Categories}}<span class='text-title size'></span>
                  </div>
                  <div class="caption1 size-guide" [style.color]="anvogueDesginOptions.color"
                    [style.--hover-color]="anvogueDesginOptions.frontColor">
                    <a [routerLink]="category.url" *ngFor="let category of categories;let i=index">
                      <span>&nbsp;{{category.name}}<span *ngIf="i != categories.length - 1">,</span></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="list-payment mt-7">
                    <div
                        class="main-content lg:pt-8 pt-6 lg:pb-6 pb-4 sm:px-4 px-3 border border-line rounded-xl relative max-md:w-2/3 max-sm:w-full">
                        <div
                            class="heading6 px-5 bg-white absolute -top-[14px] left-1/2 -translate-x-1/2 whitespace-nowrap">
                            Guranteed safe checkout</div>
                        <div class="list grid grid-cols-6">
                            <div class="item flex items-center justify-center lg:px-3 px-1">
                                <img src='./assets/images/payment/Frame-0.png' alt='payment' class='w-full' />
                            </div>
                            <div class="item flex items-center justify-center lg:px-3 px-1">
                                <img src='./assets/images/payment/Frame-1.png' alt='payment' class='w-full' />
                            </div>
                            <div class="item flex items-center justify-center lg:px-3 px-1">
                                <img src='./assets/images/payment/Frame-2.png' alt='payment' class='w-full' />
                            </div>
                            <div class="item flex items-center justify-center lg:px-3 px-1">
                                <img src='./assets/images/payment/Frame-3.png' alt='payment' class='w-full' />
                            </div>
                            <div class="item flex items-center justify-center lg:px-3 px-1">
                                <img src='./assets/images/payment/Frame-4.png' alt='payment' class='w-full' />
                            </div>
                            <div class="item flex items-center justify-center lg:px-3 px-1">
                                <img src='./assets/images/payment/Frame-5.png' alt='payment' class='w-full' />
                            </div>
                        </div>
                    </div>
                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
