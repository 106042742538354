import { StoreAppService } from "@storefront/ng.core";
import { CartAppService } from "../shared/app-services/cart-app.service";
import { EntityUtilityService } from "../shared/app-services/entity-utility.service";
import { OrderAppService } from "../shared/app-services/order-app.service";
import { TajerPlugin } from "./core/tajer-plugin";
import { CartLineStoreDto, ItemDetailStoreBySlug, ItemDetailStoreDto, ItemStoreDto, ItemStoreList } from "@tajer/api";
import { Injectable, Inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { DOCUMENT } from "@angular/common";

declare let gtag: Function;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsPlugin extends TajerPlugin {

    constructor(private entityUtilityService: EntityUtilityService,
        private orderAppService: OrderAppService,
        @Inject(DOCUMENT) private document: Document,
        private cartAppService: CartAppService,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.addGoogleAnalyticScript(this.storeAppService.storeConfig.externalServices.googleAnalyticId);

        // Register event handlers
        this.on('implementAddToCartEvent', this.implementAddToCartEvent.bind(this));
        this.on('implementPageViewEvent', this.implementPageViewEvent.bind(this));
        this.on('implementRemoveFromCartEvent', this.implementRemoveFromCartEvent.bind(this));
        this.on('implementInitiateCheckoutEvent', this.implementInitiateCheckoutEvent.bind(this));
        this.on('implementPurchaseEvent', this.implementPurchaseEvent.bind(this));
        this.on('implementClickOnProductEvent', this.implementClickOnProductEvent.bind(this));
        this.on('implementViewProductEvent', this.implementViewProductEvent.bind(this));
    }

    private implementPageViewEvent(y) {
        try {
            gtag('config', this.storeAppService.storeConfig?.externalServices?.googleAnalyticId, { 'page_path': y.url });
        } catch (error) {
            if (environment.log) { console.warn(error) }
        }
    }

    implementInitiateCheckoutEvent() {
        try {
            gtag('event', 'begin_checkout', {
                "items": this.createItemsArray,
                "coupon": this.orderAppService.coupon
            });
        } catch (error) {
            //if (environment.log) { console.warn(error) }
        }
    }

    implementPurchaseEvent() {
        try {
            gtag('event', 'purchase', {
                "transaction_id": "",
                "affiliation": "",
                "value": this.orderAppService.shippingCartTotal,
                "currency": this.storeAppService.storeConfig?.currency?.isoCode,
                "tax": this.orderAppService.totalTax,
                "shipping": 0,
                "items": this.createItemsArray
            });
        } catch (error) {
            if (environment.log) { console.warn(error) }
        }
    }

    implementViewProductEvent(item: ItemDetailStoreBySlug, itemName: string, listName = '') {
        try {
            gtag('event', 'view_item', {
                "items": [
                    {
                        "id": item.sku,
                        "name": itemName,
                        "list_name": listName,
                        //"brand": item?.brandId ? this.utility.getEntityValue(this.store.getBrandById(item?.brandId)) : "",
                        //"category": item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
                        "variant": this.createVariantList(item),
                        "list_position": 0,
                        "quantity": item.quantity,
                        "price": item.price
                    }
                ]
            });
        } catch (error) {
            if (environment.log) { console.warn(error) }
        }
    }

    implementClickOnProductEvent(item: ItemStoreList, listName = '') {
        try {
            gtag('event', 'select_content', {
                "content_type": "product",
                "items": [
                    {
                        "id": item.sku,
                        "name": item.name,
                        "list_name": listName,
                        //"brand": item?.brandId ? this.utility.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
                        //"category": item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
                        //"variant": this.createVariantList(itemDetail),
                        "list_position": 0,
                        //"quantity": item.quantity,
                        "price": item.price
                    }
                ]
            });
        } catch (error) {
            if (environment.log) { console.warn(error) }
        }
    }

    implementAddToCartEvent(item: ItemStoreList | ItemDetailStoreBySlug, itemName: string, quantity: number = 1, listName = '') {
        try {
            gtag('event', 'add_to_cart', {
                "items": [
                    {
                        "id": item?.sku,
                        "name": itemName,
                        // The list or collection to which the product belongs (e.g. Search Results)
                        "list_name": listName,
                        //"brand": item?.brandId ? this.utility.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
                        // "category": item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
                        //	The variant of the product (e.g. Black).
                        //"variant": this.createVariantList(itemDetail),
                        // 	The product's position in a list or collection (e.g. 2)
                        "list_position": 1,
                        "quantity": quantity,
                        "price": item?.price
                    }]
            });
        } catch (error) {
            //if (environment.log) { console.warn(error) }
        }
    }

    implementRemoveFromCartEvent(orderLine: CartLineStoreDto, listName = '') {
        const itemDetail = orderLine?.item.itemDetails.find(x => x.id == orderLine?.itemDetailId);
        try {
            gtag('event', 'remove_from_cart', {
                "items": [
                    {
                        "id": itemDetail?.sku,
                        "name": this.entityUtilityService.getEntityValue(orderLine.item),
                        "list_name": listName,
                        "brand": orderLine?.item?.brandId ? this.entityUtilityService.getEntityValue(this.storeAppService.getBrandById(orderLine?.item?.brandId)) : "",
                        "category": orderLine?.item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(orderLine?.item) : "",
                        "variant": this.createVariantList(itemDetail),
                        "list_position": 0,
                        "quantity": orderLine.quantity,
                        "price": orderLine.price
                    }
                ]
            });
        } catch (error) {
            //if (environment.log) { console.warn(error) }
        }
    }

    addGoogleAnalyticScript(googleAnalyticId: string) {
        var script = this.document.createElement('script');
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticId;
        script.setAttribute("async", "");
        this.document.head.appendChild(script);
        var script2 = this.document.createElement('script');
        const googleAnalyticScript = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());"
        script2.innerHTML = googleAnalyticScript;
        this.document.head.appendChild(script2);
    }

    createVariantList(itemDetail: ItemDetailStoreBySlug | ItemDetailStoreDto): string {
        var variantString = "";
        var variantArray = [];
        if (itemDetail?.variantOption1Value) {
            variantArray.push(itemDetail.variantOption1Value)
        }
        if (itemDetail?.variantOption2Value) {
            variantArray.push(itemDetail.variantOption2Value)
        }
        if (itemDetail?.variantOption3Value) {
            variantArray.push(itemDetail.variantOption3Value)
        }

        if (variantArray.length > 0) {
            variantString = variantArray.join('-')
        }
        return variantString;
    }

    createItemCategoryNameList(item: ItemStoreDto): string {
        var categoriesNames: string[] = [];
        this.storeAppService.allCategories?.forEach(category => {
            item?.categoriesIds?.forEach(categoryItem => {
                if (category.id === categoryItem) {
                    categoriesNames.push(this.entityUtilityService.getEntityValue(category));
                }
            });
        });
        return categoriesNames?.join("/");
    }

    public get createItemsArray(): any[] {
        var items = [];
        this.cartAppService.shoppingCart.cartLines.forEach((row: CartLineStoreDto) => {
            const itemDetail = row?.item.itemDetails.find(x => x.id == row?.itemDetailId);
            items.push({
                "id": itemDetail?.sku,
                "name": this.entityUtilityService.getEntityValue(row.item),
                "list_name": "",
                "brand": row?.item?.brandId ? this.entityUtilityService.getEntityValue(this.storeAppService.getBrandById(row?.item?.brandId)) : "",
                "category": row?.item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(row?.item) : "",
                "variant": this.createVariantList(itemDetail),
                "list_position": 0,
                "quantity": row.quantity,
                "price": row.price
            })
        })
        return items;
    }
}
