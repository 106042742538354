import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressCardComponent } from './address-card.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { DefaultAddressCardModule } from '@storefront/ng.themes';

@NgModule({
  declarations: [AddressCardComponent],
  imports: [CommonModule, SharedModule, DefaultAddressCardModule],
  exports: [AddressCardComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA 
  ],
})
export class AddressCardModule {}
