import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DrawerAppService {
  //#region variables
  profileSideMenuOpened: boolean;

  constructor() { }
}
