<ng-container *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'">
    <ion-header [style.background]="storeDesignOptions?.color">
        <ion-toolbar [style.--background]="storeDesignOptions?.color" [style.color]="storeDesignOptions?.frontColor">
            <ion-title>{{storeAppService.storeName}}</ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="closeSideMenu()">
                    <ion-icon [style.color]="storeDesignOptions?.frontColor" name="close-outline" slot="icon-only"
                        class="font-24 ion-margin-end pointer-cursor">
                    </ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <ion-content>
        <ion-list>
            <ion-item class="menu-item" *ngIf="customerAppService.hasLoggedIn" [routerLink]="getUrl('/profile')">
                <ion-icon [style.color]="storeDesignOptions?.color" name="person-circle" class="ion-margin-end"
                    size="small">
                </ion-icon>
                <ion-label translate>Profile</ion-label>
            </ion-item>

            <ng-container *ngIf="menuItems && menuItems.length > 0">
                <ion-item *ngFor="let menuItem of menuItems" [routerLink]="menuItem.url" class="menu-item">
                    <img *ngIf="menuItem.image" [src]="menuItem.image" class="ion-margin-end" />
                    <ion-icon *ngIf="!menuItem.image" [style.color]="storeDesignOptions?.color" [name]="menuItem.icon"
                        class="ion-margin-end" size="small"></ion-icon>
                    <span translate>{{menuItem.name}}</span>
                </ion-item>
            </ng-container>

            <ion-item class="menu-item" *ngIf="configFeatures?.notifications" [routerLink]="getUrl('/notifications')">
                <ion-icon [style.color]="storeDesignOptions?.color" name="notifications-circle" class="ion-margin-end"
                    size="small"></ion-icon>
                <ion-label translate>MyNotifications</ion-label>
            </ion-item>

            <ion-item *ngIf="storeDesignOptions?.showStickyButtonOnMenuHeader">
                <ion-buttons slot="start">
                    <ion-button [style.--background]="storeDesignOptions?.frontColor"
                        [style.--color]="storeDesignOptions?.color" size="small" fill="outline" class="end-btn"
                        style="padding-inline-start: 5px;" slot="start" translate
                        [routerLink]="getUrl('/search-order')">
                        TrackMYOrder
                    </ion-button>
                </ion-buttons>
            </ion-item>

            <ion-item
                *ngIf="storeDesignOptions?.showLanguageButtonsOnMenuHeader && storeAppService.generateLanguagesArray?.length > 0">
                <ion-icon style="font-size: 24px" [style.color]="storeDesignOptions?.color" name="earth-outline"
                    class="ion-margin-end" size="small"></ion-icon>
                <div class="flex">
                    <ng-container *ngFor="let lang of storeAppService.generateLanguagesArray">
                        <ion-button [style.--border-color]="storeDesignOptions?.color"
                            [style.color]="storeDesignOptions?.color"
                            (click)="this.customerAppService.onChangeLang(lang.isoCode);" fill="outline"
                            *ngIf="currentLang != lang.isoCode">
                            <ion-label>{{lang.name}}</ion-label>
                        </ion-button>
                    </ng-container>
                </div>
            </ion-item>
        </ion-list>

        <div class="social-links mt-2 ion-text-center">
            <a *ngIf="customerServiceInfo?.mobilePhone"
                [href]="customerServiceAppService.getCallLink(customerServiceInfo?.mobilePhone)"
                (click)="addFacebookPixelContactEvent()"
                [title]="customerServiceAppService.getCallLink(customerServiceInfo?.mobilePhone)"
                [style.color]="storeDesignOptions?.color"><i>
                    <ion-icon class="mt-12" name="call-outline"></ion-icon>
                </i>
            </a>
            <a *ngIf="customerServiceInfo?.whatsApp"
                [title]="customerServiceAppService.getWhatsAppLink(customerServiceInfo?.whatsApp)"
                (click)="addFacebookPixelContactEvent()" [style.color]="storeDesignOptions?.color"
                [href]="customerServiceAppService.getWhatsAppLink(customerServiceInfo?.whatsApp)" target="_blank"><i>
                    <ion-icon class="mt-12" name="logo-whatsapp"></ion-icon>
                </i>
            </a>
            <a *ngIf="customerServiceInfo?.telegram"
                [title]="customerServiceAppService.getTelegramLink(customerServiceInfo?.telegram)"
                (click)="addFacebookPixelContactEvent()" [style.color]="storeDesignOptions?.color"
                [href]="customerServiceAppService.getTelegramLink(customerServiceInfo?.telegram)" target="_blank"><i>
                    <ion-icon class="mt-12" name="send-outline"></ion-icon>
                </i>
            </a>
            <a [style.color]="storeDesignOptions?.color" *ngIf="customerServiceInfo?.email"
                [title]="customerServiceInfo?.email" (click)="addFacebookPixelContactEvent()"
                href="mailto:{{customerServiceInfo?.email}}" target="_blank">
                <i>
                    <ion-icon class="mt-12" name="mail-outline"></ion-icon>
                </i>
            </a>
            <a [title]="socialMediaInfo?.instagram" [style.color]="storeDesignOptions?.color"
                *ngIf="socialMediaInfo?.instagram" [href]="socialMediaInfo?.instagram" target="_blank">
                <i>
                    <ion-icon class="mt-12" name="logo-instagram"></ion-icon>
                </i>
            </a>
            <a [title]="socialMediaInfo?.snapchat" [style.color]="storeDesignOptions?.color"
                *ngIf="socialMediaInfo?.snapchat" [href]="socialMediaInfo?.snapchat" target="_blank"><i>
                    <ion-icon class="mt-12" name="logo-snapchat"></ion-icon>
                </i></a>
            <a [title]="socialMediaInfo?.ticktok" [style.color]="storeDesignOptions?.color"
                *ngIf="socialMediaInfo?.ticktok" [href]="socialMediaInfo?.ticktok" target="_blank"><i>
                    <ion-icon class="mt-12" name="logo-tiktok"></ion-icon>
                </i>
            </a>
            <a *ngIf="socialMediaInfo?.youtube" [href]="socialMediaInfo?.youtube" [title]="socialMediaInfo?.youtube"
                target="_blank" [style.color]="storeDesignOptions?.color"><i>
                    <ion-icon class="mt-12" name="logo-youtube"></ion-icon>
                </i></a>
            <a *ngIf="socialMediaInfo?.twitter" [href]="socialMediaInfo?.twitter" [title]="socialMediaInfo?.twitter"
                target="_blank" [style.color]="storeDesignOptions?.color"><i>
                    <ion-icon class="mt-12" name="logo-twitter"></ion-icon>
                </i></a>
            <a *ngIf="socialMediaInfo?.facebook" [href]="socialMediaInfo?.facebook" [title]="socialMediaInfo?.facebook"
                target="_blank" [style.color]="storeDesignOptions?.color"><i>
                    <ion-icon class="mt-12" name="logo-facebook"></ion-icon>
                </i></a>
        </div>
        <div class="flex ion-justify-content-center">
            <div class="ion-padding" [style.background]="storeDesignOptions?.color">
                <img [class.default-header-logo]="storeAppService?.defaultClientLogoUrl === clientLogoUrl"
                    routerLink="/" class="header-logo" [src]="clientLogoUrl" (click)="closeSideMenu()" />
            </div>
        </div>

        <ion-toolbar *ngIf="!storeOptions.hideProviderInfo" [style.--background]="storeDesignOptions?.color"
            class="ion-margin-top">
            <div class="ion-text-center" style="padding-top: 10px;padding-bottom: 4px;">
                <a class="text-decoration-none provider" [style.color]="storeDesignOptions?.frontColor"
                    [href]="providerWebsite" target="_blank">
                    <span translate>PoweredBy</span>
                    {{providerName}}
                </a>
                <br>
                <img style="width: 70px;" [src]="providerLogo" [alt]="providerName">
            </div>
        </ion-toolbar>
    </ion-content>
</ng-container>

<ng-container *ngIf="currentTheme == 'merch'">
    <!-- ====== Drawer Section Start -->
    <div [style.background-color]="menuBackgroundColor" [style.color]="menuFontColor"
        style="scrollbar-width: thin"
        class="relative flex h-screen w-full max-w-[350px] flex-col justify-between overflow-y-auto bg-white py-9 px-5 shadow-three sm:px-9">
        <button (click)="closeSideMenu()" [class.right-6]="!isRtl" [class.left-6]="isRtl"
            class="absolute top-6 right-6 z-10 text-body-color text-opacity-50 hover:text-black">
            <svg width="20" height="20" viewBox="0 0 20 20" class="fill-current">
                <g>
                    <path fill-rule="evenodd" clip-rule="evenodd" [attr.fill]="menuFontColor"
                        d="M15.5906 5.5886C15.916 5.26317 15.916 4.73553 15.5906 4.41009C15.2651 4.08466 14.7375 4.08466 14.412 4.41009L10.0013 8.82084L5.59056 4.41009C5.26512 4.08466 4.73748 4.08466 4.41205 4.41009C4.08661 4.73553 4.08661 5.26317 4.41205 5.5886L8.82279 9.99935L4.41205 14.4101C4.08661 14.7355 4.08661 15.2632 4.41205 15.5886C4.73748 15.914 5.26512 15.914 5.59056 15.5886L10.0013 11.1779L14.412 15.5886C14.7375 15.914 15.2651 15.914 15.5906 15.5886C15.916 15.2632 15.916 14.7355 15.5906 14.4101L11.1798 9.99935L15.5906 5.5886Z" />
                </g>
            </svg>
        </button>
        <div class="mb-[30px]">
            <a class="mb-9 inline-block">
                <img alt="logo" [class.default-header-logo]="storeAppService?.defaultClientLogoUrl === clientLogoUrl"
                    routerLink="/" class="header-logo" [src]="clientLogoUrl" (click)="closeSideMenu()" />
            </a>
            <ul class="mb-[30px] space-y-[10px] border-b border-stroke pb-[30px]">
                <ng-container *ngIf="menuItems && menuItems.length > 0">
                    <li *ngFor="let menuItem of menuItems" [routerLink]="menuItem.url" [style.color]="menuFontColor"
                        style="cursor: pointer; align-items: center;"
                        class="flex justify-between hover:border-primary hover:text-primary py-[5px]">
                        <h3 class="inline-block text-lg font-medium translate">
                            {{menuItem.name}}
                        </h3>
                        <!-- <svg style="width: 25px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                        </svg> -->
                    </li>
                </ng-container>
            </ul>

            <div>
                <h3 style="cursor: pointer;" [style.color]="menuFontColor" *ngIf="configFeatures?.notifications"
                    [routerLink]="getUrl('/notifications')" class="mb-5 text-lg font-semibold" translate>MyNotifications
                </h3>

                <h3 style="cursor: pointer;" [style.--background]="storeDesignOptions?.frontColor" [routerLink]="getUrl('/search-order')"
                    [style.--color]="menuFontColor" *ngIf="true ||storeDesignOptions?.showStickyButtonOnMenuHeader"
                    class="mb-5 text-lg font-semibold" translate>TrackMYOrder</h3>

            </div>
        </div>

        <div>
            <a *ngIf="storeDesignOptions?.showAccountOnHeader && !currentCustomer?.firstName" [routerLink]="getUrl('/profile')"
                [style.color]="'menuFontColor'" class="flex items-center justify-start text-md font-medium">
                <svg width="19" height="21" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" fill="none"
                    viewBox="0 0 18 19">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z"
                        fill="currentColor">
                    </path>
                </svg>
                <Span class="mx-2">{{translations?.Login}}</Span>
            </a>
            <div class="px-1" *ngIf="storeDesignOptions?.showLanguageSelectOnHeader">
                <div class="relative" style="max-width: 50px;">
                    <select [ngModel]="currentLang" (ngModelChange)="this.customerAppService.onChangeLang($event)"
                        class="w-full appearance-none rounded-lg bg-transparent py-3 text-lg font-medium  outline-none transition">
                        <option [style.background]="menuBackgroundColor" [style.color]="menuFontColor"
                            *ngFor="let lang of storeAppService.generateLanguagesArray" [value]="lang.isoCode">
                            {{lang.name}}
                        </option>
                    </select>
                    <span [class.right-0]="!isRtl" [class.left-0]="isRtl"
                        class="absolute top-1/2 mt-[-2px] h-2 w-2 -translate-y-1/2 rotate-45 border-r border-b border-body-color">
                    </span>
                </div>
            </div>

            <!-- <h3 class="mb-5 text-lg font-semibold text-black">Quick Links</h3> -->
            <div class="flex items-center space-x-[14px]">

                <a *ngIf="customerServiceInfo?.mobilePhone" [style.color]="menuFontColor"
                    [href]="customerServiceAppService.getCallLink(customerServiceInfo?.mobilePhone)"
                    (click)="addFacebookPixelContactEvent()"
                    [title]="customerServiceAppService.getCallLink(customerServiceInfo?.mobilePhone)"
                    class="flex h-5 w-5 items-center justify-center hover:text-primary">
                    <svg width="20" height="20" viewBox="0 0 20 20" class="fill-current">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                    </svg>
                </a>

                <a *ngIf="socialMediaInfo?.facebook" [href]="socialMediaInfo?.facebook"
                    [title]="socialMediaInfo?.facebook" target="_blank" [style.color]="menuFontColor"
                    class="flex h-5 w-5 items-center justify-center  hover:text-primary">
                    <svg width="20" height="20" viewBox="0 0 20 20" class="fill-current">
                        <g clip-path="url(#clip0_3171_770)">
                            <path
                                d="M11.6654 11.2494H13.7487L14.582 7.91602H11.6654V6.24935C11.6654 5.39102 11.6654 4.58268 13.332 4.58268H14.582V1.78268C14.3104 1.74685 13.2845 1.66602 12.2012 1.66602C9.9387 1.66602 8.33203 3.04685 8.33203 5.58268V7.91602H5.83203V11.2494H8.33203V18.3327H11.6654V11.2494Z" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3171_770">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a *ngIf="socialMediaInfo?.instagram" [title]="socialMediaInfo?.instagram" target="_blank"
                    [style.color]="menuFontColor" [href]="socialMediaInfo?.instagram"
                    class="flex h-5 w-5 items-center justify-center hover:text-primary">
                    <svg aria-hidden="true" focusable="false" class="icon icon-instagram" viewBox="0 0 20 20">
                        <path fill="currentColor" fill-rule="evenodd"
                            d="M13.23 3.492c-.84-.037-1.096-.046-3.23-.046-2.144 0-2.39.01-3.238.055-.776.027-1.195.164-1.487.273a2.43 2.43 0 0 0-.912.593 2.486 2.486 0 0 0-.602.922c-.11.282-.238.702-.274 1.486-.046.84-.046 1.095-.046 3.23 0 2.134.01 2.39.046 3.229.004.51.097 1.016.274 1.495.145.365.319.639.602.913.282.282.538.456.92.602.474.176.974.268 1.479.273.848.046 1.103.046 3.238.046 2.134 0 2.39-.01 3.23-.046.784-.036 1.203-.164 1.486-.273.374-.146.648-.329.921-.602.283-.283.447-.548.602-.922.177-.476.27-.979.274-1.486.037-.84.046-1.095.046-3.23 0-2.134-.01-2.39-.055-3.229-.027-.784-.164-1.204-.274-1.495a2.43 2.43 0 0 0-.593-.913 2.604 2.604 0 0 0-.92-.602c-.284-.11-.703-.237-1.488-.273ZM6.697 2.05c.857-.036 1.131-.045 3.302-.045 1.1-.014 2.202.001 3.302.045.664.014 1.321.14 1.943.374a3.968 3.968 0 0 1 1.414.922c.41.397.728.88.93 1.414.23.622.354 1.279.365 1.942C18 7.56 18 7.824 18 10.005c0 2.17-.01 2.444-.046 3.292-.036.858-.173 1.442-.374 1.943-.2.53-.474.976-.92 1.423a3.896 3.896 0 0 1-1.415.922c-.51.191-1.095.337-1.943.374-.857.036-1.122.045-3.302.045-2.171 0-2.445-.009-3.302-.055-.849-.027-1.432-.164-1.943-.364a4.152 4.152 0 0 1-1.414-.922 4.128 4.128 0 0 1-.93-1.423c-.183-.51-.329-1.085-.365-1.943C2.009 12.45 2 12.167 2 10.004c0-2.161 0-2.435.055-3.302.027-.848.164-1.432.365-1.942a4.44 4.44 0 0 1 .92-1.414 4.18 4.18 0 0 1 1.415-.93c.51-.183 1.094-.33 1.943-.366Zm.427 4.806a4.105 4.105 0 1 1 5.805 5.805 4.105 4.105 0 0 1-5.805-5.805Zm1.882 5.371a2.668 2.668 0 1 0 2.042-4.93 2.668 2.668 0 0 0-2.042 4.93Zm5.922-5.942a.958.958 0 1 1-1.355-1.355.958.958 0 0 1 1.355 1.355Z"
                            clip-rule="evenodd"></path>
                    </svg>
                </a>
                <a *ngIf="socialMediaInfo?.ticktok" [title]="socialMediaInfo?.ticktok" target="_blank"
                    [style.color]="menuFontColor" [href]="socialMediaInfo?.ticktok"
                    class="flex h-5 w-5 items-center justify-center hover:text-primary">
                    <svg aria-hidden="true" focusable="false" class="icon icon-tiktok" viewBox="0 0 20 20">
                        <path fill="currentColor"
                            d="M10.511 1.705h2.74s-.157 3.51 3.795 3.768v2.711s-2.114.129-3.796-1.158l.028 5.606A5.073 5.073 0 1 1 8.213 7.56h.708v2.785a2.298 2.298 0 1 0 1.618 2.205L10.51 1.705Z">
                        </path>
                    </svg>
                </a>
                <a *ngIf="socialMediaInfo?.twitter" [title]="socialMediaInfo?.twitter" target="_blank"
                    [style.color]="menuFontColor" [href]="socialMediaInfo?.twitter"
                    class="flex h-5 w-5 items-center justify-center hover:text-primary">
                    <svg width="20" height="20" viewBox="0 0 20 20" class="fill-current">
                        <g clip-path="url(#clip0_3171_774)">
                            <path
                                d="M18.4702 4.71376C17.834 4.99517 17.1593 5.17999 16.4685 5.26209C17.1967 4.82662 17.7416 4.14127 18.0019 3.33376C17.3185 3.74043 16.5694 4.02543 15.7885 4.17959C15.264 3.61841 14.5688 3.24623 13.811 3.12091C13.0532 2.9956 12.2752 3.12417 11.5979 3.48664C10.9207 3.84911 10.3822 4.42516 10.0661 5.12525C9.75007 5.82534 9.67416 6.61024 9.8502 7.35793C8.46446 7.28847 7.10882 6.92836 5.87128 6.30098C4.63374 5.6736 3.54198 4.79297 2.66687 3.71626C2.35712 4.2483 2.19434 4.85312 2.19521 5.46876C2.19521 6.67709 2.81021 7.74459 3.74521 8.36959C3.19188 8.35217 2.65073 8.20274 2.16687 7.93376V7.97709C2.16704 8.78184 2.44552 9.56177 2.95508 10.1846C3.46465 10.8075 4.17394 11.235 4.96271 11.3946C4.44905 11.5338 3.91046 11.5543 3.38771 11.4546C3.6101 12.1473 4.04355 12.7531 4.62737 13.1872C5.21119 13.6213 5.91615 13.8619 6.64354 13.8754C5.92061 14.4432 5.09285 14.8629 4.20761 15.1106C3.32236 15.3582 2.39698 15.429 1.48438 15.3188C3.07746 16.3433 4.93195 16.8872 6.82604 16.8854C13.2369 16.8854 16.7427 11.5746 16.7427 6.96876C16.7427 6.81876 16.7385 6.66709 16.7319 6.51876C17.4143 6.02557 18.0032 5.41461 18.471 4.71459L18.4702 4.71376Z" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3171_774">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a [style.color]="menuFontColor" *ngIf="customerServiceInfo?.email"
                    href="mailto:{{customerServiceInfo?.email}}" target="_blank" [title]="customerServiceInfo?.email"
                    (click)="addFacebookPixelContactEvent()"
                    class="flex h-5 w-5 items-center justify-center  hover:text-primary">
                    <svg width="20" height="20" viewBox="0 0 20 20" class="fill-current">
                        <path
                            d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                        <path
                            d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                    </svg>

                </a>
            </div>
        </div>
    </div>
    <!-- ====== Drawer Section End -->

</ng-container>