import { ChangeDetectorRef, Component, Injector, NgZone, OnDestroy, OnInit, Optional } from '@angular/core';
import { AddressDto, CountryDto } from '@tajer/api';
import { GoogleMapsAppService } from '@tajer/ng.core';
import { DialogRef } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent extends BaseComponent implements OnInit, OnDestroy {

  //#region Variables
  map: HTMLElement;
  selectedSearch: string;
  countries?: CountryDto[];
  autoCompletedItems: any[];
  address: AddressDto;
  isMapOpened: boolean;
  loading: boolean;
  firstSearch: boolean;
  private unSubscribers: Subscription[];
  //#endregion

  constructor(
    injector: Injector,
    public zone: NgZone,
    private cdr: ChangeDetectorRef,
    @Optional() public dialog: DialogRef<GoogleMapComponentContext>,
    private googleMapApp: GoogleMapsAppService) {
    super(injector);
    this.unSubscribers = [];
    this.autoCompletedItems = [];
    this.selectedSearch = null;
    this.address = { target: null};
    this.firstSearch = false;
    this.isMapOpened = false;
  }

  ngOnInit(): void {
    this.setModalContext();
    this.initial();
    this.subscribeLoading();
    this.subscribeResults();
    this.subscribeOpeningMap();
    this.googleMapApp.setCountries(this.countries);
  }

  setModalContext() {
    if (this.dialog) {
      this.countries = this.dialog?.context.countries;
    }
  }

  initial() {
    const map = document.getElementById('mapContainer');
    this.googleMapApp.setMapDOM(map);
    map.style.display = 'none';
    document.getElementById('chooseMap').style.display = 'block';
  }

  subscribeOpeningMap(): void {
    const subscriber = this.googleMapApp
      .getMapLoaded().subscribe(
        (val: boolean) => {
          this.isMapOpened = val;
          this.cdr.detectChanges();
        });
    this.unSubscribers.push(subscriber);
  }

  subscribeLoading(): void {
    const subscriber = this.googleMapApp
      .getLoadingResults().subscribe((val: boolean) => {
        this.loading = val;
        this.cdr.detectChanges();
      });
    this.unSubscribers.push(subscriber);
  }

  subscribeResults(): void {
    const subscriber = this.googleMapApp
      .getResultsSearched().subscribe((items: any[]) => this.autoCompletedItems = items.slice());
    this.unSubscribers.push(subscriber);
  }

  getMyCurrentLocation() {
    this.googleMapApp.findMyLocation();
  }

  getResults(event: any) {
    this.firstSearch = true;
    this.autoCompletedItems = [];
    const searchText: string = event.detail.value;
    if (searchText !== this.selectedSearch) {
      this.selectedSearch = null;
    }
    this.googleMapApp.searchAddress(searchText);
  }

  placeSearchMarker(item: any) {
    this.googleMapApp.chooseAddress(item);
  }

  save() {
    this.dialog.close(this.googleMapApp.getAddressDto());
    if(this.isBrowser){
      document.body.classList.remove("modal-open");
    }  
  }

  close() {
    this.dialog.close();
    if(this.isBrowser){
      document.body.classList.remove("modal-open");
    }  
  }

  ngOnDestroy(): void {
    this.unSubscribers.forEach(
      (subscriber: Subscription) => subscriber.unsubscribe()
    );
  }

}

export class GoogleMapComponentContext extends BSModalContext {
  constructor() {
    super();
  }

  countries: CountryDto[];
}