import { StoreAppService } from "@storefront/ng.core";
import { TajerPlugin } from "./core/tajer-plugin";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
    providedIn: 'root',
})
export class GoogleAdWordsPlugin extends TajerPlugin {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.addGoogleAdWordsScript(this.storeAppService.storeConfig.externalServices?.googleAdWordsId);

        // Register event handlers
    }

    addGoogleAdWordsScript(googleAdWordsId: string) {
        var script = this.document.createElement('script');
        script.setAttribute('src', `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${googleAdWordsId}`);
        script.setAttribute('crossorigin', 'anonymous');
        script.setAttribute('async', 'async');
        this.document.body.appendChild(script);
    }
}
