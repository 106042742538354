import { Component, Injector } from '@angular/core';
import {
  SideBarConfiguration,
  SideBarDesignOptions,
} from '@storefront/ng.themes/lib/themes/default/default-control-panel-side-bar';
import { CustomerService } from '@tajer/api';
import { AuthAppService } from 'src/app/shared/app-services/auth-app.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-control-panel-side-bar',
  templateUrl: './control-panel-side-bar.component.html',
  styleUrls: ['./control-panel-side-bar.component.scss'],
})
export class ControlPanelSideBarComponent extends BaseComponent {
  //#region variables
  desginOptions: SideBarDesignOptions;
  configuration: SideBarConfiguration;
  //#endregion

  constructor(
    injector: Injector,
    private customerService: CustomerService,
    private authAppService: AuthAppService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.setIonicThemeOptions();
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions?.color,
      showReviewsInCustomerProfile: true,
        //this.storeAppService.storeOptions.showReviewsInCustomerProfile,
    };
    this.configuration = {
      myRewardsUrl: this.getUrl('/my-rewards').toString(),
      ordersUrl: this.getUrl('/orders').toString(),
      notificationsUrl: this.getUrl('/notifications').toString(),
      myPaymentCardsUrl: this.getUrl('/my-payment-cards').toString(),
      addressesUrl: this.getUrl('/addresses').toString(),
      changePasswordUrl: this.getUrl('/change-password').toString(),
    };
  }

  async deleteAccountAlert() {
    const dialogRef = this.modal
      .confirm()
      .title(this.trsnalteApp.translate('DeleteAccount'))
      .cancelBtn(this.trsnalteApp.translate('No'))
      .cancelBtnClass('modal-ok-btn')
      .okBtn(this.trsnalteApp.translate('Yes'))
      .okBtnClass('modal-ok-btn')
      .isBlocking(true)
      .showClose(false)
      .body(this.trsnalteApp.translate('DeleteAccountAlertMessage'))
      .dialogClass('modal-x-sm')
      .open();

    dialogRef.result.then(
      (result) => {
        if (result) {
          //alert.dismiss();
          this.confirmDeleteAccountAlert();
        }
      },
      () => {
        return;
      }
    );
  }

  async confirmDeleteAccountAlert() {
    const dialogRef = this.modal
      .confirm()
      .title(this.trsnalteApp.translate('DeleteAccount'))
      .cancelBtn(this.trsnalteApp.translate('Cancel'))
      .cancelBtnClass('modal-ok-btn')
      .okBtn(this.trsnalteApp.translate('DeleteMyAccount'))
      .okBtnClass('modal-ok-btn')
      .isBlocking(true)
      .showClose(false)
      .body(this.trsnalteApp.translate('ConfirmDeleteAccountAlertMessage'))
      .dialogClass('modal-x-sm')
      .open();

    dialogRef.result.then(
      (result) => {
        if (result) {
          //this.showLoading();
          //instead showLoading
          this.deleteAccount();
        }
      },
      () => {
        return;
      }
    );
  }

  deleteAccount() {
    this.customerService
      .deleteAccount()
      .toPromise()
      .then(
        async (response) => {
          this.showNotify(
            this.trsnalteApp.translate('AccountDeletedSuccessfully')
          );
          this.logout();
        },
        (error) => {
          this.showError(error);
        }
      );
  }

  async showLoading() {
    // const loading = await this.loadingCtrl.create({
    //   message: this.translate('AccountBeingDeleted') + '...',
    //   cssClass: 'loading-alert',
    // });
    // loading.style.setProperty("--store-color", this.templateConfig.color);
    // loading.present();
    // this.customerService.deleteAccount().toPromise().then(async (response) => {
    //   this.utility.showNotify(this.translate('AccountDeletedSuccessfully'));
    //   this.logout();
    //   loading.dismiss();
    // }, (error) => {
    //   this.utility.showError(error);
    //   loading.dismiss();
    // });
  }

  logout() {
    this.authAppService.logout();
  }

  checkIfEnabledCardPaymentGateway(): boolean {
    return (
      (this.storeAppService.storeConfig?.configFeatures
        ?.enabledCardPaymentGateway == 'Tap' && this.storeAppService.storeConfig?.configFeatures
          ?.enabledTapSaveCardPaymentGateway) ||
      this.storeAppService.storeConfig?.configFeatures
        ?.enabledCardPaymentGateway == 'Stripe'
    );
  }

  isActive(path: string): boolean {
    return this.router.url.includes(path);
  }
}
