import { Injectable } from '@angular/core';
import * as currency from 'currency.js';
import { CookieAppService } from './cookie-app.service';
import { CurrencyPosition, CurrencyStoreDto } from '@tajer/api';
import { StoreAppService } from '@storefront/ng.core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyAppService {

  //#region variables
  currencyBase?: number = null;
  symbolPosition?: string = 'after';
  precision: number = 2;
  symbol: string = '';
  //#endregion

  constructor(
    private cookieAppService: CookieAppService,
    private storeAppService: StoreAppService,
  ) { }

  format(value, precision: number): string {
    const x = currency(value, {
      symbol: this.symbol,
      precision: precision,
      decimal: '.',
      separator: ',',
      // ! as replacement for the currency symbol
      // # as replacement for the currency amount
      pattern: this.symbolPosition == 'before' ? '!#' : '#!'
    })
    return x.format();
  }

  value(value): number {
    const x = currency(value, {
      symbol: '',
      precision: this.precision
    })
    return x.value;
  }

  public get currnetCurrnecy(): CurrencyStoreDto {
    let currency: CurrencyStoreDto=this.storeAppService.currnetCurrnecy;
    if(this.multiCurrenciesEnabled){
      let foundedCurrency=this.storeCurrencies.find(c=>c.id==this.cookieAppService.getCurrentCurrencyId());
      if(foundedCurrency){
        currency=foundedCurrency;
      }
    }
    return currency;
  }

  public set currnetCurrnecyId(currencyId: string){
    this.cookieAppService.setCurrentCurrencyId(currencyId);
  }

  public get storeCurrencies(): CurrencyStoreDto[] {
    return this.storeAppService?.storeConfig?.currencies;
  }

  public get multiCurrenciesEnabled(): boolean {
    return this.storeCurrencies?.length > 0;
  }

  public get requestCurrencyId(): string {
    return this.multiCurrenciesEnabled && this.currnetCurrnecy.id != this.storeAppService.currnetCurrnecy.id ? this.currnetCurrnecy?.id : null
  }
  
  public get isCurrnetBefore(): boolean {
    return this.currnetCurrnecy.position == CurrencyPosition.Before;
  }

  public get isCurrnetAfter(): boolean {
    return this.currnetCurrnecy.position == CurrencyPosition.After;
  }
  
}
