import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseTableModalComponent } from '../choose-table-modal/choose-table-modal.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { LoadingModule } from '../loading/loading.module';



@NgModule({
  declarations: [
    ChooseTableModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LoadingModule
  ],
  exports: [ChooseTableModalComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA 
  ],
})
export class ChooseTableModalModule { }
