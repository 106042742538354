import { Injectable } from '@angular/core';
import { TranslateAppService } from './translate-app.service';
import { StoreAppService } from '@storefront/ng.core';
import { BrandStoreDto, CategoryStoreDto, StorePageStoreDto } from '@tajer/api';

@Injectable({
  providedIn: 'root'
})
export class EntityUtilityService {
  //#region varaibales
  languagesDisplayKeys: string[] = [];
  //#endregion

  constructor(
    private translateAppService: TranslateAppService,
    private storeAppService: StoreAppService
  ) { }


  /**
     * get property value depend on the current language
     *
     * @param entity any entity
     * @param valueKey property name
     */
  getEntityValue(entity: any, valueKey: string = 'name'): string {
    const originKey = valueKey;
    if (entity && valueKey) {
        valueKey += this.getCurrentLanguageDisplayKey();
        const value = entity[valueKey];
        return value ? value : entity[originKey];
    }
    return '';
  }

  /**
     * 
     * @param category 
     * @returns string | any[]
     */
  getCategoryUrl(category: CategoryStoreDto): string | any[] {
    const subCategories = this.storeAppService.getSubCategories(category?.id);
    const slug = this.getEntityValue(category, 'slug');
    // TODO: check if we still need this.storeConfig.options.goToSeperateSubCategories && 
    if (subCategories.length > 0) {
        return this.translateAppService.getUrl('/categories/' + slug);
    } else {
        return this.translateAppService.getUrl('/' + slug);
    }
  }

  getBrandUrl(brand: BrandStoreDto): string | any[] {
    const slug = this.getEntityValue(brand, 'slug');
    return this.translateAppService.getUrl('/brands/' + slug);
  }

  /**
     * get current language display key.
     * Display key is 1, 2 or 3. It depends on app config
     */
  private getCurrentLanguageDisplayKey(): string {
    if (!this.languagesDisplayKeys) {
        return '';
    }
    const languagesDisplayKey = this.languagesDisplayKeys[this.translateAppService.currentLang];
    return languagesDisplayKey ? languagesDisplayKey : '';
  }

  public getCurrentLanguagePosition(): number {
    if (!this.languagesDisplayKeys) {
        return 1;
    }
    const languagesDisplayKey = this.languagesDisplayKeys[this.translateAppService.currentLang];
    return languagesDisplayKey ? +languagesDisplayKey : 1;
  }

  populateLanguagesDisplayKeys(): void {
    this.languagesDisplayKeys = [];

    if (!this.storeAppService?.storeConfig) {
        return;
    }

    const languages = this.storeAppService.storeConfig.languages;
    if (!languages) {
        return;
    }
    const language1 = languages[0];
    const language2 = languages.length > 1 ? languages[1] : null;
    const language3 = languages.length > 2 ? languages[2] : null;

    if (language1) {
        this.languagesDisplayKeys[language1] = '';
    }

    if (language2) {
        this.languagesDisplayKeys[language2] = 2;
    }

    if (language3) {
        this.languagesDisplayKeys[language3] = 3;
    }
  }

  getlocalSymbol(entity: any): string {
    let name = entity?.symbol;
    let storeLangs = this.storeAppService.storeConfig?.country?.languages;
    if (storeLangs && storeLangs.length > 0 && storeLangs[0] == this.translateAppService.currentLang && entity?.localSymbol) {
        name = entity?.localSymbol;
    }
    return name;
  }

  getlocalName(entity: any): string {
    let name = entity?.name;
    let storeLangs = this.storeAppService.storeConfig?.country?.languages;
    if (storeLangs && storeLangs.length > 0 && storeLangs[0] == this.translateAppService?.currentLang && entity?.localName) {
      name = entity?.localName;
    }
    return name;
}

  getPageName(slug): string {
    let page: StorePageStoreDto = this.storeAppService.storeConfig.pages?.filter(x => this.getEntityValue(x, 'slug') == slug)[0];
    return this.getEntityValue(page);
  }

  getPrivacyPolicyObject(): object {
    const object = {
        'slug': this.storeAppService.pageInfo?.privacyPolicyPageSlug,
        'slug2': this.storeAppService.pageInfo?.privacyPolicyPageSlug2,
        'slug3': this.storeAppService.pageInfo?.privacyPolicyPageSlug3,
    }
    return object;
  }

  getTermsAndConditionsObject(): object {
    const object = {
        'slug': this.storeAppService.pageInfo?.termsAndConditionsPageSlug,
        'slug2': this.storeAppService.pageInfo?.termsAndConditionsPageSlug2,
        'slug3': this.storeAppService.pageInfo?.termsAndConditionsPageSlug3,
    }
    return object;
  }
  
}
