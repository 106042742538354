import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutletListComponent } from './outlet-list.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { DefaultOutletListModule } from '@storefront/ng.themes';

@NgModule({
  declarations: [OutletListComponent],
  imports: [CommonModule, SharedModule, DefaultOutletListModule],
  exports: [OutletListComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA 
  ],
})
export class OutletListModule {}
