import { DatePipe } from '@angular/common';
import { Component, Injector, Input, Optional } from '@angular/core';
import { DeliveryConfiguration, DeliveryDesignOptions } from '@storefront/ng.themes';
import { DeliveryType } from '@tajer/api';
import { DialogRef, overlayConfigFactory } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { DeliveryMethodsComponent, DeliveryMethodsComponentContext } from 'src/app/pages/delivery-methods/delivery-methods.component';
import { AddressAppService } from 'src/app/shared/app-services/address-app.service';
import { OutletsAppService } from 'src/app/shared/app-services/outlets-app.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ChooseTableModalComponent, ChooseTableModalComponentContext } from '../choose-table-modal/choose-table-modal.component';
import { PickupDatePickerComponent, PickupDatePickerComponentContext } from '../pickup-date-picker/pickup-date-picker.component';
import { CookieAppService } from 'src/app/shared/app-services/cookie-app.service';

@Component({
  selector: 'app-delivery-type-and-time',
  templateUrl: './delivery-type-and-time.component.html',
  styleUrls: ['./delivery-type-and-time.component.scss']
})
export class DeliveryTypeAndTimeComponent extends BaseComponent{
  //#region variables
  desginOptions: DeliveryDesignOptions;
  configuration: DeliveryConfiguration;
  @Input() hideOutletName: boolean;
  @Input() sideMenucart: boolean;
  @Input() homePage: boolean;
  @Input() checkoutPage: boolean;
  @Input() isModal: boolean;
  @Input() ouletList: boolean;
  @Input() header: boolean;
  @Input() itemSlug: string;
  deliveryType = DeliveryType;
  public get minimumDeliveryLimitColor(): string {
    return this.storeDesignOptions?.minimumDeliveryLimitColor ?? this.storeDesignOptions.color;
  }
  public get minimumDeliveryLimitFrontColor(): string {
    return this.storeDesignOptions?.minimumDeliveryLimitFrontColor ?? this.storeDesignOptions.frontColor;
  }
  showMore: boolean;
  //#endregion

  constructor(
    injector: Injector,
    public outletsAppService: OutletsAppService,
    public datePipe: DatePipe,
    @Optional() public dialog: DialogRef<DeliveryTypeAndTimeComponentContext>,
    public addressAppService: AddressAppService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.setIonicThemeOptions();
    this.setModalContext();
     //for first time if we have no outlets
     if(!this.outletsAppService.storeOutlets || this.outletsAppService.storeOutlets.length==0){
      this.outletsAppService.getAllOutlets().subscribe(
        data=>{
          //this.setFilteredItems();
        }
      );
    }
    this.orderAppService.openCart();
    if (!this.orderAppService.isShoppingCartEmpty || this.homePage || this.isModal) {
      this.orderAppService.checkIfQuickCheckoutEnabled();
    }
    this.orderAppService.deliveryTime = this.orderAppService?.order?.date || !this.currentOutlet.availableForLiveOrders ? 'scheduleOrder' : 'asap';
  }

  setIonicThemeOptions() {
   
    this.desginOptions = {
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };
    
    this.configuration = {
      enableQuickCheckout: true,
    };
  
  }

  setModalContext() {
    if (this.dialog) {
      this.isModal = this.dialog?.context.isDeliveryTypeAndTimeModal;
      this.homePage = this.dialog?.context.homePage;
      this.itemSlug = this.dialog?.context.itemSlug;
    }
  }

  async showDatePickerModal() {
    if (!this.deliveryAppService.isCurrentOutletOpenForDelievryType) {
      return;
    }
    const componentContext = <PickupDatePickerComponentContext>{
      isOpenCalendar: true,
      dialogClass: (this.currentTheme != 'ionic' && this.currentTheme != 'customizedIonic') ? 'modal-x-sm' : '',
      size: (this.currentTheme == 'ionic' || this.currentTheme == 'customizedIonic') ? 'sm' : ''
    };

    const dialogRef = this.modal.open(
      PickupDatePickerComponent,
      overlayConfigFactory(componentContext)
    );

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  setOrderDateEmpty() {
    if (this.orderAppService.order?.date) {
      this.orderAppService.order.date = null;
    }
  }

  onChangeDeliveryType(event) {
    if(this.currentTheme =='ionic' || this.currentTheme == 'customizedIonic'){
      this.orderAppService.order.deliveryType= event.detail.value;
    }else{
      this.orderAppService.order.deliveryType= event;
    }
    this.orderAppService.checkMinimumDeliveryLimit();
    this.deliveryAppService.currentDeliveryType = this.orderAppService.order.deliveryType;
    this.setOrderDateEmpty();
    if (!this.checkoutPage) {
      this.orderAppService.checkIfQuickCheckoutEnabled();
    }
    if (this.checkoutPage) {
      this.orderAppService.checkIfQuickCheckoutEnabledInCheckoutPage();
    }
    this.orderAppService.deliveryTime = this.currentOutlet.availableForLiveOrders ? 'asap' : 'scheduleOrder';
  }

  close() {
    if (this.outletsAppService.currentOutletId == null) {
      this.outletsAppService.currentOutletId = this.currentOutlet?.id;
    }
    this.dialog?.close();
    if(this.isBrowser){
      document.body.classList.remove("modal-open");
    }  
  }

  outletsList(showMore) {
    this.showMore = showMore;
  }

  async openAddAddressModal(editAddress: boolean, editDeliveryMethod: boolean) {
    const componentContext = <DeliveryMethodsComponentContext>{
      isDeliveryMethodsModal: true,
      editAddress: editAddress,
      editDeliveryMethod: editDeliveryMethod,
      size: !editAddress ? 'sm' : 'lg',
    };

    const dialogRef = this.modal.open(
      DeliveryMethodsComponent,
      overlayConfigFactory(componentContext)
    );

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  async openChooseTableModal() {
    const componentContext = <ChooseTableModalComponentContext>{
      dialogClass:'modal-x-sm'
    };

    const dialogRef = this.modal.open(
      ChooseTableModalComponent,
      overlayConfigFactory(componentContext)
    );

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  //#region marketplace
  get showStoreName(){
    return this.isMarketplace && this.cookieAppService.getMarketTenantId() != this.storeAppService.storeConfig.tenantId;
  }

  get relatedStore(){
    return this.getRelatedStore(this.cookieAppService.getMarketTenantId());
  }
  //#endregion

  editAddress() {
    if (this.storeOptions.enableQuickCheckout) {
      this.openAddAddressModal(true, true);
    } else {
      this.router.navigate([this.getUrl('/delivery-methods')]);
    }
  }

  get showRoundedDeliveryButtons() {
    return this.outletsAppService.storeOutletsHasDelivery?.length > 0 &&
      this.outletsAppService.storeOutletsHasSelfPickup?.length > 0 &&
      this.outletsAppService.storeOutletsHasDineIn?.length > 0
  }

}

export class DeliveryTypeAndTimeComponentContext extends BSModalContext {
  constructor() {
    super();
  }

  homePage: boolean;
  isDeliveryTypeAndTimeModal: boolean;
  itemSlug?: string;
}
