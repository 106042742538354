import { Component, EventEmitter, HostBinding, Injector, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { PriceRange, ScreenBreakpointsService } from '@storefront/ng.core';
import {  MerchItemConfiguration, MerchItemDesignOptions } from '@storefront/ng.themes';
import { ItemStoreService, ItemStoreBySlug, ItemDetailStoreBySlug, CartService, ModifierListService,
  CustomerFavoriteItemService, CartStoreDto, ItemDetailPricelistStoreBySlug, } from '@tajer/api';
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CustomCategoryStoreDto, } from '@storefront/ng.themes/lib/models';
import { FavoriteAppService } from 'src/app/shared/app-services/favorite-app.service';
import { OutletsAppService } from 'src/app/shared/app-services/outlets-app.service';
import { ItemsAppService } from 'src/app/shared/app-services/items-app.service';

// new-theme
import { DialogRef, overlayConfigFactory } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';

@Component({
  selector: 'app-item-quick-view',
  templateUrl: './item-quick-view.component.html',
  styleUrls: ['./item-quick-view.component.scss']
})
export class ItemQuickViewComponent extends BaseComponent {
  //#region variables

  loading: boolean;
  public get editMode(): boolean {
    if (this.orderLineId) {
      return true;
    }
    return false;
  }
  note: string;
  orderLineId: string;
  item: ItemStoreBySlug;
  itemDetail: ItemDetailStoreBySlug;
  selectedImageIndex = 0;
  images = [this.defaultImage];
  quantity: number = 1;
  quantityChanges$ = new Subject<number>();
  addingToCart: boolean;
  addedToCart: boolean;
  imageLoaded: boolean;
  @HostBinding('style.--hoverColor') hoverColor;
  @HostBinding('style.--hoverFrontColor') hoverFrontColor;

  @Input() itemLink: any;
  @Input() featured: boolean = false;
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  //merch theme
  anvogueDesginOptions: AnvogueItemDesignOptions;
  anvogueConfiguration: MerchItemConfiguration;

  // variants
  option1Id: string;
  option2Id: string;
  option3Id: string;
  categories: CustomCategoryStoreDto[] = [];
  // dynamic
  public get hasOption1(): boolean {
    if (this.item?.variantOption1Id) {
      return true;
    }
    return false;
  }

  public get hasOption2(): boolean {
    if (this.item?.variantOption2Id) {
      return true;
    }
    return false;
  }

  public get hasOption3(): boolean {
    if (this.item?.variantOption3Id) {
      return true;
    }
    return false;
  }

  isFavorite: boolean;
  public get priceColor(): string {
    return (
      this.storeDesignOptions?.itemPriceColor ?? this.storeDesignOptions?.color
    );
  }

  public get discountNoteColor(): string {
    return this.storeDesignOptions?.discountNoteColor ?? '#808080';
  }

  public get discountNoteBackgroundColor(): string {
    return this.storeDesignOptions?.discountNoteBackgroundColor ?? '#e2e2e2';
  }

  priceRange: PriceRange;
  outletChanged$: Subscription;
  marketTenantId: string;
  //keepRemoveCartModal
  @ViewChild('keepRemoveCartModalRef', { static: false })
  keepRemoveCartModalRef: TemplateRef<any>;
  dialogRef: DialogRef<any>;
  cartErrorMessage: string;
  resetLoading: boolean;
  //#endregion

  constructor(
    injector: Injector,
    private itemStoreService: ItemStoreService,
    private cartService: CartService,
    public outletsAppService: OutletsAppService,
    public modifierListService: ModifierListService,
    private favoriteService: CustomerFavoriteItemService,
    public favoriteAppService: FavoriteAppService,
    private itemsAppService: ItemsAppService,
    public screenBreakpointsService: ScreenBreakpointsService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.subscribeQuantityChanged(this.quantityChanges$);
    const params = this.itemLink.split('/');
    console.log(params);
    this.marketTenantId = params[1];

    if (this.screenBreakpointsService.isSizeMdOrUp) {
      this.fetchItem(params[2]);
    } else {
      setTimeout(() => {
        this.fetchItem(params[2]);
      }, 100);
    }

    if (this.isSegmentEnabled) {
      this.segmentService.implementEnterPageEvent('Catalog', 'Product details');
    }
    this.setAnvogueThemeOptions();
  }

  fetchItem(slug) {
    this.fetchItemBySlug(slug);
  }

  subscribeQuantityChanged(quantityChanges: Observable<number>) {
    quantityChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((quantity: any) => {
        if (Number.parseInt(quantity) >= 1) {
          this.quantity = quantity;
          if (environment.log) {
            console.log('quantity');
            console.log(this.quantity);
          }
        } else {
          // to correct any incorrect data
          this.quantity = 1;
        }
      });
  }

  increaseQuantity() {
    if (this.quantity < 99) {
      this.quantityChanges$.next(++this.quantity);
    }
  }

  decreaseQuantity() {
    if (this.quantity > 1) {
      this.quantityChanges$.next(--this.quantity);
    }
  }

  onUpdateQuantity(qunatity) {
    this.quantityChanges$.next(qunatity);
  }

  fetchItemBySlug(slug: string) {
    this.loading = true;
    this.itemStoreService
      .getSlug(encodeURI(slug), {
        warehouseId: this.currentOutlet?.warehouseId,
        branchId: this.currentOutlet?.branchId,
        languagePosition:
          this.entityUtilityService.getCurrentLanguagePosition(),
        currencyId: this.requestCurrencyId,
      })
      .toPromise()
      .then(
        (item) => {
          this.populateItem(item);
        },
        () => {
          this.loading = false;
          this.navigateToNotFound();
        }
      );
  }

  fetchItemBySlugMarketplace(slug: string, tenantId: string) {
    this.loading = true;
    this.itemStoreService
      .getMarketplaceSlug(slug, tenantId, {
        languagePosition:
          this.entityUtilityService.getCurrentLanguagePosition(),
        currencyId: this.requestCurrencyId,
      })
      .toPromise()
      .then(
        (item) => {
          this.populateItem(item);
        },
        () => {
          this.loading = false;
          this.navigateToNotFound();
        }
      );
  }

  populateItem(item: ItemStoreBySlug) {
    this.item = item;
    this.loading = false;
    if (this.customerAppService.hasLoggedIn) {
      this.checkIfItemExistInFavorite();
    }

    // item
    if (this.item.images) {
      this.populateImages();
    }

    if (this.item) {
      if (
        !this.storeOptions.changeAddToCartToSelectOption ||
        !this.isItemHasMultiVariants(this.item.itemDetails)
      ) {
        this.populateItemDetail();
      }
      this.populateItemCategories();

      if (this.isItemHasMultiVariants(this.item.itemDetails)) {
        this.priceRange = this.getPriceRangeForItem(this.item.itemDetails);
      }

      setTimeout(() => {
        this.checkIfOptionsSelected();
      }, 200);
    }
    this.populateMetaTags().then();

    this.pluginRegistry.trigger(
      'implementViewProductEvent',
      this.itemDetail,
      this.item.name,
      this.itemsAppService.source
    );

    if (this.isSegmentEnabled) {
      this.segmentService.implementViewProductEvent(this.item, this.itemDetail);
    }
  }

  populateItemCategories() {
    this.categories = [];
    this.storeAppService.allCategories?.forEach((category) => {
      this.item?.categoriesIds?.forEach((categoryItem) => {
        if (category.id === categoryItem) {
          let addedCategory: CustomCategoryStoreDto = Object.assign(
            {},
            category
          );
          addedCategory.url = this.getUrl(
            this.entityUtilityService.getCategoryUrl(category).toString()
          );
          addedCategory.name = this.getValue(category);
          this.categories.push(addedCategory);
        }
      });
    });
  }

  onChangeVariant() {
    this.populateItemDetail();
    this.resetQuantity();
    this.checkIfOptionsSelected();
    this.pluginRegistry.trigger('implementCustomizeProductEvent');
  }

  private populateItemDetail() {
    let itemDetail = this.getMatchedItemDetail();
    if (!itemDetail || (itemDetail && !itemDetail.id)) {
      itemDetail = this.item.itemDetails[0];
    }
    this.itemDetail = itemDetail;
    this.resetQuantity();

    // auto select variant image
    if (this.itemDetail.image) {
      this.selectedImageIndex = this.images.findIndex(
        (x) => x == this.itemDetail.image
      );
    }
  }

  checkIfOptionsSelected() {
    if (!this.option1Id && this.itemDetail?.variantOption1Value) {
      this.option1Id = this.itemDetail.variantOption1Value;
    }
    if (!this.option2Id && this.itemDetail?.variantOption2Value) {
      this.option2Id = this.itemDetail.variantOption2Value;
    }
    if (!this.option3Id && this.itemDetail?.variantOption3Value) {
      this.option3Id = this.itemDetail.variantOption3Value;
    }
  }

  private populateImages() {
    this.images = this.item.images.split(',');
  }

  addToCart(goToCart?: boolean) {
    if (
      this.outletsAppService.openSelectOutletWhenAddingToCart(
        this.getValue(this.item, 'slug')
      )
    ) {
      return;
    }
    this.addingToCart = true;
    this.cartService[this.isMarketplace ? 'addMarketplace' : 'addItem'](
      this.itemDetail.id,
      {
        quantity: this.quantity,
        token: this.storeAppService.storeConfig?.token,
        outletId: this.isMarketplace
          ? this.getMarketplaceCartOutletId(this.marketTenantId)
          : this.currentOutlet.id,
        note: this.note,
        cartLineModifiers: null,
        tenantId: this.isMarketplace ? this.marketTenantId : null,
        currencyId: this.requestCurrencyId,
      }
    )
      .toPromise()
      .then(
        (response) => {
          if (this.isMarketplace) {
            this.updateMarketTenantId(this.cartAppService.shoppingCart);
          }
          this.cartAppService.shoppingCart = response.cart;
          if (this.storeOptions.autoOpenCartWhenAddItemToCart) {
            this.openCartMenu();
          }
          this.addingToCart = false;
          this.addedToCart = true;
          this.showAddedToCart();
          this.pluginRegistry.trigger(
            'implementAddToCartEvent',
            this.itemDetail,
            this.getValue(this.item),
            this.quantity,
            this.itemsAppService.source
          );

          if (this.isSegmentEnabled) {
            this.segmentService.implementAddToCartEvent(
              this.itemDetail,
              this.item.name,
              this.quantity
            );
          }
          if (this.isSegmentEnabled && this.isFavorite) {
            this.segmentService.implementMoveProductFromWishlistToCartEvent(
              this.itemDetail,
              this.item.name,
              this.quantity
            );
          }

          // reset
          this.resetQuantity();
          this.note = '';
          if (this.storeOptions.autoBackWhenAddToCartOnItem) {
            //this.utility.goBack(this.navCtrl, this.routerOutlet);
          }
          if (goToCart) {
            this.router.navigate(['cart']);
          }
        },
        async (response) => {
          this.addingToCart = false;
          if (this.isMarketplace) {
            this.cartErrorMessage = response?.error?.error
              ? response?.error?.error.message
              : '';
            this.openKeepRemoveCartModal();
          } else {
            this.showError(response);
          }
        }
      );
  }

  private resetQuantity() {
    var defaultQuantity = this.itemDetail.minOrderQuantity
      ? this.itemDetail.minOrderQuantity
      : 1;
    this.quantityChanges$.next(defaultQuantity);
  }

  private showAddedToCart() {
    setTimeout(() => {
      this.addedToCart = false;
    }, 2500);
  }

  getMatchedItemDetail(): ItemDetailStoreBySlug {
    let itemDetail = {} as ItemDetailStoreBySlug;
    if (this.option1Id && !this.option2Id && !this.option3Id) {
      itemDetail = this.item.itemDetails.find(
        (x) => x.variantOption1Value === this.option1Id
      );
    }

    if (this.option1Id && this.option2Id && !this.option3Id) {
      itemDetail = this.item.itemDetails.find(
        (x) =>
          x.variantOption1Value === this.option1Id &&
          x.variantOption2Value === this.option2Id
      );
    }

    if (this.option1Id && this.option2Id && this.option3Id) {
      itemDetail = this.item.itemDetails.find(
        (x) =>
          x.variantOption1Value === this.option1Id &&
          x.variantOption2Value === this.option2Id &&
          x.variantOption3Value === this.option3Id
      );
    }

    return itemDetail;
  }

  checkIfItemExistInFavorite() {
    //if is mareketplace then we call checkExist in faviorate based on marketplace api
    let checkExistFaviorateObservable = null;
    if (this.isMarketplace) {
      checkExistFaviorateObservable =
        this.favoriteService.checkExistMarketplace({
          itemId: this.item.id,
          tenantId: this.marketTenantId,
        });
    } else {
      checkExistFaviorateObservable = this.favoriteService.checkExist(
        this.item.id
      );
    }
    checkExistFaviorateObservable.toPromise().then((response) => {
      this.isFavorite = response;
    });
  }

  addOrRemoveFavorite() {
    if (this.customerAppService.hasLoggedIn) {
      if (this.isFavorite) {
        this.removeFromFavorite();
      } else {
        this.addToFavorite();
      }
    } else {
      this.openAddToFavoriteAlert();
    }
  }

  addToFavorite() {
    this.favoriteAppService.addOrRemoveFavoriteItemId = this.item.id;
    this.favoriteAppService.addOrRemoveFavoriteLoading = true;
    //if is mareketplace then we call add to faviorate based on marketplace api
    let addToFaviorateObservable = null;
    if (this.isMarketplace) {
      addToFaviorateObservable = this.favoriteService.createMarketplace({
        itemId: this.item.id,
        tenantId: this.marketTenantId,
      });
    } else {
      addToFaviorateObservable = this.favoriteService.create({
        itemId: this.item.id,
      });
    }
    addToFaviorateObservable.toPromise().then(
      (response) => {
        this.favoriteAppService.addOrRemoveFavoriteLoading = false;
        this.favoriteAppService.addOrRemoveFavoriteItemId = null;
        this.isFavorite = true;
        this.pluginRegistry.trigger(
          'implementAddToWishlistEvent',
          this.itemDetail,
          this.item.name,
          this.quantity
        );

        if (this.isSegmentEnabled) {
          this.segmentService.implementAddToWishlistEvent(
            this.itemDetail,
            this.item.name
          );
        }
      },
      async (response) => {
        this.favoriteAppService.addOrRemoveFavoriteLoading = false;
        this.favoriteAppService.addOrRemoveFavoriteItemId = null;
        this.showError(response);
      }
    );
  }

  removeFromFavorite() {
    this.favoriteAppService.addOrRemoveFavoriteItemId = this.item.id;
    this.favoriteAppService.addOrRemoveFavoriteLoading = true;
    //if is mareketplace then we call remove from faviorate based on marketplace api
    let removeFromFaviorateObservable = null;
    if (this.isMarketplace) {
      removeFromFaviorateObservable = this.favoriteService.deleteMarketplace({
        itemId: this.item.id,
        tenantId: this.marketTenantId,
      });
    } else {
      removeFromFaviorateObservable = this.favoriteService.delete(this.item.id);
    }
    removeFromFaviorateObservable.toPromise().then(
      (response) => {
        this.favoriteAppService.addOrRemoveFavoriteLoading = false;
        this.favoriteAppService.addOrRemoveFavoriteItemId = null;
        this.isFavorite = false;
        if (this.isSegmentEnabled) {
          this.segmentService.implementRemoveFromWishlistEvent(
            this.itemDetail,
            this.item.name
          );
        }
      },
      async (response) => {
        this.favoriteAppService.addOrRemoveFavoriteLoading = false;
        this.favoriteAppService.addOrRemoveFavoriteItemId = null;
        this.showError(response);
      }
    );
  }

  ngOnDestroy() {
    this.htmlDocApp.removeProductTags();
    this.htmlDocApp.removeKeywordsTag();
    this.htmlDocApp.setTypeTag('store');
    this.outletChanged$?.unsubscribe();
  }

  sendInquiry() {
    const message = '';
    //   `*${this.getValue(this.item)?.trim()}*
    // https://${this.storeConfig.domains[0]}/catalog/${this.getValue(this.item, 'slug')}
    // `;
    window.open(
      'https://api.whatsapp.com/send/?phone=' +
      this.customerServiceInfo?.whatsApp +
      '&text=' +
      encodeURIComponent(message) +
      '&type=phone_number&app_absent=0',
      '_blank'
    );
  }

  public get disableDecreaseQuantityBtn() {
    return (
      this.itemDetail?.minOrderQuantity &&
      this.quantity == this.itemDetail?.minOrderQuantity
    );
  }

  public get disableIncreaseQuantityBtn() {
    return (
      this.itemDetail?.maxOrderQuantity &&
      this.quantity == this.itemDetail?.maxOrderQuantity
    );
  }

  openCartMenu() {
    this.orderAppService.openCart();
    this.cartAppService.sideMenuCartOpened = true;
  }

  getDiscountRatio(
    priceBeforeDiscount: number,
    priceAfterDiscount: number
  ): string {
    let ratio = (
      ((priceBeforeDiscount - priceAfterDiscount) / priceBeforeDiscount) *
      100
    ).toFixed(0);
    return ratio;
  }

  isItemHasMultiVariants(itemDetails: ItemDetailStoreBySlug[]): boolean {
    return itemDetails?.length > 1;
  }

  getPriceRangeForItem(itemDetails: ItemDetailStoreBySlug[]): PriceRange {
    var priceArray: number[] = [];
    var range: PriceRange = { min: 0, max: 0 };
    itemDetails?.forEach((row) => priceArray.push(row.price));
    range.min = Math.min(...priceArray);
    range.max = Math.max(...priceArray);
    return range;
  }

  populateMetaTags(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const title = this.item.metaTitle ?? this.item.name;
      const description =
        this.item.metaDescription ?? this.item.shortDescription;
      const image = this.item.thumbnail ? this.item.thumbnail : this.images[0];
      const url = this.getRequestFullUrl;
      this.htmlDocApp.SetMetas(title, description, url, image);
      this.htmlDocApp.setProductTags(
        this.item,
        this.itemDetail,
        this.createItemCategoryNameList(),
        this.storeAppService.storeConfig.currency.isoCode,
        this.entityUtilityService.getEntityValue(
          this.storeAppService.getBrandById(this.item?.brandId)
        ),
        this.trsnalteApp.translate('OutOfStock'),
        this.trsnalteApp.translate('InStock')
      );
      this.htmlDocApp.setTypeTag('product');
      resolve();
    });
  }

  createItemCategoryNameList(): string {
    var categoriesNames: string[] = [];
    this.categories?.forEach((category) => {
      categoriesNames.push(this.getValue(category));
    });
    return categoriesNames?.join('/');
  }

  async showRequiredModifiersAlert() {
    const dialogRef = this.modal
      .alert()
      .title(this.trsnalteApp.translate('Warning'))
      .okBtn(this.trsnalteApp.translate('Ok'))
      .okBtnClass('modal-ok-btn')
      .isBlocking(true)
      .showClose(false)
      .body(this.trsnalteApp.translate('ChooseItemOptions'))
      .dialogClass('modal-x-sm')
      .open();

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
    if (this.isBrowser) {
      document.body.classList.remove('modal-open');
    }
  }

  /**
   * in case we are in a marketplace and current cart is empty then
   * we change the current market tenant to the item store tenant id
   * and make it the store for the cart
   */
  private updateMarketTenantId(cart: CartStoreDto) {
    if (!cart || !cart.cartLines || cart.cartLines.length == 0) {
      this.cookieAppService.SetMarketTenantId(this.marketTenantId);
      //change outlet to the selected tenant outlet
      this.outletsAppService.updateStoreOutlet(
        this.getMarketplaceCartOutletId(this.marketTenantId)
      );
    }
  }

  //openKeepRemoveCartModal
  openKeepRemoveCartModal() {
    const componentContext = <BSModalContext>{
      inElement: true,
      dialogClass: 'modal-x-sm',
      isBlocking: false,
    };

    this.dialogRef = this.modal.open(
      this.keepRemoveCartModalRef,
      overlayConfigFactory(componentContext)
    );

    this.dialogRef.onDestroy.subscribe(() => {
      if (this.isBrowser) {
        document.body.classList.remove('modal-open');
      }
    });

    this.dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  closeKeepRemoveCartModal() {
    this.dialogRef?.close();
  }

  closeQuickView() {
    this.close.emit(true);
  }

  resetCart() {
    this.resetLoading = true;
    this.cartService
      .deleteMarketplace({
        tenantId: this.cookieAppService.getMarketTenantId(),
        token: this.storeAppService.storeConfig?.token,
        currencyId: this.requestCurrencyId,
      })
      .toPromise()
      .then(
        (response) => {
          this.cartAppService.shoppingCart = null;
          this.cookieAppService.SetMarketTenantId(
            this.storeAppService.storeConfig?.tenantId
          );
          //change outlet to the selected tenant outlet
          this.outletsAppService.updateStoreOutlet(
            this.getMarketplaceCartOutletId(
              this.storeAppService.storeConfig?.tenantId
            )
          );
          this.resetLoading = false;
          this.closeKeepRemoveCartModal();
        },
        (error) => {
          console.log('error');
          console.log(error);
          this.resetLoading = false;
          this.closeKeepRemoveCartModal();
        }
      );
  }

  get storeName() {
    return this.isMarketplace &&
      this.marketTenantId != this.storeAppService.storeConfig.tenantId
      ? this.getRelatedStore(this.marketTenantId)?.storeName
      : null;
  }

  get storeLogo() {
    return this.isMarketplace &&
      this.marketTenantId != this.storeAppService.storeConfig.tenantId
      ? this.getRelatedStore(this.marketTenantId)?.logo
      : null;
  }

  get storeUrl() {
    return this.isMarketplace &&
      this.marketTenantId != this.storeAppService.storeConfig.tenantId
      ? this.getUrl('/stores/' + this.marketTenantId).toString()
      : null;
  }

  //pricelist
  get priceBasedOnPriceListQuantity() {
    let priceListItem = this.itemDetail.pricelist?.find(pl => this.quantity >= pl.fromQuantity && this.quantity <= pl.toQuantity);
    if (priceListItem) {
      return priceListItem.price;
    } else {
      return this.itemDetail.price;
    }
  }

  checkPriceListItemActive(pricelistItem: ItemDetailPricelistStoreBySlug) {
    return this.quantity >= pricelistItem.fromQuantity && this.quantity <= pricelistItem.toQuantity;
  }

  get hasPriceList() {
    return this.itemDetail?.pricelist?.length > 0;
  }

  onChoosingImg(i: number) {
    this.selectedImageIndex = i;
  }


  onLoadImage() {
    setTimeout(() => {
      this.imageLoaded = true;
    }, 200);
  }

  selectedOptions: string[] = [];
  onSelectionChange(name: string) {
    if (!this.selectedOptions.includes(name)) {
      this.selectedOptions.push(name);
    } else {
      this.selectedOptions.splice(this.selectedOptions.indexOf(name), 1);
    }
  }
  setAnvogueThemeOptions() {
    this.anvogueDesginOptions = {
      showItemSkuOnItem: this.storeDesignOptions?.showItemSkuOnItem,
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
      showReviewsInItem: this.storeAppService.storeOptions.showReviewsInItem,
      showStockQuantityLabelOnItem:
        this.storeDesignOptions?.showStockQuantityLabelOnItem,
      showStockQuantityOnItem: this.storeDesignOptions?.showStockQuantityOnItem,
      disableAddToCartOnItem: this.storeOptions.disableAddToCartOnItem,
      changeAddToCartToSelectOption:
        this.storeOptions.changeAddToCartToSelectOption,
      showBuyNowBtnOnItem: this.storeDesignOptions?.showBuyNowBtnOnItem,
      isFavoriteItemsEnabled: this.storeOptions.isFavoriteItemsEnabled,
      showShareOnSocialMediaOnItem:
        this.storeDesignOptions.showShareOnSocialMediaOnItem,
      hidePrices: this.storeOptions.hidePrices,
      isInquiryOnWhatsAppEnabled: this.storeOptions.isInquiryOnWhatsAppEnabled,
      showCalories: this.storeOptions.showCalories,
      showCaloriesOnItem: this.storeDesignOptions?.showCaloriesOnItem,
      showDiscountNoteOnItem: this.storeDesignOptions?.showDiscountNoteOnItem,
      showFeaturesOnItemPage: this.storeDesignOptions?.showFeaturesOnItemPage,
      secondaryColor: this.storeDesignOptions?.secondaryColor,
      itemPriceColor: this.storeDesignOptions?.itemPriceColor ?
        this.storeDesignOptions?.itemPriceColor : this.storeDesignOptions?.frontColor,
    };

    this.anvogueConfiguration = {
      cartUrl: [this.getUrl('/cart')],
      deliveryMethodsEnabled: this.configFeatures?.deliveryMethods,
    };

    this.hoverColor= this.storeDesignOptions?.hoverColor?this.storeDesignOptions?.hoverColor:this.storeDesignOptions?.color;
    this.hoverFrontColor= this.storeDesignOptions?.hoverFrontColor?this.storeDesignOptions?.hoverFrontColor:this.storeDesignOptions?.frontColor;
  }
}

interface AnvogueItemDesignOptions extends MerchItemDesignOptions {
  secondaryColor: string;
  itemPriceColor: string;
}
