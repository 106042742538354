<lib-default-delivery-method-card
  [desginOptions]="desginOptions"
  [translations]="translations"
  [showMethodMinimumLimitError]="showMethodMinimumLimitError"
  [deliveryStatus]="deliveryStatus"
  [isCurrnetBefore]="isCurrnetBefore"
  [selected]="selected"
  [viewNote]="viewNote"
  [deliveredDate]="deliveredDate"
  [currnetSymbol]="currnetSymbol"
  [isCurrnetAfter]="isCurrnetAfter"
  [currentOrderStatus]="currentOrderStatus"
  [showFreeDeliveryLimit]="showFreeDeliveryLimit"
  [shoppingCart]="cartAppService.shoppingCart"
  [viewRadio]="viewRadio"
  [deliveryMethod]="deliveryMethod"
>
</lib-default-delivery-method-card>

<!-- <ion-item class="delivery-method-card" lines="none"
  [style.border-color]="selected ? storeDesignOptions.color: '#b1b1b1'">
  <ion-label class="mb-0">
    <div class="flex grid payment-box">
      <div class="mb-5">
        <span class="text-break bold">
          {{getValue(deliveryMethod, 'deliveryMethodName')}}
        </span>
      </div>
      <div class="text-break" *ngIf="showMethodMinimumLimitError && deliveryMethod?.minimumDeliveryLimit"
        style="color: red;margin-bottom: 1rem;">
        <span translate>MinimumCartLimitMustBe</span>:
        <span>
          <span *ngIf="isCurrnetBefore">
            {{currnetSymbol}}
          </span>
          <span>{{displayCurrency(deliveryMethod?.minimumDeliveryLimit)}}</span>
          <span *ngIf="isCurrnetAfter">
            {{currnetSymbol}}
          </span>
        </span>
      </div>
      <div class="text-break" style="margin: 0 0 1rem 0" *ngIf="deliveryMethod?.note && viewNote">
        {{deliveryMethod.note}}
      </div>
      <div>
        <div class="flex">
          <span *ngIf="isCurrnetBefore"> {{currnetSymbol}} </span>
          <div style="margin: 0 2px"><span>{{deliveryMethod?.deliveryFees}}</span></div>
          <span *ngIf="isCurrnetAfter"> {{currnetSymbol}} </span>
        </div>
        <div *ngIf="deliveryStatus !== shippingStatus.Delivered && currentOrderStatus != orderStatus.Completed">
          <div class="flex" style="margin: 0 2px;" *ngIf="deliveryMethod?.maxDuration && deliveryMethod?.duration">
            <span class="text-break ion-margin-end" translate>DeliveryWithin<span>: </span></span>
            <div class="flex" *ngIf="deliveryMethod?.duration">
              <p class="text-break" style="margin: 0 4px">{{getDuration(deliveryMethod?.duration, false)}}</p>
            </div>
            <div class="flex" *ngIf="deliveryMethod?.maxDuration">
              <p class="text-break" *ngIf="deliveryMethod?.maxDuration && deliveryMethod?.duration" translate>To</p>
              <p class="text-break" style="margin: 0 4px">{{getDuration(deliveryMethod?.maxDuration)}}</p>
            </div>
          </div>
          <div style="margin: 0 2px;" class="flex"
            *ngIf="!(deliveryMethod?.maxDuration && deliveryMethod?.duration) && (deliveryMethod?.maxDuration || deliveryMethod?.duration)">
            <span class="text-break ion-margin-end" translate>EstimatedDelivery<span>: </span></span>
            <div class="flex ion-margin-end" *ngIf="deliveryMethod?.duration">
              <p class="text-break">{{getDuration(deliveryMethod?.duration)}}</p>
            </div>
            <div class="flex" *ngIf="deliveryMethod?.maxDuration">
              <p class="text-break">{{getDuration(deliveryMethod?.maxDuration)}}</p>
            </div>
          </div>
        </div>
        <div
          *ngIf="deliveredDate && (deliveryStatus === shippingStatus.Delivered || currentOrderStatus === orderStatus.Completed)">
          <div style="margin: 0 2px;" class="flex">
            <span class="text-break ion-margin-end" translate>DeliveredAt<span>: </span></span>
            <div class="flex ion-margin-end" *ngIf="deliveredDate">
              <p class="text-break">{{deliveredDate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="showFreeDeliveryLimit && deliveryMethod?.freeDeliveryLimit > 0 && cartAppService?.shoppingCart.subTotal < deliveryMethod?.freeDeliveryLimit"
        [style.color]="storeDesignOptions.color" class="font-12 ion-margin-top"
        style="padding: 0.5rem;background: #fff3be;white-space: normal;">
        <span translate>SpendAnAdditional</span>
        <span style="margin: 0 5px">
          <span *ngIf="isCurrnetBefore">{{currnetSymbol}}</span>
          <span>{{displayCurrency(deliveryMethod?.freeDeliveryLimit)}}</span>
          <span *ngIf="isCurrnetAfter">{{currnetSymbol}}</span>
        </span>
        <span translate>ToQualifyForFreeDelivery</span>
      </div>
    </div>
  </ion-label>
  <ion-radio *ngIf="viewRadio" slot="start" [value]="deliveryMethod?.id" [style.--color-checked]="storeDesignOptions.color">
  </ion-radio>
</ion-item> -->
