import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressFormComponent } from './address-form.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { DefaultAddressFormModule } from '@storefront/ng.themes';
import { ModalModule } from 'ngx-modialog-7';
import { BootstrapModalModule } from 'ngx-modialog-7/plugins/bootstrap';
import { GoogleMapModule } from '../google-map/google-map.module';

@NgModule({
  declarations: [AddressFormComponent],
  imports: [
    CommonModule, 
    SharedModule, 
    DefaultAddressFormModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
    GoogleMapModule
  ],
  exports: [AddressFormComponent],
})
export class AddressFormModule {}
