<ng-container *ngIf="isModal">
  <div class="modal-content">
    <!--header-->
    <div class="modal-header">
      <button type="button" [class]="isRtl ? 'close header-close close-left' : 'close header-close'"
        (click)="close()">&times;</button>
      <h3 class="modal-title" [style.color]="storeDesignOptions.color">{{translations?.ChooseOutlet}}</h3>
    </div>
    <!--body-->
    <div class="modal-body modal-body-flow">
      <ng-content *ngTemplateOutlet="contentTemplate"></ng-content>
    </div>
    <div class="modal-footer">
      <div class="fixed-btn white-background" style="display: flex; justify-content: center;">
        <button (click)="chooseOutlet()" class="basic-btn footer-btn" [style.background]="storeDesignOptions.color"
          [style.color]="storeDesignOptions.frontColor" [disabled]="outlets.length ==0 || !selectedOutlet">
          {{translations.ChooseOutlet}}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isModal">
  <ng-content *ngTemplateOutlet="contentTemplate"></ng-content>
</ng-container>

<ng-template #contentTemplate>
  <ng-container *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'">
    <div class="container" style="overflow:auto;">
      <ion-searchbar ngDefaultControl [(ngModel)]="searchTerm" (ionClear)="clear()" (ionChange)="setFilteredItems()"
        *ngIf="!isListOnly" [placeholder]="trsnalteApp.translate('SearchForOutlet')" class="search">
      </ion-searchbar>
      <ion-list class="pt-0" [class.mb]="isModal">
        <div class="ion-padding-start" *ngIf="!isListOnly">
          <ion-toolbar>
            <ion-icon slot="start" name="car-outline" class="icon-courier ion-padding-end"
              [style.color]="storeDesignOptions.color"></ion-icon>
            <span class="bold font-18" [style.color]="storeDesignOptions.color" translate>DeliveryType</span> :
            <span class="bold font-18">{{title}}</span>
          </ion-toolbar>
        </div>
        <ion-radio-group ngDefaultControl [(ngModel)]="selectedOutlet" (ionChange)="onSelectOutlet($event)">
          <ion-item *ngFor="let outlet of filteredOutlets()" [disabled]="outlet?.status == outletStatus.Closed">
            <ion-radio slot="start" color="success" [value]="outlet" class="ion-no-margin"
              [disabled]="!outletsAppService.getOpenTimeInOutlet(outlet, deliveryType)?.isOpen"></ion-radio>
            <ion-grid>
              <ion-row *ngIf='outlet.image && !isListOnly'>
                <div class="outlet-photo" [style.background-image]="'url(' + outlet.image + ')'">
                </div>
              </ion-row>
              <ion-row>
                <ion-col size="12">
                  <div class="flex ion-justify-content-between ion-align-items-center">
                    <ion-label class="outlet-name">{{getValue(outlet)}}</ion-label>
                    <ion-chip class="inherit-cursor ion-no-margin font-12 line-height-inherit" color="success"
                      *ngIf="outlet?.status == outletStatus.Active">
                      <ion-label translate>Active</ion-label>
                    </ion-chip>
                    <ion-chip class="inherit-cursor ion-no-margin font-12 line-height-inherit" color="warning"
                      *ngIf="outlet?.status == outletStatus.Busy">
                      <ion-label translate>Busy</ion-label>
                    </ion-chip>
                    <ion-chip class="inherit-cursor ion-no-margin font-12 line-height-inherit" color="danger" *ngIf="outlet?.status == outletStatus.Closed ||
                        outlet?.status == outletStatus.TemporarilyClosed">
                      <ion-label *ngIf="outlet?.status == outletStatus.Closed" translate>ClosedOutlet</ion-label>
                      <ion-label *ngIf="outlet?.status == outletStatus.TemporarilyClosed" translate>TemerorlyClosed
                      </ion-label>
                    </ion-chip>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row
                *ngIf="orderAppService.isDeliveryTimeDisplayed() || addressAppService.getOutletAddressSummary(outlet)">
                <ion-col size="12">
                  <small class="outlet-address" *ngIf="orderAppService.isDeliveryTimeDisplayed()">
                    <span *ngIf="deliveryType == deliveryTypeEnum.Delivery">
                      {{getDuration(outlet?.deliveryLiveOrdersPreperationTime , true)}}</span>
                    <span *ngIf="deliveryType == deliveryTypeEnum.SelfPickup">
                      {{getDuration(outlet?.selfPickupLiveOrdersPreperationTime , true)}}</span>
                    <span *ngIf="deliveryType == deliveryTypeEnum.DineIn">
                      {{getDuration(outlet?.dineInLiveOrdersPreperationTime , true)}}</span>
                  </small>
                  <small class="outlet-address" *ngIf="addressAppService.getOutletAddressSummary(outlet)">
                    <a *ngIf="addressAppService.getOutletLocationLink(outlet)"
                      class="ion-text-center text-decoration-none" style="color: inherit"
                      [href]="addressAppService.getOutletLocationLink(outlet)"
                      target="_blank">{{addressAppService.getOutletAddressSummary(outlet)}}</a>
                    <span
                      *ngIf="!addressAppService.getOutletLocationLink(outlet)">{{addressAppService.getOutletAddressSummary(outlet)}}</span>
                  </small>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>
  </ng-container>

  <ng-container *ngIf="currentTheme == 'merch' || currentTheme == 'shopper' || currentTheme == 'restaurants'">
    <form>
      <div class="w-full px-3" *ngFor="let outlet of filteredOutlets();let i=index">
        <div class="mb-3">
          <input [value]="outlet" [(ngModel)]="selectedOutlet" (ngModelChange)="onSelectOutlet($event)"
            [disabled]="outlet?.status == outletStatus.Closed || !outletsAppService.getOpenTimeInOutlet(outlet, deliveryType)?.isOpen"
            type="radio" name="outletList" [id]="'outletList'+i" class="shipping sr-only" />

          <label [for]="'outletList'+i"
            class="flex bg-gray-2 cursor-pointer items-center overflow-hidden rounded-md border border-transparent bg-white py-4 px-6 sm:px-4 md:px-6 lg:px-4 xl:px-6">
            <div *ngIf='outlet.image' class="mx-4 w-full max-w-[40px] sm:max-w-[65px]">
              <img [src]="outlet.image" alt="fedex" />
            </div>
            <div [class.border-r]="outlet.image && isRtl" [class.border-l]="outlet.image && !isRtl" class="border-[#e7e7e7] px-4">
              <span class="title mb-1 block text-sm font-semibold text-black sm:text-base md:text-sm lg:text-base">
                {{getValue(outlet)}}
              </span>
              <span *ngIf="addressAppService.getOutletAddressSummary(outlet)"
                class="text-body-color block text-sm font-medium">
                <a *ngIf="addressAppService.getOutletLocationLink(outlet)" class="ion-text-center text-decoration-none"
                  style="color: inherit" [href]="addressAppService.getOutletLocationLink(outlet)"
                  target="_blank">{{addressAppService.getOutletAddressSummary(outlet)}}</a>
                <span
                  *ngIf="!addressAppService.getOutletLocationLink(outlet)">{{addressAppService.getOutletAddressSummary(outlet)}}</span>
              </span>
              <span *ngIf="orderAppService.isDeliveryTimeDisplayed()"
                class="text-body-color block text-sm font-semibold">
                <small class="outlet-address">
                  <span *ngIf="deliveryType == deliveryTypeEnum.Delivery">
                    {{getDuration(outlet?.deliveryLiveOrdersPreperationTime , true)}}</span>
                  <span *ngIf="deliveryType == deliveryTypeEnum.SelfPickup">
                    {{getDuration(outlet?.selfPickupLiveOrdersPreperationTime , true)}}</span>
                  <span *ngIf="deliveryType == deliveryTypeEnum.DineIn">
                    {{getDuration(outlet?.dineInLiveOrdersPreperationTime , true)}}</span>
                </small>
              </span>
            </div>
          </label>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>
<!-- <lib-default-outlet-list
  [desginOptions]="desginOptions"
  [configuration]="configuration"
  [translations]="translations"
  [isModal]="isModal"
  [title]="title"
  [isOpen]="isOpen"
  [searchTerm]="searchTerm"
  [getOutletAddressSummary]="getOutletAddressSummary"
  [isListOnly]="isListOnly"
  [getOutletLocationLink]="getOutletLocationLink"
  [isDeliveryTimeDisplayed]="isDeliveryTimeDisplayed"
  [selectedOutlet]="selectedOutlet"
  [outlets]="outlets"
  (clearDel)="clear()"
  (filteredItems)="setFilteredItems()"
  (selectOutlet)="onSelectOutlet()"
>
</lib-default-outlet-list> -->