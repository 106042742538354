import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subject } from 'rxjs';
import { Localize } from 'src/assets/languages/localize';
import { ILangeuge } from 'src/assets/languages/models';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { StoreAppService } from '@storefront/ng.core';

@Injectable({
  providedIn: 'root'
})
export class TranslateAppService {

  //#region variables
  languageChanged$: Subject<boolean> = new Subject();
  language: string;

  public get routerEvents(): Subject<string> {
    return this.localizeService.routerEvents;
  }
  public get currentLang(): string {
    return this.language || this.localizeService.parser.currentLang;
  }
  public get currentLanguage(): ILangeuge | undefined {
    return this.languages?.find(x => x.code == this.currentLang);
  }

  public set currentLang(lang: string) {
    this.language = lang;
    this.localizeService.changeLanguage(lang);
    this.translateService.use(lang);
    this.languageChanged$.next(true);
  }

  public get isRtl(): boolean | undefined {
    return this.currentLanguage?.isRtl;
  }
  public get dir(): string {
    return this.isRtl ? 'rtl' : 'ltr';
  }
  public get languages(): ILangeuge[] {
    return Localize.languages;
  }
  public getLanguageDetails(code: string): ILangeuge {
    return Localize.languages.find(l=>l.code==code);
  }

  //all translations
  translations: any;
  private transferState: TransferState;
  private TRANSLATIONS_KEY = makeStateKey('translations');
  //#endregion

  constructor(
    private localizeService: LocalizeRouterService,
    public translateService: TranslateService,
    private storeAppService: StoreAppService,
    injector: Injector
  ) {
    if (environment.application.ssrEnabled) {
      this.transferState = injector.get(TransferState);
    }
  }

  getAllTranslations(){
    this.translateService.getTranslation(this.translateService.currentLang).subscribe(
        data => {
          this.translations = data;
        }
      );
  }

  //get language from url
  get locationLang(){
    return this.localizeService.parser.getLocationLang();
  }

  get defaultLanguage(){
    return this.localizeService.parser.defaultLang;
  }

  performLanguageInitialization(){
    //if we are in default language; set the current language to first lang of storeConfig
    if(!this.locationLang && this.storeAppService.storeConfig && this.defaultLanguage != this.storeAppService.storeConfig?.languages[0]){
      this.language = this.storeAppService.storeConfig?.languages[0];
      this.translateService.use(this.language);
    }
  }

  getServerAllTranslations() {
    this.translateService.getTranslation(this.translateService.currentLang).subscribe(
      data => {
        this.translations = data;
        this.transferState?.set(this.TRANSLATIONS_KEY, data);
      }
    );
  }
  
  getBrowserAllTranslations() {
    if (this.transferState){
      this.translations=this.transferState.get(this.TRANSLATIONS_KEY, null);
    }
  }

  async translateAsync(key: string): Promise<string> {
    return await this.translateService.get(key).toPromise();
  }

  translate(key: string): string {
    return key?this.translateService.instant(key):'';
  }

  getUrl(url: string): any[] | string {
    const translatedPath = this.localizeService.translateRoute(url);
    return translatedPath;
  }

  public get isServicesStore(): boolean {
    return this.storeAppService?.storeConfig?.options?.enableServicesStore;
  }

  getTranslateKey(key: string): string {
      return this.isServicesStore ? key + '_ser' : key;
  }
}
