import { Component, EventEmitter, Injector, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { outletListConfiguration, outletListDesignOptions } from '@storefront/ng.themes';
import { DeliveryType, OutletStatus, OutletStoreDto } from '@tajer/api';
import { DialogRef } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { AddressAppService } from 'src/app/shared/app-services/address-app.service';
import { OutletsAppService } from 'src/app/shared/app-services/outlets-app.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-outlet-list',
  templateUrl: './outlet-list.component.html',
  styleUrls: ['./outlet-list.component.scss']
})
export class OutletListComponent extends BaseComponent implements OnInit {

  //#region variabels
  desginOptions: outletListDesignOptions;
  configuration: outletListConfiguration;

  outlets: OutletStoreDto[] = [];
  deliveryType: DeliveryType;
  selectedOutlet: OutletStoreDto;
  public searchTerm: string = "";
  title: string = "";
  @Input() isModal: boolean;
  @Output() outletChange = new EventEmitter();
  deliveryTypeEnum = DeliveryType;
  outletStatus = OutletStatus;
  @Output("outletsList") outletsList: EventEmitter<any> = new EventEmitter();
  @Input() outletChanged: OutletStoreDto;
  @Input() isListOnly: boolean;
  @Input() deliveryTypeChanged: DeliveryType;
  @Input() homePage: boolean;
  @Input() itemSlug: string;
  //#endregion

  constructor(
    injector: Injector,
    public outletsAppService: OutletsAppService,
    @Optional() public dialog: DialogRef<OutletListComponentContext>,
    public addressAppService: AddressAppService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.setThemeOptions();
    this.setModalContext();
    //for first time if we have no outlets
    if(this.outletsAppService.storeOutlets && this.outletsAppService.storeOutlets.length==0){
      this.outletsAppService.getAllOutlets().subscribe(
        data=>{
          this.setFilteredItems();
        }
      );
    }else{
      this.setFilteredItems();
    }
  }

  setThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };
    this.configuration = {
      outletImageUrl: '',
    };
  }

  setModalContext() {
    if (this.dialog) {
      this.isModal = this.dialog?.context.isOutletListModal;
      this.deliveryType = this.dialog?.context.deliveryType;
      this.itemSlug = this.dialog?.context.itemSlug;
    }
  }

  setFilteredItems() {
    this.deliveryType = this.deliveryAppService.currentDeliveryType;
    if (!this.deliveryType) {
      this.outlets = this.outletsAppService.storeOutlets;
    } else {
      switch (+this.deliveryType) {
        case DeliveryType.Delivery:
          this.outlets = this.outletsAppService.storeOutletsHasDelivery;
          this.title = this.trsnalteApp.translate('Delivery');
          break;

        case DeliveryType.SelfPickup:
          this.outlets = this.outletsAppService.storeOutletsHasSelfPickup;
          this.title = this.trsnalteApp.translate('SelfPickup');
          break;

        case DeliveryType.DineIn:
          this.outlets = this.outletsAppService.storeOutletsHasDineIn;
          this.title = this.trsnalteApp.translate('DineIn');
          break;

        default:
          break;
      }
    }
    this.selectedOutlet = this.outlets.find(o => o.id == this.currentOutlet.id);
    // put current outlet as first element in outlets array
    const fromIndex = this.outlets.indexOf(this.selectedOutlet);
    const toIndex = 0;
    const element = this.outlets.splice(fromIndex, 1)[0];
    this.outlets.splice(toIndex, 0, element);
    if (this.isListOnly) {
      if (this.outlets?.length > 3) {
        this.outletsList.emit(true);
        this.outlets = this.outlets?.slice(0, 3);
      } else {
        this.outletsList.emit(false);
      }
    }
  }

  filteredOutlets() {
    return this.searchTerm === "" ? this.outlets : this.outlets.filter(outlet => {
      return this.getValue(outlet)?.toLowerCase().includes(this.searchTerm?.toLowerCase());
    });
  }

  clear() {
    this.searchTerm = '';
  }

  async chooseOutlet() {
    if (this.isModal) {
      this.dialog?.close({ selectedOutlet: this.selectedOutlet, itemSlug: this.itemSlug })
      if(this.isBrowser){
        document.body.classList.remove("modal-open");
      }
    }
  }

  onSelectOutlet(ev) {
    let outlet=null;
    if(this.currentTheme=='ionic' || this.currentTheme == 'customizedIonic'){
      outlet = ev?.detail?.value;
    }else{
      outlet = ev;
    }
    if (outlet?.id !== this.currentOutlet?.id && !this.isModal) {
      this.outletsAppService.changeOutlet(outlet, this.itemSlug)
        .then(() => {
          this.outletChange.emit(outlet);
        })
        .catch((oldId) => {
          this.selectedOutlet = this.outlets.find(o => o.id == this.currentOutlet.id);
        });
    }
  }

  close() {
    this.dialog?.close();
    if(this.isBrowser){
      document.body.classList.remove("modal-open");
    }  
  }

  updateOutletsList() {
    this.setFilteredItems();
    this.filteredOutlets();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.updateOutletsList();
    if (this.homePage && simpleChanges["outletChanged"]) {
      setTimeout(() => {
        if (!this.orderAppService.isShoppingCartEmpty || !this.customerAppService.hasLoggedIn) {
          this.orderAppService.order.address = null;
          this.orderAppService.order.addressId = null;
          this.orderAppService.deliveryMethodZoneRate = null;
          this.orderAppService.order.deliveryMethodZoneRateId = null;
          this.orderAppService.checkIfQuickCheckoutEnabled();
        }
      }, 500);
    }
  }
}

export class OutletListComponentContext extends BSModalContext {
  constructor() {
    super();
  }

  deliveryType: DeliveryType;
  isOutletListModal: boolean;
  itemSlug?: string;
}
