import { Component, Injector } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent extends BaseComponent {

  //#region variabels

  //#endregion

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {

  }


  //added new 
  onSearch(searchTerm: string) {
  }

}
