import { ILangeuge } from "./models";

export const Localize = {
    locales: ["en", "ar", "de", 'ms', 'tr', 'es', 'fr', 'nl', 'so'],
    "prefix": "ROUTES.",
    languages: [
        { name: 'En', code: 'en', label: 'English', isRtl: false, metaCode: 'en_GB', metaName: 'English'},
        { name: 'عربي', code: 'ar', label: 'العربية', isRtl: true, metaCode: 'ar_AR', metaName: 'Arabic' },
        { name: 'De', code: 'de', label: 'Deutsch', isRtl: false, metaCode: 'de_DE', metaName: 'Deutsch' },
        { name: 'Ms', code: 'ms', label: 'Bahasa Melayu', isRtl: false, metaCode: 'ms_MS', metaName: 'Malay' },
        { name: 'Tr', code: 'tr', label: 'Türkçe', isRtl: false, metaCode: 'tr_TR', metaName: 'Türkçe' },
        { name: 'Es', code: 'es', label: 'Español', isRtl: false, metaCode: 'es_ES', metaName: 'Español' },
        { name: 'Fr', code: 'fr', label: 'Français', isRtl: false, metaCode: 'fr_FR', metaName: 'Français' },
        { name: 'Nl', code: 'nl', label: 'Nederlands', isRtl: false, metaCode: 'nl_NL', metaName: 'Nederlands' },
        { name: 'So', code: 'so', label: 'Somalia', isRtl: false, metaCode: 'so_SO', metaName: 'Somalia' },
        { name: 'Persian', code: 'fa', label: 'فارسی', isRtl: true , metaCode: 'fa_FA', metaName: 'Persian' },
    ] as ILangeuge[]
}