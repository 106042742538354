import { Injectable, Injector } from "@angular/core";
import { OAuthStorage } from "angular-oauth2-oidc";
import { CookieService, ICookieService } from "ngx-cookie";
import { environment } from "src/environments/environment";

@Injectable()
export class AppAuthStorage implements OAuthStorage {

  //#region variables
  cookieService: ICookieService;
  //#endregion

  constructor(
    injector: Injector
  ) {
    if (environment.application.ssrEnabled) {
      this.cookieService = injector.get(CookieService);
    }
  }

  getItem(key: string): string {
    if (this.cookieService) {
      return this.cookieService.get(key);
    }
    else {
      return localStorage.getItem(key);
    }
  }

  removeItem(key: string): void {
    if (this.cookieService) {
      return this.cookieService.remove(key);
    }
    else {
      return localStorage.removeItem(key);
    }
  }

  setItem(key: string, data: string): void {
    if (this.cookieService) {
      return this.cookieService.put(key, data);
    }
    else {
      return localStorage.setItem(key, data);
    }
  }

}
