import { DatePipe, isPlatformServer } from '@angular/common';
import { Component, Injector, Input, OnInit, Optional } from '@angular/core';
import { DeliveryType } from '@tajer/api';
import { DialogRef } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ScreenBreakpointsService } from '@storefront/ng.core';

@Component({
  selector: 'app-pickup-date-picker',
  templateUrl: './pickup-date-picker.component.html',
  styleUrls: ['./pickup-date-picker.component.scss']
})
export class PickupDatePickerComponent extends BaseComponent implements OnInit {

  //#region variables
  deliveryType = DeliveryType;
  selectedDate: string = '';
  wrongDate: boolean;
  @Input() isOpenCalendar: boolean;
  @Input() header: boolean;
  today = new Date();
  //#endregion

  constructor(
    injector: Injector,
    public screenBreakpointsService: ScreenBreakpointsService,
    @Optional() public dialog: DialogRef<PickupDatePickerComponentContext>,
    public datePipe: DatePipe
  ) {
    super(injector);
  }

  ngOnInit() {
    this.selectedDate = this.orderAppService.order?.date
    this.setModalContext();
  }

  setModalContext() {
    if (this.dialog) {
      this.header = this.dialog?.context.header;
      this.isOpenCalendar = this.dialog?.context.isOpenCalendar;
    }
  }

  changeDate(event) {
    if (this.currentTheme == "ionic" || this.currentTheme == 'customizedIonic') {
      this.selectedDate = event.detail.value;
    } else {
      this.selectedDate = event;
    }
  }

  save() {
    this.wrongDate = false;
    if (!this.selectedDate) {
      this.orderAppService.order.date = this.selectedDate;
      this.close();
    } else {
      var d = new Date(this.selectedDate);
      if (this.today.getTime() > d.getTime()) {
        this.wrongDate = true;
        return;
      }
      else {
        this.orderAppService.order.date = this.selectedDate;
        this.close();
      }
    }
  }

  close() {
    this.orderAppService.deliveryTime = this.orderAppService?.order?.date || !this.currentOutlet.availableForLiveOrders ? 'scheduleOrder' : 'asap';
    this.dialog?.close();
    if (this.isBrowser) {
      document.body.classList.remove("modal-open");
    }
  }

  onSelect(event) {
    if (this.currentTheme == "ionic" || this.currentTheme == 'customizedIonic') {
      this.isOpenCalendar = event.detail.value;
    } else {
      this.isOpenCalendar = event;
    }
    if (!this.isOpenCalendar) {
      this.selectedDate = '';
    }
  }

  getSelectedDate() {
    return new Date(this.selectedDate);
  }
}

export class PickupDatePickerComponentContext extends BSModalContext {
  constructor() {
    super();
  }

  header: boolean;
  isOpenCalendar: boolean;
}