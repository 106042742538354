import { StoreAppService } from "@storefront/ng.core";
import { TajerPlugin } from "./core/tajer-plugin";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

declare let gtag: Function;

@Injectable({
    providedIn: 'root',
})
export class TictookPlugin extends TajerPlugin {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.addTictookScript(this.storeAppService.storeConfig.externalServices?.tiktookId);

        // Register event handlers
    }

    addTictookScript(tictookId: string) {
        const tictookScript = `!function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t][];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t][],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        
          ttq.load('${tictookId}');
          ttq.page();
        }(window, document, 'ttq');`;
        var script = this.document.createElement('script');
        script.innerHTML = tictookScript;
        this.document.head.appendChild(script);
    }
}
