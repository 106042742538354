import { StoreAppService } from "@storefront/ng.core";
import { TajerPlugin } from "./core/tajer-plugin";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
    providedIn: 'root',
})
export class TidioPlugin extends TajerPlugin {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.insertTidioScript(this.storeAppService.storeConfig.externalServices?.tidioId);

        // Register event handlers
    }

    insertTidioScript(tidioPublicKey: string) {
        var script = this.document.createElement('script');
        script.setAttribute('src', `//code.tidio.co/${tidioPublicKey}.js`);
        this.document.body.appendChild(script);
      }
}
