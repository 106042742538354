
<lib-default-address-card
  [configuration]="configuration"
  [translations]="translations"
  [address]="address"
  [showAddressOnly]="showAddressOnly"
  [viewActions]="viewActions"
  [shortAddress]="shortAddress"
  [addressRecipient]="addressAppService.getAddressRecipient(address)"
  [addressShortSummary]="addressAppService.getAddressShortSummary(address)"
  [addressSummary]="addressAppService.getAddressSummary(address)"
  (deleteAdd)="delete.emit()"
></lib-default-address-card>
