import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './shared/components/main-layout/main-layout.component';
import { LoggdInGuard } from './shared/guards/loggd-in.guard';
import { AuthGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'category',
        loadChildren: () =>
          import('./pages/category/category.module').then(
            (m) => m.CategoryModule
          ),
      },
      {
        path: 'categories/:cateogry',
        loadChildren: () =>
          import('./pages/categories/categories.module').then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: 'page/:page',
        loadChildren: () =>
          import('./pages/custom-page/custom-page.module').then(
            (m) => m.CustomPageModule
          ),
      },
      {
        path: 'categories',
        loadChildren: () =>
          import('./pages/categories/categories.module').then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: 'cart',
        loadChildren: () =>
          import('./pages/cart/cart.module').then((m) => m.CartModule),
      },
      {
        path: 'checkout',
        loadChildren: () =>
          import('./pages/checkout/checkout.module').then(
            (m) => m.CheckoutModule
          ),
      },
      {
        path: 'checkout-details',
        loadChildren: () =>
          import('./pages/checkout-details/checkout-details.module').then(
            (m) => m.CheckoutDetailsModule
          ),
      },
      {
        path: 'catalog/filters/:filter',
        loadChildren: () =>
          import('./pages/items/items.module').then((m) => m.ItemsModule),
      },
      {
        path: 'catalog/id/:id',
        loadChildren: () =>
          import('./pages/item/item.module').then((m) => m.ItemModule),
      },
      {
        path: 'catalog/id/:id/:tenantId',
        loadChildren: () =>
          import('./pages/item/item.module').then((m) => m.ItemModule),
      },
      {
        path: 'catalog/:slug',
        loadChildren: () =>
          import('./pages/item/item.module').then((m) => m.ItemModule),
      },
      {
        path: 'catalog/:slug/:tenantId',
        loadChildren: () =>
          import('./pages/item/item.module').then((m) => m.ItemModule),
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('./pages/items/items.module').then((m) => m.ItemsModule),
      },
      {
        path: 'search/:search',
        loadChildren: () =>
          import('./pages/items/items.module').then((m) => m.ItemsModule),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./pages/items/items.module').then((m) => m.ItemsModule),
      },
      {
        path: 'change-password',
        loadChildren: () =>
          import('./pages/change-password/change-password.module').then(
            (m) => m.ChangePasswordModule
          ),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./pages/store-blog/store-blog.module').then(
            (m) => m.StoreBlogModule
          ),
      },
      {
        path: 'blog/search/:search',
        loadChildren: () =>
          import('./pages/store-blog/store-blog.module').then(
            (m) => m.StoreBlogModule
          ),
      },
      {
        path: 'blog/search',
        loadChildren: () =>
          import('./pages/store-blog/store-blog.module').then(
            (m) => m.StoreBlogModule
          ),
      },
      {
        path: 'blog/:id',
        loadChildren: () =>
          import('./pages/blog-details/blog-details.module').then(
            (m) => m.BlogDetailsModule
          ),
      },
      {
        path: 'brands',
        loadChildren: () =>
          import('./pages/brands/brands.module').then((m) => m.BrandsModule),
      },
      {
        path: 'brands/:brand',
        loadChildren: () =>
          import('./pages/items/items.module').then((m) => m.ItemsModule),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/my-profile/my-profile.module').then(
            (m) => m.MyProfileModule
          ),
      },
      {
        path: 'change-password',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/change-password/change-password.module').then(
            (m) => m.ChangePasswordModule
          ),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'login-checkout',
        canActivate: [LoggdInGuard],
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'register',
        canActivate: [LoggdInGuard],
        loadChildren: () =>
          import('./pages/register/register.module').then(
            (m) => m.RegisterModule
          ),
      },
      {
        path: 'register-checkout',
        canActivate: [LoggdInGuard],
        loadChildren: () =>
          import('./pages/register/register.module').then(
            (m) => m.RegisterModule
          ),
      },
      {
        path: 'edit-profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/edit-profile/edit-profile.module').then(
            (m) => m.EditProfileModule
          ),
      },
      {
        path: 'order/:id',
        loadChildren: () =>
          import('./pages/order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'order/:id/:tenantId',
        loadChildren: () =>
          import('./pages/order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'addresses',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/addresses/addresses.module').then(
            (m) => m.AddressesModule
          ),
      },
      {
        path: 'address-form/new',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/address/address.module').then((m) => m.AddressModule),
      },
      {
        path: 'address-form/:id/edit',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/address/address.module').then((m) => m.AddressModule),
      },
      {
        path: 'search-order',
        loadChildren: () =>
          import('./pages/search-order/search-order.module').then(
            (m) => m.SearchOrderModule
          ),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./pages/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'my-rewards',
        loadChildren: () =>
          import('./pages/my-rewards/my-rewards.module').then(
            (m) => m.MyRewardsModule
          ),
      },
      {
        path: 'outlets',
        loadChildren: () =>
          import('./pages/outlets/outlets.module').then((m) => m.OutletsModule),
      },
      {
        path: 'payment/:id',
        loadChildren: () =>
          import('./pages/payment/payment.module').then((m) => m.PaymentModule),
      },
      {
        path: 'payment/:id/:tenantId',
        loadChildren: () =>
          import('./pages/payment/payment.module').then((m) => m.PaymentModule),
      },
      {
        path: 'payment-status/:id',
        loadChildren: () =>
          import('./pages/payment-status/payment-status.module').then(
            (m) => m.PaymentStatusModule
          ),
      },
      {
        path: 'my-payment-cards',
        loadChildren: () =>
          import('./pages/my-payment-cards/my-payment-cards.module').then(
            (m) => m.MyPaymentCardsModule
          ),
      },
      {
        path: 'business-register',
        canActivate: [LoggdInGuard],
        loadChildren: () =>
          import('./pages/business-register/business-register.module').then(
            (m) => m.BusinessRegisterModule
          ),
      },
      {
        path: 'my-favorite',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/my-favorite/my-favorite.module').then(
            (m) => m.MyFavoriteModule
          ),
      },
      {
        path: 'delivery-methods',
        loadChildren: () =>
          import('./pages/delivery-methods/delivery-methods.module').then(
            (m) => m.DeliveryMethodsModule
          ),
      },
      {
        path: 'payment-methods',
        loadChildren: () =>
          import('./pages/payment-methods/payment-methods.module').then(
            (m) => m.PaymentMethodsModule
          ),
      },
      {
        path: 'payment-methods-checkout',
        loadChildren: () =>
          import('./pages/payment-methods/payment-methods.module').then(
            (m) => m.PaymentMethodsModule
          ),
      },
      {
        path: 'orders',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/orders-review/orders-review.module').then(
            (m) => m.OrdersReviewModule
          ),
      },
      {
        path: 'review/:id',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/review/review.module').then((m) => m.ReviewModule),
      },
      {
        path: 'review/:id/:tenantId',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/review/review.module').then((m) => m.ReviewModule),
      },
      {
        path: 'item-reviews/:id',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/item-reviews/item-reviews.module').then(
            (m) => m.ItemReviewsModule
          ),
      },
      {
        path: 'item-reviews/:id/:tenantId',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/item-reviews/item-reviews.module').then(
            (m) => m.ItemReviewsModule
          ),
      },
      {
        path: 'thanks-page',
        loadChildren: () =>
          import('./pages/thanks/thanks.module').then((m) => m.ThanksModule),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./pages/contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'stores',
        loadChildren: () =>
          import('./pages/related-stores/related-stores.module').then(
            (m) => m.RelatedStoresModule
          ),
      },
      {
        path: 'not-found',
        loadChildren: () =>
          import('./pages/not-found/not-found.module').then(
            (m) => m.NotFoundModule
          ),
      },
      {
        path: ':cateogry',
        loadChildren: () =>
          import('./pages/items/items.module').then((m) => m.ItemsModule),
      },
    ],
  },
  /** NOTE: This must in the end */
  { path: '**', redirectTo: 'not-found' },
  { path: '', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //preloadingStrategy: PreloadAllModules,
      //initialNavigation: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
