import { Injectable } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';
import { CartService, DeliveryType, ItemStoreService, OpenSelectOutletDialogOptions, OutletDto, OutletService, OutletStatus, OutletStoreDto, OutletTimeTableStoreDto } from '@tajer/api';
import { CartAppService } from './cart-app.service';
import { OrderAppService } from './order-app.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { TranslateAppService } from './translate-app.service';
import { CookieAppService } from './cookie-app.service';
import { DeliveryAppService } from './delivery-app.service';
import { DeliveryTypeAndTimeComponent, DeliveryTypeAndTimeComponentContext } from 'src/app/components/delivery-type-and-time/delivery-type-and-time.component';
import { overlayConfigFactory } from 'ngx-modialog-7';
import { OutletListComponent, OutletListComponentContext } from 'src/app/components/outlet-list/outlet-list.component';
import { EntityUtilityService } from './entity-utility.service';

@Injectable({
  providedIn: 'root'
})
export class OutletsAppService {

  outlets: OutletStoreDto[];
  outletChanged$ = new BehaviorSubject<boolean>(false);
  today = new Date();
  day = this.today.getDay();

  constructor(private storeAppService: StoreAppService,
    private outletService: OutletService,
    private translateAppService: TranslateAppService,
    private orderAppService: OrderAppService,
    private cartService: CartService,
    private itemService: ItemStoreService, 
    private modal: Modal,
    private entityUtilityService: EntityUtilityService,
    private cookieAppService: CookieAppService,
    private deliveryAppService: DeliveryAppService,
    private cartAppService: CartAppService) { }

  public get storeOutlets(): OutletStoreDto[] {
    return this.outlets?.filter(row => row.status != OutletStatus.Draft && row.status != OutletStatus.Disabled);
  }

  public get storeOutletsHasSelfPickup(): OutletStoreDto[] {
    return this.storeOutlets?.filter(row => row.availableForSelfPickup);
  }

  public get storeOutletsHasDineIn(): OutletStoreDto[] {
    return this.storeOutlets?.filter(row => row.availableForDineIn);
  }

  public get storeOutletsHasDelivery(): OutletStoreDto[] {
    return this.storeOutlets?.filter(row => row.availableForDelivery);
  }

  public getAllOutlets(): Observable<any> {
    //call the api and assign the outlets
    let outlet: OutletDto[];

    //if marektplace we get all outles for marketplace and related stores
    if(this.storeAppService.storeConfig.isMarketplace){
      return this.outletService.getMarketplaceList().pipe(
        map(
          data => {
            this.outlets = data;
            //select the outlet related to the current store in the marketplace
            this.updateStoreOutlet(this.getMarketplaceCartOutletId(this.cookieAppService.getMarketTenantId()))
            return data;
          }
        )
      );
    }else{
      return this.outletService.getStoreList({ status: OutletStatus.Active }).pipe(
        map(
          data => {
            this.outlets = data;
            return data;
          }
        )
      );
    }
    //this.outlets= [this.storeAppService.currentOutlet]
    //return new Observable<OutletStoreDto>();
  }

  async openChooseOutletModal(itemSlug: string = '') {
    const componentContext = <OutletListComponentContext>{
      isOutletListModal: true,
      deliveryType: this.deliveryAppService.currentDeliveryType,
      itemSlug: itemSlug,
      size: 'sm',  
      isBlocking: false    
    };
    
    const dialogRef = this.modal.open(
      OutletListComponent,
      overlayConfigFactory(componentContext)
    );

    dialogRef.result.then(
      (result) => {
        if (result) {
          this.changeOutlet(result.selectedOutlet , result.itemSlug);
        }
      },
      () => {
        return;
      }
    );

    // let modal = await this.modalController.create({
    //   component: OutletListPage,
    //   componentProps: {
    //     isModal: true,
    //     deliveryType: this.storeAppService.currentDeliveryType,
    //     itemSlug: itemSlug
    //   },
    //   cssClass: 'date-modal'
    // });
    // modal.onDidDismiss()
    //   .then((response) => {
    //     this.changeOutlet(response?.data?.selectedOutlet , response?.data?.itemSlug);
    //   });
    // return await modal.present();
  }

  changeOutlet(outlet: any, itemSlug: string = '') {
    return new Promise<any>((resolve, reject) => {
      if (outlet) {
        if (this.cartAppService.shoppingCart) {
          this.orderAppService.closeCartMenu();

          this.prenestResetCartAlert()
            .then(() => {
              // if user selected ok reset
              //this.close();
              this.currentOutletId = outlet.id;
              if (itemSlug) {
                this.showLoading(itemSlug);
              }
              resolve(true);
            })
            .catch(() => {
              reject(false);
            });
        } else {
          this.currentOutletId = outlet.id;
          if (itemSlug) {
            this.showLoading(itemSlug);
          }
          resolve(true);
        }
      }
    });

  }

  async prenestResetCartAlert() {
    return new Promise<any>(async (resolve, reject) => {
      const dialogRef = this.modal
        .confirm()
        .title(this.translateAppService.translate('Alert'))
        .cancelBtn(this.translateAppService.translate('Cancel'))
        .cancelBtnClass('modal-ok-btn')
        .okBtn(this.translateAppService.translate('Ok'))
        .okBtnClass('modal-ok-btn')
        .isBlocking(true)
        .showClose(false)
        .body(this.translateAppService.translate('SureResetCart'))
        .dialogClass("modal-x-sm")
        .open();

      await dialogRef.result.then(
        (result) => {
          if (result) {
            //todo; in the future in case the store is marketplace and have more than outlet we need to call deleteMarketplace api instead of this
            this.cartService.removeReset(this.storeAppService.storeConfig.token).toPromise().
              then((response) => {
                this.cartAppService.shoppingCart = null;
                this.orderAppService.showMethodMinimumLimitError = false;
                this.orderAppService.showFreeLimit = false;
                this.orderAppService.order.date = null;
                this.orderAppService.order.outletTable = null;
                document.body.classList.remove("modal-open");
                resolve(true);
              }, (error) => {
                document.body.classList.remove("modal-open");
                //this.utility.showError(error);
                reject(false);
              });
          }
        },
        () => {
          document.body.classList.remove("modal-open");
          reject(false);
        }
      );
    });
  }

  async showLoading(itemSlug) {
    // const loading = await this.loadingCtrl.create({
    //   message: this.translate('Loading'),
    //   cssClass: 'loading-alert',
    // });
    // loading.style.setProperty("--store-color", this.templateConfig.color);
    // loading.present();
    // this.itemService.get(itemSlug, {
    //   outletId: this.storeService.currentOutlet?.id
    // }).toPromise().then((item) => {
    //   loading.dismiss();
    // }, (error => {
    //   loading.dismiss();
    //   this.itemNotFoundAlert();
    // }))

    this.itemService.getSlug(itemSlug, {
        outletId: this.storeAppService.currentOutlet?.id,
        languagePosition: this.entityUtilityService.getCurrentLanguagePosition()
      }).toPromise().then((item) => {
      }, (error => {
        this.itemNotFoundAlert();
      }))
  }

  async itemNotFoundAlert() {
    const dialogRef = this.modal
      .confirm()
      .title(this.translateAppService.translate('Alert'))
      .okBtn(this.translateAppService.translate('Ok'))
      .okBtnClass('modal-ok-btn')
      .showClose(false)
      .body(this.translateAppService.translate('ItemNotFoundAlert'))
      .dialogClass("modal-x-sm")
      .open();

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  getOpenTimeInOutlet(outlet: OutletStoreDto, deliveryType): OutletWorkingTime {
    let isOpen: boolean;
    let outletTimeTable: OutletTimeTableStoreDto;
    let timeTables: OutletTimeTableStoreDto[] = outlet?.timeTables.filter(
      row => !deliveryType || (row.deliveryType == deliveryType && row.dayOfWeek == this.day));
    if (!outlet.specificTimeTableForSelfPickup) {
      isOpen = true;
    } else {
      isOpen = this.deliveryAppService.checkIfOutletOpen(outlet, deliveryType, timeTables);
      outletTimeTable = timeTables.sort((n1, n2) => n2.endHour - n1.endHour)[0];
    }
    let outletWorkingTime: OutletWorkingTime = {
      'isOpen': isOpen,
      'outletTimeTable': outletTimeTable
    };
    return outletWorkingTime;
  }

  public get currentOutletId(): string {
    const outlet_id = this.cookieAppService.getOutletId();
    if (outlet_id !== 'undefined' && outlet_id !== null) {
      return outlet_id;
    }
    return null;
  }

  public set currentOutletId(outletId: string) {
    this.cookieAppService.SetOutletId(outletId);
    //assign cuurentOutlet for store
    this.storeAppService.storeConfig.outlet = this.storeOutlets.find(o => o.id == this.currentOutletId);
    this.outletChanged$.next(true);
  }

  viewDeliveryType() {
    return this.storeOutletsHasDelivery?.length > 0 ||
      this.storeOutletsHasSelfPickup?.length > 0 ||
      this.storeOutletsHasDineIn?.length > 0;
  }

  public openSelectOutletWhenAddingToCart(slug: string = ''): boolean {
    if (this.currentOutletId == null && this.storeAppService.storeOptions.showSelectOutletDialog == OpenSelectOutletDialogOptions.WhenAddingToCartForFirstTime
      && this.cartAppService.shoppingCartCount == 0 && this.storeAppService.isMultiOutletEnabled) {
      this.showDeliveryTypeAndTimeModal(slug);
      return true;
    } else {
      return false;
    }
  }

  public openSelectOutletWhenStoreIsOpend() {
    if (this.currentOutletId == null && this.storeAppService.storeOptions.showSelectOutletDialog == OpenSelectOutletDialogOptions.WhenStoreIsOpenedForFirstTime
      && this.storeAppService.isMultiOutletEnabled) {
      this.showDeliveryTypeAndTimeModal();
    }
  }

  showDeliveryTypeAndTimeModal(slug: string = '') {
    const componentContext = <DeliveryTypeAndTimeComponentContext>{
      homePage: true,
      isDeliveryTypeAndTimeModal: true,
      itemSlug: slug,
      size: 'sm',      
    };
    
    const dialogRef = this.modal.open(
      DeliveryTypeAndTimeComponent,
      overlayConfigFactory(componentContext)
    );

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  //update storeOutlet
  updateStoreOutlet(outletId: string) {
    if (!this.storeOutlets || this.storeOutlets.length == 0) {
      this.getAllOutlets().subscribe(
        data => {
          this.storeAppService.storeConfig.outlet = this.outlets?.find(o => o.id == outletId);
        }
      );
    } else {
      this.storeAppService.storeConfig.outlet = this.outlets?.find(o => o.id == outletId);
    }
  }

  getMarketplaceCartOutletId(tenantId: string){
    return tenantId == this.storeAppService.storeConfig.tenantId ? this.storeAppService.currentOutlet.id : this.storeAppService.storeConfig.relatedStores.find(s => s.tenantId == tenantId)?.outletId;
  }

}

export interface OutletWorkingTime {
  isOpen?: boolean;
  outletTimeTable?: OutletTimeTableStoreDto
}
