import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuCartComponent } from '../side-menu-cart/side-menu-cart.component';
import { DeliveryTypeAndTimeModule } from '../delivery-type-and-time/delivery-type-and-time.module';
import { ItemWideCardModule } from '../item-wide-card/item-wide-card.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { LoadingModule } from '../loading/loading.module';



@NgModule({
  declarations: [
    SideMenuCartComponent
  ],
  imports: [
    CommonModule,
    DeliveryTypeAndTimeModule,
    ItemWideCardModule,
    SharedModule,
    LoadingModule
  ],
  exports:[
    SideMenuCartComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA 
  ],
})
export class SideMenuCartModule { }
