import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryMethodCardComponent } from './delivery-method-card.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { DefaultDeliveryMethodCardModule } from '@storefront/ng.themes';

@NgModule({
  declarations: [DeliveryMethodCardComponent],
  imports: [CommonModule, SharedModule, DefaultDeliveryMethodCardModule],
  exports: [DeliveryMethodCardComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA 
  ],
})
export class DeliveryMethodCardModule {}
