import { Injectable } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';
import { CartLineStoreDto, CustomerStoreDto, CustomerSummaryStoreDto, ItemDetailStoreBySlug, ItemDetailStoreDto, ItemStoreBySlug, ItemStoreDto, ItemStoreList, ItemStoreMarketplaceList, SalesOrderDto, SalesOrderStoreDto } from '@tajer/api';
import { CartAppService } from './cart-app.service';
import { EntityUtilityService } from './entity-utility.service';
import { OrderAppService } from './order-app.service';
import { environment } from 'src/environments/environment';
import { TranslateAppService } from './translate-app.service';

declare let analytics: any;

@Injectable({
  providedIn: 'root'
})
export class SegmentService {
  constructor(
    public storeAppService: StoreAppService,
    public cartAppService: CartAppService,
    private entityUtilityService: EntityUtilityService,
    private orderAppService: OrderAppService,
    private translateAppService: TranslateAppService 
    ) { }

  implementSearchEvent(searchTerm: string) {
    try {
      analytics?.track('Products Searched', {
        query: searchTerm
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementEnterPageEvent(categoryName: string, pageName: string) {
    try {
      analytics?.page(categoryName, pageName);
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementAddToCartEvent(item: ItemStoreList | ItemDetailStoreBySlug | ItemStoreMarketplaceList, itemName: string,  quantity: number = 1) {
    try {
      analytics?.track('Product Added', {
        cart_id: this.cartAppService?.shoppingCart?.id,
        product_id: item?.id,
        sku: item?.sku,
        name: itemName,
        price: item?.price,
        position: 1, // Position in the product list (ex. 3)
        //category: item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
        //url: `https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(item, 'slug')}`,
        //image_url: this.getItemImage(item),
        //brand: item?.brandId ? this.utility.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
        //variant: this.createVariantList(itemDetail),
        quantity: quantity
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementMoveProductFromWishlistToCartEvent(item: ItemStoreList | ItemDetailStoreBySlug | ItemStoreMarketplaceList, itemName: string, quantity: number = 1) {
    try {
      analytics?.track('Wishlist Product Added to Cart', {
        wishlist_id: '', // Wishlist ID to which the product was added to
        wishlist_name: '', // Wishlist name to which the product was added to
        cart_id: this.cartAppService?.shoppingCart?.id,
        product_id: item?.id,
        sku: item?.sku,
        //category: item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
        name: itemName,
        price: item?.price,
        position: 1, // Position in the product list (ex. 3)
        //url: `https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(item, 'slug')}`,
        //image_url: this.getItemImage(item),
        //brand: item?.brandId ? this.utility.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
        //variant: this.createVariantList(itemDetail),
        quantity: quantity
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementAddToWishlistEvent(item: ItemStoreList | ItemDetailStoreBySlug | ItemStoreMarketplaceList, itemName: string) {
    try {
      analytics?.track('Product Added to Wishlist', {
        wishlist_id: '', // Wishlist ID to which the product was added to
        wishlist_name: '', // Wishlist name to which the product was added to
        product_id: item?.id,
        sku: item?.sku,
        //category: item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
        name: itemName,
        price: item?.price,
        position: 1, // Position in the product list (ex. 3)
        //url: `https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(item, 'slug')}`,
        //image_url: this.getItemImage(item),
        //brand: item?.brandId ? this.utility.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
        //variant: this.createVariantList(itemDetail),
        //quantity: itemDetail?.quantity
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementRemoveFromWishlistEvent(item: ItemStoreList | ItemDetailStoreBySlug | ItemStoreMarketplaceList, itemName: string) {
    try {
      analytics?.track('Product Removed from Wishlist', {
        wishlist_id: '', // Wishlist ID to which the product was added to
        wishlist_name: '', // Wishlist name to which the product was added to
        product_id: item?.id,
        sku: item?.sku,
        //category: item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
        name: itemName,
        price: item?.price,
        position: 1, // Position in the product list (ex. 3)
        //url: `https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(item, 'slug')}`,
        //image_url: this.getItemImage(item),
        //brand: item?.brandId ? this.utility.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
        //variant: this.createVariantList(itemDetail),
        //quantity: itemDetail?.quantity
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementRemoveItemStoreFromWishlistEvent(item: ItemStoreDto, itemDetail: ItemDetailStoreDto, itemName: string) {
    try {
      analytics?.track('Product Removed from Wishlist', {
        wishlist_id: '', // Wishlist ID to which the product was added to
        wishlist_name: '', // Wishlist name to which the product was added to
        product_id: item?.id,
        sku: itemDetail?.sku,
        category: item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
        name: itemName,
        price: itemDetail?.price,
        position: 1, // Position in the product list (ex. 3)
        //url: `https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(item, 'slug')}`,
        image_url: this.getItemImage(item),
        //brand: item?.brandId ? this.utility.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
        variant: this.createVariantList(itemDetail),
        quantity: itemDetail?.quantity
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementClickOnProductEvent(item: ItemStoreList | ItemStoreMarketplaceList) {
    try {
      analytics?.track('Product Clicked', {
        product_id: item?.id,
        sku: item?.sku,
        name: item.name,
        price: item?.price,
        position: 1, // Position in the product list (ex. 3)
        //category: item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
        //url: `https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(item, 'slug')}`,
        //image_url: this.getItemImage(item),
       // brand: item?.brandId ? this.utility.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
        //variant: this.createVariantList(itemDetail),
        //quantity: itemDetail?.quantity
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementViewProductEvent(item: ItemStoreBySlug, itemDetail: ItemDetailStoreBySlug) {
    try {
      analytics?.track('Product Viewed', {
        product_id: item?.id,
        sku: itemDetail?.sku,
        name: item.name,
        price: itemDetail?.price,
        position: 1, // Position in the product list (ex. 3)
        //category: item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
        //url: `https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(item, 'slug')}`,
        image_url: this.getItemImage(item),
        //brand: item?.brandId ? this.utility.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
        variant: this.createVariantList(itemDetail),
        quantity: itemDetail.quantity
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementViewCartEvent(cartLines: CartLineStoreDto[]) {
    var products: any[] = []
    if (cartLines) {
      cartLines.forEach(cartLine => {
        products.push({
          product_id: cartLine?.item?.id,
          sku: cartLine?.item?.itemDetails.find(x => x.id == cartLine?.itemDetailId)?.sku,
          name: this.entityUtilityService.getEntityValue(cartLine?.item),
          price: cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)?.price,
          position: 1, // Position in the product list (ex. 3)
          category: cartLine?.item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(cartLine?.item) : "",
          //url: `https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(cartLine?.item, 'slug')}`,
          image_url: this.getItemImage(cartLine?.item),
          brand: cartLine?.item?.brandId ? this.entityUtilityService.getEntityValue(this.storeAppService.getBrandById(cartLine?.item?.brandId)) : "",
          variant: this.createVariantList(cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)),
          quantity: cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)?.quantity
        })
      });
    }
    try {
      analytics?.track('Cart Viewed', {
        cart_id: this.cartAppService?.shoppingCart?.id,
        products: products
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementRemoveFromCartEvent(cartLine: CartLineStoreDto) {
    const itemDetail = cartLine?.item?.itemDetails.find(x => x.id == cartLine?.itemDetailId);
    try {
      analytics?.track('Product Removed', {
        cart_id: this.cartAppService?.shoppingCart?.id,
        product_id: cartLine?.item?.id,
        sku: itemDetail?.sku,
        name: this.entityUtilityService.getEntityValue(cartLine?.item),
        price: itemDetail?.price,
        position: 1, // Position in the product list (ex. 3)
        category: cartLine?.item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(cartLine?.item) : "",
        //url: `https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(cartLine?.item, 'slug')}`,
        image_url: this.getItemImage(cartLine?.item),
        brand: cartLine?.item?.brandId ? this.entityUtilityService.getEntityValue(this.storeAppService.getBrandById(cartLine?.item?.brandId)) : "",
        variant: this.createVariantList(itemDetail),
        quantity: cartLine.quantity,
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementEnterCouponEvent() {
    try {
      analytics?.track('Coupon Entered', {
        order_id: '',
        cart_id: this.cartAppService?.shoppingCart?.id,
        coupon_id: this.orderAppService?.coupon?.id
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementApplyCouponEvent() {
    try {
      analytics?.track('Coupon Applied', {
        order_id: '',
        cart_id: this.cartAppService?.shoppingCart?.id,
        coupon_id: this.orderAppService?.coupon?.id,
        coupon_name: this.orderAppService?.coupon?.code,
        discount: this.orderAppService?.coupon?.discountValue
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementRemoveCouponEvent() {
    try {
      analytics?.track('Coupon Removed', {
        order_id: '',
        cart_id: this.cartAppService?.shoppingCart?.id,
        coupon_id: this.orderAppService?.coupon?.id,
        coupon_name: this.orderAppService?.coupon?.code,
        discount: this.orderAppService?.coupon?.discountValue
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementDenyCouponEvent(coupon: string, reason: string) {
    try {
      analytics?.track('Coupon Denied', {
        order_id: '',
        cart_id: this.cartAppService?.shoppingCart?.id,
        coupon: coupon,
        reason: reason
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  get analyticsUser() {
    if (!analytics) {
      return;
    }
    return analytics?.user ? analytics?.user() : null;
  }
  
  analyticsIdentify(
    storeId: string,
    storeName: string,
    customer: CustomerSummaryStoreDto,
    domain: string,
    country: string
  ) {
    if (!analytics) {
      return;
    }
    if (environment.log) {
      console.log('identity analytics');
      console.log(this.analyticsUser);
    }
    analytics?.group(storeId, {
      id: storeId,
      name: storeName,
      username: customer?.email,
      phone: customer?.phoneNumber,
      site: domain,
      address: {
        country: country,
      }
    });
    analytics?.identify(customer.userId, {
      id: customer.userId,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phone: customer.phoneNumber,
      language: this.translateAppService.currentLang,
      language_override: this.translateAppService.currentLang,
      company: {
        id: storeId,
        name: storeName
      },
      intercome: {
        language_override: this.translateAppService.currentLang
      }
    });
  }

  implementSignedInEvent(customer: CustomerSummaryStoreDto, storeId: string) {
    try {
      analytics?.track('Signed In', {
        first_name: customer?.firstName,
        last_name: customer?.lastName,
        email: customer?.email,
        phone: customer?.phoneNumber,
        username: '',
        title: '',
        "context.groupId": storeId
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementSignupEvent(type: string, customer: CustomerStoreDto, storeId: string) {
    try {
      analytics?.track('Signed Up', {
        type: type,
        first_name: customer?.firstName,
        last_name: customer?.lastName,
        email: customer?.email,
        phone: customer?.phoneNumber,
        username: '',
        title: '',
        "context.groupId": storeId
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  createVariantList(itemDetail: ItemDetailStoreBySlug | ItemDetailStoreDto): string {
    var variantString = "";
    var variantArray = [];
    if (itemDetail?.variantOption1Value) {
      variantArray.push(itemDetail.variantOption1Value)
    }
    if (itemDetail?.variantOption2Value) {
      variantArray.push(itemDetail.variantOption2Value)
    }
    if (itemDetail?.variantOption3Value) {
      variantArray.push(itemDetail.variantOption3Value)
    }
    
    if (variantArray.length > 0) {
      variantString = variantArray.join('-')
    }
    return variantString;
  }

  getItemImage(item: ItemStoreBySlug | ItemStoreDto): string {
    var images: string[] = [];
    var itemImage: string = '';
    if (item?.images) {
      images = item.images?.split(',');
      itemImage = images[0];
    } else {
      itemImage = ''//`https://${this.utility.storeConfig.domains[0]}/${this.defaultImage}`;
    }
    return itemImage;
  }

  public get isSegmentEnabled(): boolean {
    try {
      if (!analytics) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  }

  createItemCategoryNameList(item: ItemStoreDto): string {
    var categoriesNames: string[] = [];
    this.storeAppService.allCategories?.forEach(category => {
      item?.categoriesIds?.forEach(categoryItem => {
        if (category.id === categoryItem) {
          categoriesNames.push(this.entityUtilityService.getEntityValue(category));
        }
      });
    });
    return categoriesNames?.join("/");
  }

  implementViewCheckoutFirstStepEvent(deliveryMethodName: string) {
    try {
      analytics?.track('Checkout Step Viewed', {
        checkout_id: '',
        step: 1,
        shipping_method: deliveryMethodName
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementCompleteCheckoutFirstStepEvent(deliveryMethodName: string) {
    try {
      analytics?.track('Checkout Step Completed', {
        checkout_id: '',
        step: 1,
        shipping_method: deliveryMethodName
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementViewCheckoutSecondStepEvent(paymentMethodName: string) {
    try {
      analytics?.track('Checkout Step Viewed', {
        checkout_id: '',
        step: 2,
        payment_method: paymentMethodName
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementCompleteCheckoutSecondStepEvent(paymentMethodName: string) {
    try {
      analytics?.track('Checkout Step Completed', {
        checkout_id: '',
        step: 2,
        payment_method: paymentMethodName
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementStartCheckoutEvent(cartLines: CartLineStoreDto[], order: SalesOrderDto) {
    var products: any[] = []
    if (cartLines) {
      cartLines.forEach(cartLine => {
        products.push({
          product_id: cartLine?.item?.id,
          sku: cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)?.sku,
          name: this.entityUtilityService.getEntityValue(cartLine?.item),
          price: cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)?.price,
          position: 1, // Position in the product list (ex. 3)
          category: cartLine?.item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(cartLine?.item) : "",
          url: null,//`https://${this.utility.storeConfig.domains[0]}/catalog/${this.entityUtilityService.getEntityValue(cartLine?.item, 'slug')}`,
          image_url: this.getItemImage(cartLine?.item),
          brand: cartLine?.item?.brandId ? this.entityUtilityService.getEntityValue(this.storeAppService.getBrandById(cartLine?.item?.brandId)) : "",
          variant: this.createVariantList(cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)),
          quantity: cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)?.quantity
        })
      });
    }
    try {
      analytics?.track('Checkout Started', {
        order_id: order?.id,
        affiliation: '',
        value: order?.total,
        revenue: 0, // with discounts and coupons added in
        shipping: 0, // with discounts and coupons added in
        tax: this.orderAppService.totalTax,
        discount: order?.discount,
        coupon: order?.coupon?.code,
        currency: this.storeAppService.storeConfig?.currency?.isoCode,
        products: products
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementCompleteCheckoutEvent(order: SalesOrderDto) {
    try {
      analytics?.track('Checkout Step Completed', {
        checkout_id: '', // Checkout transaction ID
        step: 3,
        shipping_method: this.entityUtilityService.getEntityValue(order?.deliveryMethodZoneRate, 'deliveryMethodName'),
        payment_method: this.entityUtilityService.getEntityValue(order?.paymentMethod)
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementCompleteOrderEvent(cartLines: CartLineStoreDto[], order: SalesOrderDto) {
    var products: any[] = []
    if (cartLines) {
      cartLines.forEach(cartLine => {
        products.push({
          product_id: cartLine?.item?.id,
          sku: cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)?.sku,
          name: this.entityUtilityService.getEntityValue(cartLine?.item),
          price: cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)?.price,
          position: 1, // Position in the product list (ex. 3)
          category: cartLine?.item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(cartLine?.item) : "",
          url: null,//`https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(cartLine?.item, 'slug')}`,
          image_url: this.getItemImage(cartLine?.item),
          brand: cartLine?.item?.brandId ? this.entityUtilityService.getEntityValue(this.storeAppService.getBrandById(cartLine?.item?.brandId)) : "",
          variant: this.createVariantList(cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)),
          quantity: cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId)?.quantity
        })
      });
    }
    try {
      analytics?.track('Order Completed', {
        checkout_id: '',
        order_id: order?.id,
        affiliation: '',
        total: order?.total,
        subtotal: order?.subTotal,
        revenue: 0,
        shipping: 0,
        tax: this.orderAppService.totalTax,
        discount: order?.discount,
        coupon: order?.coupon?.code,
        currency: this.storeAppService.storeConfig?.currency?.isoCode,
        products: products
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementEnterPaymentInfoEvent(order: SalesOrderStoreDto) {
    try {
      analytics?.track('Payment Info Entered', {
        checkout_id: '', // Checkout transaction ID
        order_id: order?.id,
        step: 2, // Number representing a step in the checkout process
        shipping_method: '',
        payment_method: this.entityUtilityService.getEntityValue(order?.paymentMethod)
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementCancelOrderEvent(order: SalesOrderStoreDto) {
    var products: any[] = []
    if (order?.orderLines) {
      order?.orderLines.forEach(orderLine => {
        products.push({
          product_id: orderLine?.item?.id,
          sku: orderLine?.itemDetail?.sku,
          name: this.entityUtilityService.getEntityValue(orderLine?.item),
          price: orderLine?.itemDetail?.price,
          position: 1, // Position in the product list (ex. 3)
          category: orderLine?.item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(orderLine?.item) : "",
          url: null,//`https://${this.utility.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(orderLine?.item, 'slug')}`,
          image_url: this.getItemImage(orderLine?.item),
          brand: orderLine?.item?.brandId ? this.entityUtilityService.getEntityValue(this.storeAppService.getBrandById(orderLine?.item?.brandId)) : "",
          variant: this.createVariantList(orderLine?.itemDetail),
          quantity: orderLine?.itemDetail?.quantity
        })
      });
    }
    try {
      analytics?.track('Order Cancelled', {
        order_id: order?.id,
        affiliation: '',
        total: order?.total,
        revenue: 0,
        shipping: 0,
        tax: this.orderAppService.getOrderTotalTax(order),
        discount: order?.discount,
        coupon: order?.coupon?.code,
        currency: this.storeAppService.storeConfig?.currency?.isoCode,
        products: products
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementShareProductEvent(socialMedia: string, share_message: string, item: ItemStoreBySlug, itemDetail: ItemDetailStoreBySlug) {
    try {
      analytics?.track('Product Shared', {
        share_via: socialMedia,
        share_message: share_message,
        recipient: '',
        product_id: item?.id,
        sku: itemDetail?.sku,
        category: '',// item?.categoriesIds?.length > 0 ? this.createItemCategoryNameList(item) : "",
        name: this.entityUtilityService.getEntityValue(item),
        brand: item?.brandId ? this.entityUtilityService.getEntityValue(this.storeAppService.getBrandById(item?.brandId)) : "",
        variant: this.createVariantList(itemDetail),
        price: itemDetail?.price,
        url: null,//`https://${this.storeAppService.storeConfig.domains[0]}/catalog/${this.utility.getEntityValue(item, 'slug')}`,
        image_url: this.getItemImage(item),
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  reset() {
    if (!analytics) {
      return;
    }
    analytics?.reset();
  }
}