<div class="modal-content">
  <!--header-->
  <div class="modal-header">
    <button type="button" [class]="isRtl ? 'close close-left' : 'close'" (click)="close()">&times;</button>
    <h3 class="modal-title" [style.color]="storeDesignOptions.color">{{translations?.ChooseLocationMap}}</h3>
  </div>
  <!--body-->
  <div class="modal-body">
    <div style="position: relative;">

      <!-- Map -->
      <div id="mapContainer" class="w-100 h-100">
      </div>

      <div id="chooseMap">

        <!-- Search -->
        <ion-searchbar animated="true" [debounce]="1000" ngDefaultControl
          [placeholder]="trsnalteApp.translate('SearchPlace')" (ionChange)="getResults($event)"
          [(ngModel)]="selectedSearch" (ionCancel)="autoCompletedItems=[];selectedSearch=null">
        </ion-searchbar>

        <!-- Current Location -->
        <ion-list>
          <ion-item tappable (click)="getMyCurrentLocation()">
            <ion-text color="primary" class="flex-header" [style.flex-direction]="!isRtl?'row':'row-reverse'">
              <ion-icon color="primary" name="navigate-circle-outline">
              </ion-icon>
              <span translate class="me-2">
                CurrentLocation
              </span>
            </ion-text>
          </ion-item>

          <ion-item tappable (click)="getMyCurrentLocation()">
            <ion-text color="danger" class="flex-header" [style.flex-direction]="!isRtl?'row':'row-reverse'">
              <ion-icon name="map-outline" color="danger"></ion-icon>
              <span translate class="me-2">
                ChooseMap
              </span>
            </ion-text>
          </ion-item>

          <!-- Empty Results -->
          <ion-item class="flex-header" [style.flex-direction]="!isRtl?'row':'row-reverse'"
            *ngIf="autoCompletedItems.length === 0 &&  firstSearch &&!loading ">
            <ion-icon name="close-outline"></ion-icon>
            <span translate>EmptySearchResults</span>
          </ion-item>

          <!-- List Results -->
          <ng-container *ngIf="!loading && autoCompletedItems.length !== 0">
            <ion-item tappable (click)="placeSearchMarker(item)" *ngFor="let item of autoCompletedItems">
              <ion-icon name="location-outline"></ion-icon>
              {{ item.description }}
            </ion-item>

          </ng-container>

        </ion-list>

      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="isMapOpened">
      <ion-footer class="text-align-center">
        <ion-button (click)="save()" class="basic-btn" [style.color]="storeDesignOptions?.frontColor"
          [style.--background]="storeDesignOptions?.color">
          <ion-label>
            <span translate>Save</span>
          </ion-label>
        </ion-button>
      </ion-footer>
    </ng-container>
  </div>
</div>