import { StoreAppService } from "@storefront/ng.core";
import { TajerPlugin } from "./core/tajer-plugin";
import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root',
})
export class FacebookDVKPlugin extends TajerPlugin {

    constructor(
        private metaService: Meta,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.addfacebookDVKMeta(this.storeAppService.storeConfig.externalServices?.facebookDVK);

        // Register event handlers
    }

    addfacebookDVKMeta(facebookDVK: string) {
        this.metaService.updateTag({ name: 'facebook-domain-verification', content: facebookDVK });
    }
}
