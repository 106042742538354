import { Component, Injector, OnInit } from '@angular/core';
import { ScreenBreakpointsService } from '@storefront/ng.core';
import {
  CartLineStatus,
  CartLineStoreDto,
  CartService,
  DeliveryType,
} from '@tajer/api';
import { PluginRegistryService } from 'src/app/plugins/core';
import { CookieAppService } from 'src/app/shared/app-services/cookie-app.service';
import { ItemsAppService } from 'src/app/shared/app-services/items-app.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-side-menu-cart',
  templateUrl: './side-menu-cart.component.html',
  styleUrls: ['./side-menu-cart.component.scss'],
})
export class SideMenuCartComponent extends BaseComponent implements OnInit {
  //#region variants
  cartLineStatus = CartLineStatus;
  deliveryType = DeliveryType;
  public get availableCartLines(): CartLineStoreDto[] {
    return this.cartAppService.shoppingCart?.cartLines.filter(
      (cl) =>
        cl.status !== this.cartLineStatus.NotAvailable &&
        cl.status !== this.cartLineStatus.NoStockAvailable
    );
  }
  public get unavailableCartLines(): CartLineStoreDto[] {
    return this.cartAppService.shoppingCart?.cartLines.filter(
      (cl) =>
        cl.status === this.cartLineStatus.NotAvailable ||
        cl.status === this.cartLineStatus.NoStockAvailable
    );
  }
  //#endregion

  constructor(
    injector: Injector,
    private cartService: CartService,
    private pluginRegistryService: PluginRegistryService,
    private itemsAppService: ItemsAppService,
    public screenBreakpointsService: ScreenBreakpointsService
  ) {
    super(injector);
  }

  closeCartMenu() {
    if (
      this.screenBreakpointsService.isSizeLgOrUp &&
      (this.currentTheme == 'ionic' || this.currentTheme == 'customizedIonic')
    ) {
      this.cartAppService.sideMenuCartOpened = false;
    } else if (this.currentTheme == 'merch' || this.currentTheme == 'shopper' || this.currentTheme == 'restaurants') {
      //merch
      this.cartAppService.sideMenuCartOpened = false;
    }
    //this.menu.close('cart').then(r => { });
  }

  goToCartEdit() {
    this.router.navigate([this.getUrl('/cart')]);
  }

  getMatchedItemDetail(cartLine: CartLineStoreDto) {
    return cartLine.item?.itemDetails?.find(
      (x) => x.id === cartLine.itemDetailId
    );
  }

  getOrderLineTotal(cartLine: CartLineStoreDto) {
    return (
      cartLine.quantity *
      (this.getMatchedItemDetail(cartLine)?.price + cartLine.modifiersPrice)
    );
  }

  ngOnInit() {}

  sidemenuopen() {
    if (this.isSegmentEnabled) {
      this.segmentService.implementViewCartEvent(
        this.cartAppService?.shoppingCart?.cartLines
      );
    }
    if (
      !this.orderAppService.isShoppingCartEmpty &&
      this.configFeatures?.loyaltyProgram
    ) {
      this.cartAppService.loadingPoints = true;
      this.cartAppService.getEstimateRewordPoints(true);
    }
    if (this.isSegmentEnabled) {
      this.segmentService.implementEnterPageEvent('Cart', 'Side cart');
    }
  }

  removeFromCart(orderLine: CartLineStoreDto) {
    this.orderAppService.loadingRemove = true;
    this.orderAppService.removedItemId = orderLine.id;
    let deleteLinePromise = this.isMarketplace ? this.cartService.deleteMarketplaceLine(orderLine.id, {
      tenantId: this.cookieAppService.getMarketTenantId(),
      token: this.storeAppService.storeConfig?.token,
      currencyId: this.requestCurrencyId,
    }).toPromise() :
      this.cartService.remove(orderLine.id, {
        token: this.storeAppService.storeConfig?.token,
        currencyId: this.requestCurrencyId,
      }).toPromise();

    deleteLinePromise.then(
      (response) => {
        const index = this.cartAppService.shoppingCart.cartLines.findIndex(
          (x) => x.id == orderLine.id
        );
        if (index != -1) {
          this.cartAppService.shoppingCart.cartLines.splice(index, 1);
        }
        this.orderAppService.updateShoppingCart(response.cart);
        if (
          !this.orderAppService.isShoppingCartEmpty &&
          this.configFeatures?.loyaltyProgram
        ) {
          this.cartAppService.loadingPoints = true;
          this.cartAppService.getEstimateRewordPoints(false);
        }
        this.pluginRegistryService.trigger(
          'implementRemoveFromCartEvent',
          orderLine,
          this.itemsAppService.source
        );
        if (this.isSegmentEnabled) {
          this.segmentService.implementRemoveFromCartEvent(orderLine);
        }
        this.orderAppService.loadingRemove = false;
        this.orderAppService.removedItemId = null;
      },
      (error) => {
        this.orderAppService.loadingRemove = false;
        this.orderAppService.removedItemId = null;
      }
    );
  }

  checkout() {
    if (this.isMarketplace) {
      this.orderAppService.checkout(this.cookieAppService.getMarketTenantId());
    } else {
      this.orderAppService.checkout();
    }
    this.closeCartMenu();
  }

  getItemsTenantId() {
    return this.isMarketplace
      ? this.cookieAppService.getMarketTenantId()
      : null;
  }
}
