import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MerchSubscribeConfiguration,
  RestaurantsSubscribeConfiguration,
  RestaurantsSubscribeDesignOptions,
  ShopperSubscribeConfiguration,
} from '@storefront/ng.themes';
import {
  SubscribeFormConfiguration,
  SubscribeFormDesignOptions,
} from '@storefront/ng.themes/lib/themes/default/default-subscribe-form/models';
import { NewsLetterService } from 'src/app/shared/app-services/news-letter.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-subscribe-form',
  templateUrl: './subscribe-form.component.html',
  styleUrls: ['./subscribe-form.component.scss'],
})
export class SubscribeFormComponent extends BaseComponent implements OnInit {
  //#region variables
  desginOptions: SubscribeFormDesignOptions;
  configuration: SubscribeFormConfiguration;
  @Input() headColor?: string;
  @Input() frontColor?: string;
  @Input() showAsGrid?: boolean = false;
  error = '';

  //merch theme
  merchConfiguration: MerchSubscribeConfiguration;

  //shopper theme
  shopperConfiguration: ShopperSubscribeConfiguration;

  //restaurants theme
  restaurantsDesginOptions: RestaurantsSubscribeDesignOptions;
  restaurantsConfiguration: RestaurantsSubscribeConfiguration;
  @Input() footerFrontColor?: string;

  //#end region

  get subscribeDone() {
    return this.newsLetter.subscribeDone;
  }
  get subscribeLoading() {
    return this.newsLetter.subscribeLoading;
  }

  constructor(injector: Injector, private newsLetter: NewsLetterService) {
    super(injector);
  }

  ngOnInit() {
    if (this.currentTheme == 'ionic') {
      this.setIonicThemeOptions();
    } else if (this.currentTheme == 'merch') {
      this.setMerchThemeOptions();
    } else if (this.currentTheme == 'shopper') {
      this.setShopperThemeOptions();
    } else if (this.currentTheme == 'restaurants') {
      this.setRestaurantsThemeOptions();
    }
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions.color,
      frontColor: this.storeDesignOptions.color,
    };
    this.configuration = {
      newsletterText: this.trsnalteApp.translate(
        this.getValue(this.storeAppService.storeConfig, 'newsletterText')
      ),
    };
  }

  ///merch
  setMerchThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions.color,
      frontColor: this.storeDesignOptions.newsletterColor??this.frontColor,
    };
    this.merchConfiguration = {
      newsletterText: this.trsnalteApp.translate(
        this.getValue(this.storeAppService.storeConfig, 'newsletterText')
      ),
    };
  }

  //shopper
  setShopperThemeOptions() {
    this.shopperConfiguration = {
      newsletterText: this.trsnalteApp.translate(
        this.getValue(this.storeAppService.storeConfig, 'newsletterText')
      ),
    };
  }

  //Restaurants
  setRestaurantsThemeOptions() {
    this.restaurantsDesginOptions = {
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };

    this.restaurantsConfiguration = {
      newsletterText: this.trsnalteApp.translate(
        this.getValue(this.storeAppService.storeConfig, 'newsletterText')
      ),
    };
  }

  subscribe(email: FormControl) {
    this.error = '';
    if (!email.invalid) {
      this.newsLetter
        .subscribe(email.value)
        .then((response) => {})
        .catch((err) => {
          console.log(err);
          this.error = err;
        })
        .finally(() => {});
    } else {
      email.markAsDirty();
    }
  }

  resetSubDone(email: FormControl) {
    email.reset();
    this.newsLetter.subscribeDone = false;
  }
}
