import { StoreAppService } from "@storefront/ng.core";
import { TajerPlugin } from "./core/tajer-plugin";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
    providedIn: 'root',
})
export class HotJarPlugin extends TajerPlugin {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.addHotJarScript(this.storeAppService.storeConfig.externalServices?.hotJarId);

        // Register event handlers
    }

    addHotJarScript(hotJarId: string) {
        const hotJarScript = `(function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:'${hotJarId}',hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
        var script = this.document.createElement('script');
        script.innerHTML = hotJarScript;
        this.document.head.appendChild(script);
    }
}
