import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressBookCardComponent } from './address-book-card.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AddressCardModule } from '../address-card/address-card.module';
import { DefaultAddressBookCardModule } from '@storefront/ng.themes';
import { ModalModule } from 'ngx-modialog-7';
import { BootstrapModalModule } from 'ngx-modialog-7/plugins/bootstrap';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
  declarations: [AddressBookCardComponent],
  imports: [
    CommonModule,
    SharedModule,
    AddressCardModule,
    DefaultAddressBookCardModule,
    LoadingModule,
    ModalModule.forRoot(),
    BootstrapModalModule
  ],
  exports: [AddressBookCardComponent],
})
export class AddressBookCardModule {}
