import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AddressCardConfiguration } from '@storefront/ng.themes';
import { AddressAppService } from 'src/app/shared/app-services/address-app.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent extends BaseComponent {
  //#region variables
  configuration: AddressCardConfiguration;
  @Input() address: any;//AddressDto | OrderAddressDto;
  @Input() viewActions: boolean = true;
  @Input() showAddressOnly: boolean;
  @Output() delete = new EventEmitter();
  @Input() shortAddress:any;
  //#endregion

  constructor(
    injector: Injector,
    public addressAppService: AddressAppService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.setIonicThemeOptions();
  }
  
  setIonicThemeOptions() {
    this.configuration = {
      addressFormIdUrl: this.getUrl('/address-form/' + this.address?.id + '/edit').toString(),
    };
  }
  
}
