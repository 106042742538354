export class Theme {
    name?: string;
    dependencies?: ThemeDependency[];

    addDependency(name: string, type: ThemeDependencyType, value: string, version?: string, provider?: string) {
        if (!this.dependencies) {
            this.dependencies = [];
        }
        this.dependencies.push({
            name: name,
            type: type,
            value: value,
            provider: provider,
            version: version
        });
    }

    addScriptDependency(name: string, value: string, version?: string, provider?: string) {
        this.addDependency(name, ThemeDependencyType.Script, value, version, provider);
    }

    addStyleDependency(name: string, value: string, version?: string, provider?: string) {
        this.addDependency(name, ThemeDependencyType.Style, value, version, provider);
    }

    addFontDependency(name: string, value: string) {
        this.addDependency(name, ThemeDependencyType.Font, value);
    }
}

export class ThemeDependency {
    name?: string;
    version?: string;
    provider?: string;
    type?: ThemeDependencyType;
    value?: string;
    strategy?: string;
    loadOnServer?: boolean;
}

export enum ThemeDependencyType {
    Script = 0,
    Style = 1,
    Font = 2,
    // Image = 3,
    // Video = 4,
    // Audio = 5,
    // Document = 6,
    // Other = 7
}

export class ThemeTemplate {
    id?: string;
    name?: string;
    content?: string;
}

export class ThemeTemplateOption {
    id?: string;
    name?: string;
    type?: string;
    hideOnMobile?: boolean;
    hideOnTablet?: boolean;
    hideOnDesktop?: boolean;
}