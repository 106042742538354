import { StoreAppService } from "@storefront/ng.core";
import { TajerPlugin } from "./core/tajer-plugin";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
    providedIn: 'root',
})
export class CrazyEggPlugin extends TajerPlugin {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.addCrazyEggScript(this.storeAppService.storeConfig.externalServices?.crazyEggId);

        // Register event handlers
    }

    addCrazyEggScript(crazyEggId: string) {
        var script = this.document.createElement('script');
        script.setAttribute("type", "text/javascript");
        script.src = "//script.crazyegg.com/pages/scripts/" + crazyEggId?.slice(0, 4) + "/" + crazyEggId?.slice(4, 8) + ".js";
        script.setAttribute("async", "async");
        this.document.head.appendChild(script);
    }

}
