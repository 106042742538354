import { Injectable } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';
import { BrandStoreDto, CategoryStoreDto, ItemStoreList, ItemStoreMarketplaceList, ItemStoreMarketplaceRequestDto, ItemStoreRequestDtoV2, ItemStoreService } from '@tajer/api';
import { CurrencyAppService } from './currency-app.service';
import {  SalesChannels } from '@tajer/api';

@Injectable({
  providedIn: 'root'
})
export class ItemsAppService {

  //#region variables
  items: ItemStoreList[] | ItemStoreMarketplaceList[] | any = [];
  loading: boolean;
  currentParams: any;
  lastParams: any;
  loaded: boolean;
  skip = 0;
  category: CategoryStoreDto;
  brand: BrandStoreDto;
  searchTerm = '';
  selectedFilter: string[] = [];
  rangePrice: { lower: number; upper: number } = {
    lower: 0,
    upper: 10000
  };
  filters: any[];
  filterType = 0;
  countViewFilter = 3;
  firstLoading: boolean;
  maxResultCount = 10;
  totalItemsCount = 0;
  // default sorting
  sorting = 'date desc';
  maxPrice = 0;
  minPrice = 0;
  filtersLoading: boolean;
  public get source(): string {
    let _source = 'catalog';
    if (this.category) {
      _source = "category";
    } else if (this.brand) {
      _source = "brand";
    } else if (this.searchTerm) {
      _source = "search";
    } else if (this.filterType == 1) {
      _source = "featured products";
    } else if (this.filterType == 2) {
      _source = "latest products";
    }
    return _source;
  }
  storeIdFilter: string; //in marketplace Used
  //#endregion

  constructor( private itemService: ItemStoreService,
    private storeAppService: StoreAppService,
    private currencyAppService: CurrencyAppService,
) { }

resetParams() {
  this.items = null;
  this.loaded = false;
  this.skip = 0;
  this.category = null;
  this.brand = null;
  this.searchTerm = null;
  this.selectedFilter = [];
  // to avoid sending any prices in the request
  this.rangePrice = { lower: null, upper: null };
}

fetchItems(languagePosition: number, loadData = true, filtering = false) {
  if (filtering) {
    this.skip = 0;
  }
  if (!this.items || !loadData) {
    this.setFirsLoading(true);
  } else {
    this.loading = true;
  }

  this.itemService[this.isMarketplace?'getMarketplace':'getList']({
    maxResultCount: this.maxResultCount,
    skipCount: this.skip,
    warehouseId: !this.isMarketplace?this.storeAppService.currentOutlet?.warehouseId: null,
    branchId: !this.isMarketplace?this.storeAppService.currentOutlet?.branchId:null,
    sorting: this.sorting,
    search: this.searchTerm,
    categoryId: this.category ? this.category.id : null,
    brandId: this.brand ? this.brand.id : null,
    // filters: this.selectedFilter?.join(','),
    featuredOnly: this.filterType === 1 ? true : null,
    latestOnly: this.filterType === 2 ? true : null,
    fromPrice: (this.rangePrice && this.rangePrice?.lower) ? this.rangePrice?.lower : null,
    toPrice: (this.rangePrice && this.rangePrice?.upper) ? this.rangePrice?.upper : null,
    hideOutOfStock: this.storeAppService.storeOptions?.hideOutOfStockItems,
    salesChannel: this.getSalesChannel,
    ids: null,
    languagePosition: languagePosition,
    storesIds: this.isMarketplace && this.storeIdFilter ? [this.storeIdFilter] : null,
    currencyId: this.currencyAppService.requestCurrencyId,
  }).toPromise().then((response) => {
    this.totalItemsCount = response.totalCount;
    if (!this.items || !loadData) {
      // first load
      // if (this.utility.isPlatformHybrid) {
      //   // to let ionic open the page quality on mobile and then load data
      //   setTimeout(() => {
      //     this.items = response.items;
      //     this.setFirsLoading(false);
      //     this.loaded = true;
      //   }, 100);
      // } else {
        // if not mobile, we can load it immediately
        this.items = response.items;
        this.setFirsLoading(false);
        this.loaded = true;
      //}
    } else {
      this.items.push(...response.items);
      this.loading = false;
    }
    // if (this.segment.isSegmentEnabled) {
    //   this.segment.implementFilterProductEvent(this.items, {
    //     outlet: this.utility.getEntityValue(this.utility.storeAppService.currentOutlet),
    //     sort_type: this.sorting ? this.sorting?.split(" ")[0] : '',
    //     sort_value: this.sorting ? this.sorting?.split(" ")[1] : '',
    //     search: this.searchTerm,
    //     category: this.category ? this.utility.getEntityValue(this.category) : null,
    //     brand: this.brand ? this.utility.getEntityValue(this.brand) : null,
    //     filters: this.selectedFilter?.join(','),
    //     classcification: this.filterType === 1 ? this.utility.translate('FeaturedProducts') : this.filterType === 2 ? this.utility.translate('LatestProducts') : '',
    //     fromPrice: this.rangePrice && this.rangePrice?.lower,
    //     toPrice: this.rangePrice && this.rangePrice?.upper,
    //   })
    //   this.segment.implementViewProductListEvent(this.items)
    // }
  });

  this.updateLastParams();
  }

  changeFilters(languagePosition: number,filter: string) {
    if (this.selectedFilter.length === 0) {
      this.selectedFilter.push(filter);
    } else {
      const isExist = this.selectedFilter?.find(f => f === filter);
      if (isExist) {
        this.selectedFilter = this.selectedFilter.filter(f => f !== filter);
      } else {
        this.selectedFilter.push(filter);
      }
    }
    this.refresh(languagePosition);
  }

  private setFirsLoading(firstLoading) {
    this.firstLoading = firstLoading;
    // if (environment.log) {
    //   console.log('firstLoading')
    //   console.log(this.firstLoading)
    // }
  }

  updateLastParams() {
    this.lastParams = this.currentParams;
  }

  refresh(languagePosition: number) {
    this.skip = 0;
    this.fetchItems(languagePosition,false);
  }

  private get getSalesChannel() {
    return SalesChannels.WebStore;
  }

  get isMarketplace(){
    return this.storeAppService.storeConfig.isMarketplace;
  }

}
