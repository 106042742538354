import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapComponent } from '../google-map/google-map.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';



@NgModule({
  declarations: [
    GoogleMapComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA 
  ],
  exports:[
    GoogleMapComponent
  ]
})
export class GoogleMapModule { }
