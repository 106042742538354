import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ItemDetailStoreBySlug, ItemDetailStoreDto, ItemStoreBySlug, ItemStoreDto, StoreFullPageStoreDto } from '@tajer/api';
import * as currency from 'currency.js';
import { StoreAppService } from '@storefront/ng.core';
import { environment } from 'src/environments/environment';
import { TranslateAppService } from './translate-app.service';

@Injectable({
  providedIn: 'root'
})
export class HtmlDocumentAppService {
  sideMenuOpened: boolean;
  showItemQuickView: boolean;
  itemQuickViewLink : string | string[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private metaService: Meta,
    private translateAppService: TranslateAppService,
    private storeAppService: StoreAppService
  ) { }

  setLang(lang: string): void {
    this.document.documentElement.lang = lang;
  }

  setDir(dir: string): void {
    this.document.documentElement.dir = dir;
  }

  setIcon(url: string) {
    if (!url) {
      return;
    }
    var link = this.document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (!link) {
      link = this.document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'icon';
    }
    link.href = url;
    this.document.getElementsByTagName('head')[0].appendChild(link);

  }

  init(lang?: string, dir?: string, iconUrl?: string, font?: string) {
    if (lang) {
      this.setLang(lang);
    }
    if (dir) {
      this.setDir(dir);
    }
    if (iconUrl) {
      this.setIcon(iconUrl);
    }
    if (font) {
      this.loadGoogleFont(font);
    }
  }

  SetMetas(title: string, description: string, url: string, image: string) {
    this.titleService.setTitle(title);
    this.metaService.addTags([
      { name: 'title', content: title ? title: "" },
      { name: 'description', content: description ? description: "" },
      { property: 'og:title', content: title },
      { property: 'og:site_name', content: this.storeAppService.storeConfig.seo?.metaTitle ?? this.storeAppService.storeName },
      { property: 'og:description', content: description ? description: "" },
      { property: 'og:url', content: url },
      { name: 'twitter:title', content: title ? title: "" },
      { name: 'twitter:url', content: url },
      { name: 'twitter:description', content: description ? description: "" },
      { name: 'twitter:card', content: 'summary_large_image' },
      { property: 'generator', content: environment.provider.name },
    ]);
    this.setTypeTag('store');
    this.setLanguageTag();
    this.setImageTag(image);
  }

  setImageTag(imageUrl: string) {
    if (imageUrl) {
      this.metaService.updateTag({ property: 'og:image', content: imageUrl });
      this.metaService.updateTag({ property: 'twitter:image', content: imageUrl });
      this.metaService.updateTag({ property: 'og:image:width', content: "600" });
      this.metaService.updateTag({ property: 'og:image:height', content: "300" });
    }
  }

  setLanguageTag(){
    // languages meta tages
    this.metaService.updateTag({ name: 'language', content: this.translateAppService.getLanguageDetails(this.translateAppService.currentLang).metaName });
    this.metaService.updateTag({ property: 'og:locale', content: this.translateAppService.getLanguageDetails(this.translateAppService.currentLang).metaCode });
    const allLanguages = this.storeAppService.storeConfig?.languages;
    const otherLanguages = allLanguages.filter(x => x != this.translateAppService.currentLang); // except for the current one.
    otherLanguages.forEach(otherLanguage => {
      let otherLanguageMeta=this.translateAppService.getLanguageDetails(otherLanguage).metaCode;
      this.metaService.addTag({ property: 'og:locale:alternate', otherLanguageMeta });      
    });
  }

  setKeywordsTag(keywords: string) {
    if (keywords) {
      this.metaService.updateTag({ name: 'Keywords', content: keywords });
    }
  }

  setTypeTag(type: string) {
    if (type) {
      this.metaService.updateTag({ property: 'og:type', content: type });
    }
  }

  setBlogTag(blog: StoreFullPageStoreDto) {
    if (blog) {
      this.metaService.updateTag({ property: 'article:published_time', content: blog.publishDate });
      this.metaService.updateTag({ property: 'article:modified_time', content: blog.publishDate });
      this.metaService.updateTag({ name: 'author', content: 'admin' });
    }
  }

  removeKeywordsTag() {
    this.metaService.removeTag("name='Keywords'");
  }

  setProductTags(item: ItemStoreBySlug, itemDetail: ItemDetailStoreBySlug, categories: string, currency: string, brand: string, outOfStockText: string, inStockText: string) {
    // this.metaService.updateTag({ property: 'product:pretax_price:amount', content: itemDetail?.preTaxPrice?.toString() });
    // this.metaService.updateTag({ property: 'product:pretax_price:currency', content: currency });
    this.metaService.updateTag({ property: 'product:price:amount', content: itemDetail?.oldPrice ? itemDetail?.oldPrice?.toString() : itemDetail?.price?.toString() });
    this.metaService.updateTag({ property: 'product:price:currency', content: currency });
    this.metaService.updateTag({ property: 'product:sale_price:amount', content: itemDetail?.price?.toString() });
    this.metaService.updateTag({ property: 'product:sale_price:currency', content: currency });
    this.metaService.updateTag({ property: 'product:brand', content: brand });
    this.metaService.updateTag({ property: 'product:availability', content: itemDetail?.isOutOfStock == true ? outOfStockText : inStockText });
    this.metaService.updateTag({ property: 'product:retailer_item_id', content: itemDetail?.sku });
    this.metaService.updateTag({ property: 'product:category', content: categories });
    this.metaService.updateTag({ property: 'og:price:currency', content: currency });
    this.metaService.updateTag({ property: 'og:price:amount', content: itemDetail?.price?.toString() });
  }

  removeProductTags() {
    this.metaService.removeTag("property='product:pretax_price:amount'");
    this.metaService.removeTag("property='product:pretax_price:currency'");
    this.metaService.removeTag("property='product:price:amount'");
    this.metaService.removeTag("property='product:price:currency'");
    this.metaService.removeTag("property='product:sale_price:amount'");
    this.metaService.removeTag("property='product:sale_price:currency'");
    this.metaService.removeTag("property='product:brand'");
    this.metaService.removeTag("property='product:availability'");
    this.metaService.removeTag("property='product:retailer_item_id'");
    this.metaService.removeTag("property='product:category'");
  }

  loadGoogleFont(font: string) {
    if (!font) {
      return;
    }
    var link = this.document.createElement('link');
    link.rel = 'stylesheet';
    link.href = "https://fonts.googleapis.com/css2?family=" + font + "&display=swap";
    this.document.head.appendChild(link);
    var styles = `
    *{
      font-family: '${font}' , sans-serif;
    }`
    const node = this.document.createElement('style');
    node.innerHTML = styles;
    this.document.head.appendChild(node);
  }

  loadFontUsingTTF(font: string) {
    if (!font) {
      return;
    }
    var styles = `
    @font-face {
      font-family: ${font};
      src: url("./assets/fonts/${font.replace(/\s+/g, '-').toLowerCase()}.ttf");
    }

    *{
      font-family: '${font}' , sans-serif;
    }`
    const node = this.document.createElement('style');
    node.innerHTML = styles;
    this.document.head.appendChild(node);
  }

  loadStyle(url: string, callback: () => void = null, strategy = 'head') {
    if (!url) {
      return;
    }
    var link = this.document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    link.onload = callback;
    this.appendChild(strategy, link, this.document);
  }

  loadScript(url: string, callback: () => void = null, strategy = 'body') {
    if (!url) {
      return;
    }
    var script = this.document.createElement('script');
    script.type = "text/javascript";
    script.src = url;
    script.onload = callback;
    this.appendChild(strategy, script, this.document);
  }

  appendChild(strategy: string, element: HTMLScriptElement | HTMLStyleElement, document: Document) {
    switch (strategy) {
      case 'head':
        document.head.appendChild(element);
        break;
      default:
        document.body.appendChild(element);
        break;
    }
  }
}
