import { Theme } from 'src/app/shared/models/theme';

let theme = new Theme();
theme.name = 'merch';
theme.addStyleDependency(
  'merch-tailwind',
  '/assets/tailwind.min.css',
  '3.0.1',
  'tailwind'
);
theme.addStyleDependency('swiper-css', 'https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css');
theme.addFontDependency('tailwind-font', 'Inter');
export const merchTheme = theme;
