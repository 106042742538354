import { Injectable, Injector } from '@angular/core';
import { DeliveryType } from '@tajer/api';
import { CookieService, ICookieService } from "ngx-cookie";

@Injectable({
  providedIn: 'root'
})
export class CookieAppService {

  //#region variables
  cookieService: ICookieService;
  //#endregion

  constructor(
    injector: Injector
  ) {
      this.cookieService = injector.get(CookieService);
  }

  /**
   * set tenantId in cookies
   * @param tenantId string
   */
  public SetTenantId(tenantId: string) {
    if (!this.cookieService) {
      return;
    }
    const key = 'store_tenantId';
    if (tenantId) {
      this.cookieService.put(key, tenantId);
    } else {
      this.cookieService.remove(key);
    }
  }

  /**
   * get tenantId from cookies
   * @returns tenantId
   */
  public getTenantId(): string {
    if (!this.cookieService) {
      return null;
    }
    const key = 'store_tenantId';
    const tenantId = this.cookieService.get(key);
    return tenantId;
  }

  /**
   * set outletId in cookies
   * @param outletId string
   */
  public SetOutletId(outletId: string) {
    if (!this.cookieService) {
      return;
    }
    const key = 'store_outletId';
    if (outletId) {
      this.cookieService.put(key, outletId);
    } else {
      this.cookieService.remove(key);
    }
  }

  /**
   * get outletId from cookies
   * @returns outletId
   */
  public getOutletId(): string {
    if (!this.cookieService) {
      return null;
    }
    const key = 'store_outletId';
    const outletId = this.cookieService.get(key);
    return outletId;
  }

  /**
   * set deliveryType in cookies
   * @param deliveryType string
   */
  public SetCurrentDeliveryType(deliveryType: DeliveryType) {
    if (!this.cookieService) {
      return;
    }
    const key = 'store_delivery_type';
    if (deliveryType) {
      this.cookieService.put(key, JSON.stringify(deliveryType));
    } else {
      this.cookieService.remove(key);
    }
  }

  /**
   * get deliveryType from cookies
   * @returns deliveryType
   */
  public getCurrentDeliveryType(): DeliveryType {
    if (!this.cookieService) {
      return null;
    }
    const key = 'store_delivery_type';
    const delivery_type = this.cookieService.get(key);
    if (delivery_type !== undefined && delivery_type !== null) {
      return JSON.parse(delivery_type);
    }
    return null;
  }

  /**
   * used in marketplace to know the cart tenantId
   * set tenantId in cookies
   * @param tenantId string
   */
  public SetMarketTenantId(tenantId: string) {
    if (!this.cookieService) {
      return;
    }
    const key = 'market_tenantId';
    if (tenantId) {
      this.cookieService.put(key, tenantId);
    } else {
      this.cookieService.remove(key);
    }
  }

  /**
   * used in marketplace to know the cart tenantId
   * get tenantId from cookies
   * @returns tenantId
   */
  public getMarketTenantId(): string {
    if (!this.cookieService) {
      return null;
    }
    const key = 'market_tenantId';
    const tenantId = this.cookieService.get(key);
    return tenantId;
  }

  /**
   * used in multi Currencies store to know the current currency
   * set currencyId in cookies
   * @param currencyId string
   */
  public setCurrentCurrencyId(currencyId: string) {
    if (!this.cookieService) {
      return;
    }
    const key = 'current_currency_Id';
    if (currencyId) {
      this.cookieService.put(key, currencyId);
    } else {
      this.cookieService.remove(key);
    }
  }

  /**
   * used in multi Currencies store to know the current currency
   * get currencyId from cookies
   * @returns currencyId
   */
  public getCurrentCurrencyId(): string {
    if (!this.cookieService) {
      return null;
    }
    const key = 'current_currency_Id';
    const currencyId = this.cookieService.get(key);
    return currencyId;
  }

}
