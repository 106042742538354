import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerServiceAppService {

  constructor() { }


  getWhatsAppLink(number: string) {
    return 'https://wa.me/' + number;
}

  getTelegramLink(number: string) {
      return 'https://t.me/' + number;
  }

  getCallLink(number: string) {
      return 'tel:' + number;
  }

}
