import { Injectable } from '@angular/core';
import { TajerPlugin } from './tajer-plugin';

@Injectable({
    providedIn: 'root',
})
export class PluginRegistryService {
    private plugins: TajerPlugin[] = [];

    register(plugin: TajerPlugin): void {
        this.plugins.push(plugin);
    }

    loadPlugins(appData: Record<string, any>): void {
        for (const plugin of this.plugins) {
            plugin.init(appData);
        }
    }

    trigger(eventName: string, ...args: any[]): void {
        for (const plugin of this.plugins) {
            plugin.trigger(eventName, ...args);
        }
    }
}
