import { Injectable, Injector } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';
import { CustomerService, CustomerSummaryStoreDto } from '@tajer/api';
import { TranslateAppService } from './translate-app.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerAppService {

  private translateAppService: TranslateAppService;
  customer: CustomerSummaryStoreDto;
  customer$: Subject<CustomerSummaryStoreDto>= new Subject<CustomerSummaryStoreDto>();

  constructor(
    injector: Injector,
    private customerService: CustomerService,
    private oAuthService: OAuthService,

  ) {
    this.translateAppService = injector.get(TranslateAppService);
  }

  public get hasLoggedIn(): boolean {
    const hasValidAccessToken = this.oAuthService.hasValidAccessToken() //&& !!this.customer;
    return hasValidAccessToken;
  }

  onChangeLang(lang: string) {
    this.translateAppService.currentLang = lang;
    localStorage.setItem('isLangAlreadyChanged', 'true');
    if (this.hasLoggedIn) {
      this.customerService.changeLanguage(lang).toPromise().then(() => {});
    }
  }

  getCustomerInfo(){
    // call get GetMyCustomerInfo api to popualte user info
    if(this.hasLoggedIn){
      this.customerService.getMyCustomerSummary().toPromise().then((customer) => {
        this.customer = customer;
        this.customer$.next(this.customer);
      }).catch(err =>{});
    }
  }
}
