import { Injectable, Injector } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(private injector: Injector) { }

  getForwardedHost() {
    const request = this.injector.get(REQUEST);
    const forwardedHost = request.headers['x-forwarded-host']?.toString();
    return forwardedHost;
  }

  getServerHost() {
    const request = this.injector.get(REQUEST);
    const forwardedHost = request.get('host'); // or: request.headers.host
    return forwardedHost;
  }

  getRequestUrl() {
    const request = this.injector.get(REQUEST);
    const url = request.originalUrl;
    return url;
  }

  getRequestHostUrl(){
    const request = this.injector.get(REQUEST);
    const url = request.protocol + '://' + request.get('Host');
    return url;
  }
}
