import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { DeliveryMethodsRoutingModule } from './delivery-methods-routing.module';
import { DeliveryMethodsComponent } from './delivery-methods.component';
import { ProgressBarModule } from 'src/app/components/progress-bar/progress-bar.module';
import { DeliveryAddressCardModule } from 'src/app/components/delivery-address-card/delivery-address-card.module';
import { DeliveryMethodCardModule } from 'src/app/components/delivery-method-card/delivery-method-card.module';
import { DefaultDeliveryMethodsModule } from '@storefront/ng.themes';

@NgModule({
  declarations: [DeliveryMethodsComponent],
  imports: [
    CommonModule,
    DeliveryMethodsRoutingModule,
    SharedModule,
    ProgressBarModule,
    DeliveryAddressCardModule,
    DeliveryMethodCardModule,
    DefaultDeliveryMethodsModule,
  ],
  exports:[
    DeliveryMethodsComponent,
  ],
  providers: [DatePipe],
})
export class DeliveryMethodsModule {}
