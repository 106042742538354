import { TajerPlugin } from "./core/tajer-plugin";

export class InternalPlugin1 extends TajerPlugin {
    override init(appData: Record<string, any>): void {
        super.init(appData);

        // Track initial page view on plugin initialization
        this.trackPageView(window.location.pathname);

        // Register event handlers
        this.on('pageNavigated', this.handlePageNavigated);
        this.on('buttonClicked', this.handleButtonClicked);
    }

    private trackPageView(path: string): void {
        // analytics.track('page_view', { path });
        console.log('Page view tracked:', path);
    }

    private trackButtonClick(buttonId: string): void {
        // analytics.track('button_click', { buttonId });
        console.log('Button click tracked:', buttonId);
    }

    private handlePageNavigated = (path: string) => {
        this.trackPageView(path);
    };

    private handleButtonClicked = (buttonId: string) => {
        this.trackButtonClick(buttonId);
    };

}
