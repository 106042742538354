
import { StoreAppService } from "@storefront/ng.core";
import { TajerPlugin } from "./core/tajer-plugin";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Meta } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root',
})
export class GoogleConsolePlugin extends TajerPlugin {

    constructor(
        private metaService: Meta,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.addGoogleConsoleMeta(this.storeAppService.storeConfig.externalServices?.googleConsoleId);

        // Register event handlers
    }

    addGoogleConsoleMeta(googleConsoleId: string) {
        this.metaService.updateTag({ name: 'google-site-verification', content: googleConsoleId });
    }
}
