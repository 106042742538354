import { Component, Injector, OnInit } from '@angular/core';
import { ScreenBreakpointsService } from '@storefront/ng.core';
import { CustomerServiceAppService } from 'src/app/shared/app-services/customer-service-app.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent extends BaseComponent implements OnInit {

  //#region variants
  //#endregion

  constructor(
    injector: Injector,
    public customerServiceAppService: CustomerServiceAppService,
    public screenBreakpointsService: ScreenBreakpointsService,
  ) {
    super(injector);
  }

  closeSideMenu() {
    if (this.screenBreakpointsService.isSizeLgOrUp && (this.currentTheme == "ionic" || this.currentTheme == 'customizedIonic')) {
      this.htmlDocApp.sideMenuOpened = false;
    } else {
      if (this.currentTheme == "merch") {
        this.htmlDocApp.sideMenuOpened = false;
      }
    }
  }

  public get menuBackgroundColor(): string {
    return (
      this.storeDesignOptions?.menuBackgroundColorOnHeader ?? this.color
    );
  }

  public get menuFontColor(): string {
    return (
      this.storeDesignOptions?.menuFontColorOnHeader ?? this.frontColor
    );
  }

  public get color(): string {

    return this.storeDesignOptions?.headerColor ?? this.storeDesignOptions?.color;
  }

  public get frontColor(): string {
    return this.storeDesignOptions?.headerFrontColor ?? this.storeDesignOptions?.frontColor;
  }

  ngOnInit() {
  }

}
