import { Component, Injector } from '@angular/core';
import { LoadingDesignOptions } from '@storefront/ng.themes/lib/themes/default/default-loading/models';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent extends BaseComponent {
  //#region variables
  desginOptions: LoadingDesignOptions;
  //#end variables

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.setIonicThemeOptions();
  }
  setIonicThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions?.color,
    };
  }
}
