import { Injectable } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';
import { DeliveryType, OutletStoreDto, OutletTimeTableStoreDto } from '@tajer/api';
import { CookieAppService } from './cookie-app.service';

@Injectable({
    providedIn: 'root'
})
export class DeliveryAppService {
    today = new Date();
    day = this.today.getDay();
    hours = this.today.getHours();
    minutes = this.today.getMinutes();

    constructor(private storeAppService: StoreAppService,
        private cookieAppService: CookieAppService) { }

    //delivery Type
    public set currentDeliveryType(deliveryType: DeliveryType) {
        this.cookieAppService.SetCurrentDeliveryType(deliveryType);
    }

    public get currentDeliveryType(): DeliveryType {
        if (!this.cookieAppService.getCurrentDeliveryType()) {
            this.populateDeliveryType();
        }
        const delivery_type = this.cookieAppService.getCurrentDeliveryType();
        if (delivery_type !== null) {
            return delivery_type;
        }
        return null;
    }

    private populateDeliveryType() {
        var deliveryType: DeliveryType;
        if (this.storeAppService.currentOutlet.availableForDelivery) {
            deliveryType = DeliveryType.Delivery;
        } else if (this.storeAppService.currentOutlet.availableForSelfPickup) {
            deliveryType = DeliveryType.SelfPickup;
        } else if (this.storeAppService.currentOutlet.availableForDineIn) {
            deliveryType = DeliveryType.DineIn;
        }
        this.currentDeliveryType = deliveryType;
    }

    public get isCurrentOutletOpenForDelievryType(): boolean {
        let outletState: boolean = false;
        if (this.currentDeliveryType == DeliveryType.Delivery && this.storeAppService.currentOutlet?.availableForDelivery && (!this.storeAppService.currentOutlet?.specificTimeTableForDelivery || this.checkIfCurrentOutletOpen())) {
            outletState = true;
        } else if (this.currentDeliveryType == DeliveryType.SelfPickup && this.storeAppService.currentOutlet?.availableForSelfPickup && (!this.storeAppService.currentOutlet?.specificTimeTableForSelfPickup || this.checkIfCurrentOutletOpen())) {
            outletState = true;
        } else if (this.currentDeliveryType == DeliveryType.DineIn && this.storeAppService.currentOutlet?.availableForDineIn && (!this.storeAppService.currentOutlet?.specificTimeTableForDineIn || this.checkIfCurrentOutletOpen())) {
            outletState = true;
        }
        return outletState;
    }

    public checkIfCurrentOutletOpen(): boolean {
        return this.checkIfOutletOpen(this.storeAppService.currentOutlet, this.currentDeliveryType);
    }

    public checkIfOutletOpen(outlet: OutletStoreDto, deliveryType: DeliveryType, timeTablesArg?: OutletTimeTableStoreDto[]): boolean {
        let isOpen: boolean;
        let timeTables: OutletTimeTableStoreDto[] = timeTablesArg ? timeTablesArg : outlet?.timeTables.filter(
            row => row.deliveryType == deliveryType && row.dayOfWeek == this.day);
        if (timeTables.length == 0) {
            isOpen = false;
        } else {
            timeTables.forEach(row => {
                if (row.startHour < this.hours &&
                    this.hours < row.endHour) {
                    isOpen = true;
                } else if (this.hours == row.endHour) {
                    if (this.minutes <= row.endMinute) {
                        isOpen = true;
                    } else {
                        isOpen = false;
                    }
                } else if (this.hours == row.startHour) {
                    if (this.minutes >= row.startMinute) {
                        isOpen = true;
                    } else {
                        isOpen = false;
                    }
                } else {
                    isOpen = false;
                }
            });
        }
        return isOpen;
    }

}
