import { StoreAppService } from "@storefront/ng.core";
import { CartAppService } from "../shared/app-services/cart-app.service";
import { EntityUtilityService } from "../shared/app-services/entity-utility.service";
import { OrderAppService } from "../shared/app-services/order-app.service";
import { TajerPlugin } from "./core/tajer-plugin";
import { BrandStoreDto, CategoryStoreDto, ItemDetailStoreBySlug, ItemStoreList, StoreFullPageStoreDto } from "@tajer/api";
import { Injectable, Inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { DOCUMENT } from "@angular/common";
import { TranslateAppService } from "../shared/app-services/translate-app.service";

declare let fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelPlugin extends TajerPlugin {

  constructor(private entityUtilityService: EntityUtilityService,
    private orderAppService: OrderAppService,
    @Inject(DOCUMENT) private document: Document,
    private translateAppService: TranslateAppService,
    private storeAppService: StoreAppService) {
    super();
  }

  override init(appData: Record<string, any>): void {
    super.init(appData);

    //add FaceboookPixel
    this.addFaceboookPixelScript(this.storeAppService.storeConfig.externalServices.facebookPixelId);

    // Register event handlers
    this.on('implementPageViewEvent', this.implementPageViewEvent.bind(this));
    this.on('implementAddToCartEvent', this.implementAddToCartEvent.bind(this));
    this.on('implementInitiateCheckoutEvent', this.implementInitiateCheckoutEvent.bind(this));
    this.on('implementPurchaseEvent', this.implementPurchaseEvent.bind(this));
    this.on('implementViewProductEvent', this.implementViewProductEvent.bind(this));
    this.on('implementViewBlogEvent', this.implementViewBlogEvent.bind(this));
    this.on('implementCustomizeProductEvent', this.implementCustomizeProductEvent.bind(this));
    this.on('implementAddToWishlistEvent', this.implementAddToWishlistEvent.bind(this));
    this.on('implementViewBrandEvent', this.implementViewBrandEvent.bind(this));
    this.on('implementViewCategoryEvent', this.implementViewCategoryEvent.bind(this));
    this.on('implementSearchEvent', this.implementSearchEvent.bind(this));
    this.on('implementSignupEvent', this.implementSignupEvent.bind(this));
    this.on('implementContactEvent', this.implementContactEvent.bind(this));
  }

  implementPageViewEvent() {
    try {
      fbq('track', 'PageView');
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementAddToWishlistEvent(item: ItemStoreList | ItemDetailStoreBySlug, itemName: string, quantity: number = 1) {
    try {
      fbq('track', 'AddToWishlist', {
        content_name: itemName,
        content_category: 'product',
        content_ids: item?.sku,
        contents: [{ 'id': item.sku, 'quantity': quantity }],
        value: 0,
        currency: this.storeAppService.storeConfig?.currency?.isoCode,
      });
    } catch (error) {
      if (environment.log) { console.warn(error); }
    }
  }

  implementCustomizeProductEvent() {
    try {
      fbq('track', 'CustomizeProduct');
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementInitiateCheckoutEvent() {
    try {
      fbq('track', 'InitiateCheckout', {
        content_ids: this.orderAppService?.cartLinesSkus,
        content_category: '',
        contents: this.orderAppService?.facebookPixelContents,
        num_items: this.orderAppService?.shoppingLinesAvailableCount,
        value: this.orderAppService?.shippingCartTotal,
        currency: this.storeAppService.storeConfig?.currency?.isoCode
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementContactEvent() {
    try {
      fbq('track', 'Contact');
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementPurchaseEvent() {
    try {
      fbq('track', 'Purchase', {
        content_ids: this.orderAppService?.cartLinesSkus,
        contents: this.orderAppService?.facebookPixelContents,
        num_items: this.orderAppService?.shoppingLinesAvailableCount,
        value: this.orderAppService.shippingCartTotal,
        currency: this.storeAppService.storeConfig?.currency?.isoCode,
        content_name: this.translateAppService.translate('Checkout'),
        content_type: 'product_group'
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementViewCategoryEvent(category: CategoryStoreDto) {
    try {
      fbq('track', 'ViewContent ', {
        // Required for Dynamic Adss
        content_ids: this.entityUtilityService.getEntityValue(category, 'slug'), // entity id, item sku, category slug, ...
        content_category: 'category',
        content_name: this.entityUtilityService.getEntityValue(category),// entity name, item name, category name ..
        // Required for Dynamic Ads
        content_type: 'product_group', // if item, set product. othersie, product_group
        // Required for Dynamic Ads
        contents: '', // null
        value: 0,
        currency: this.storeAppService.storeConfig?.currency?.isoCode
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementViewBrandEvent(brand: BrandStoreDto) {
    try {
      fbq('track', 'ViewContent ', {
        // Required for Dynamic Adss
        content_ids: this.entityUtilityService.getEntityValue(brand, 'slug'), // entity id, item sku, category slug, ...
        content_category: 'brand',
        content_name: this.entityUtilityService.getEntityValue(brand),// entity name, item name, category name ..
        // Required for Dynamic Ads
        content_type: 'product_group', // if item, set product. othersie, product_group
        // Required for Dynamic Ads
        contents: '', // null
        value: 0,
        currency: this.storeAppService.storeConfig?.currency?.isoCode
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementViewProductEvent(item: ItemStoreList | ItemDetailStoreBySlug, itemName: string) {
    try {
      fbq('track', 'ViewContent ', {
        // Required for Dynamic Adss
        content_ids: item?.sku, // entity id, item sku, category slug, ...
        content_category: 'product',
        content_name: itemName,// entity name, item name, category name ..
        // Required for Dynamic Ads
        content_type: 'product', // if item, set product. othersie, product_group
        // Required for Dynamic Ads
        contents: '', // null
        value: 0,
        currency: this.storeAppService.storeConfig?.currency?.isoCode
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementAddToCartEvent(item: ItemStoreList | ItemDetailStoreBySlug, itemName: string, quantity: number = 1) {
    try {
      fbq('track', 'AddToCart', {
        content_name: itemName,
        content_ids: item?.sku,
        content_type: 'product',
        contents: [{ 'id': item.sku, 'quantity': quantity }],
        value: item?.price,
        currency: this.storeAppService.storeConfig?.currency?.isoCode,
      });
    } catch (error) {
      if (environment.log) { console.warn(error); }
    }
  }

  implementSearchEvent(searchTerm: string) {
    try {
      fbq('track', 'Search', {
        content_category: '',
        content_ids: '',
        contents: '',
        currency: this.storeAppService.storeConfig?.currency?.isoCode,
        search_string: searchTerm,
        value: ''
      });
    } catch (error) {
      if (environment.log) { console.warn(error) }
    }
  }

  implementViewBlogEvent(storePage: StoreFullPageStoreDto) {
    try {
      fbq('track', 'ViewContent ', {
        // Required for Dynamic Adss
        content_ids: this.entityUtilityService.getEntityValue(storePage, 'slug'), // entity id, item sku, category slug, ...
        content_category: 'blog',
        content_name: this.entityUtilityService.getEntityValue(storePage, 'name'),// entity name, item name, category name ..
        // Required for Dynamic Ads
        content_type: 'product_group', // if item, set product. othersie, product_group
        // Required for Dynamic Ads
        contents: '', // null
        value: 0,
        currency: this.storeAppService.storeConfig?.currency?.isoCode
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  implementSignupEvent() {
    try {
      fbq('track', 'CompleteRegistration', {
        content_name: this.translateAppService.translate('Register'),
        status: true,
        value: 0,
        currency: this.storeAppService.storeConfig?.currency?.isoCode
      });
    } catch (error) {
      //if (environment.log) { console.warn(error) }
    }
  }

  addFaceboookPixelScript(pixelId: string) {
    const facebookPixelScript = `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixelId}');`;
    var script = this.document.createElement('script');
    script.innerHTML = facebookPixelScript;
    this.document.head.appendChild(script);
    var noscript = this.document.createElement('noscript');
    noscript.innerHTML = `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>`;
    this.document.head.appendChild(noscript);
  }
}
