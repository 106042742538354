import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateAppService } from '../app-services/translate-app.service';
import { StoreAppService } from '@storefront/ng.core';
import { HttpWaitService } from '@tajer/api';
import { CookieAppService } from '../app-services/cookie-app.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
    providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private httpWaitService: HttpWaitService,
        private storeAppService: StoreAppService,
        private translateAppService: TranslateAppService,
        private cookieAppService: CookieAppService,
        private oAuthService: OAuthService
    ) {
    }

    intercept(
        request: HttpRequest<any>, next: HttpHandler
    ) {
        this.httpWaitService.addRequest(request);
        return next
            .handle(
                request.clone({
                    setHeaders: this.getAdditionalHeaders(request.headers),
                }),
            )
            .pipe(finalize(() => this.httpWaitService.deleteRequest(request)));
    }

    getAdditionalHeaders(existingHeaders?: HttpHeaders) {
        const headers = {} as any;

        let bearerToken = this.oAuthService.getAccessToken();
        if (bearerToken && environment.log) {
            console.log('from storage')
        }
        if (environment.log) {
            console.log('bearerToken: ' + bearerToken)
        }
        if (!existingHeaders?.has('Authorization') && bearerToken) {
            headers['Authorization'] = `Bearer ${bearerToken}`;
        }

        const lang = this.translateAppService.currentLang;
        if (!existingHeaders?.has('Accept-Language') && lang) {
            headers['Accept-Language'] = lang;
        }

        // get it from store config if store configs is loaded
        let tenantId = this.storeAppService.storeConfig?.tenantId;
        // if SSR enabled, in the first request storeConfig is still not populated, so need to get it from cookies
        if (!tenantId) {
            tenantId = this.cookieAppService.getTenantId();
        }
        
        if (environment.log) {
            console.log('tenantId: ' + tenantId)
        }
        if (!existingHeaders?.has('tajertenant') && tenantId) {
            headers['tajertenant'] = tenantId;
        }

        return headers;
    }
}
