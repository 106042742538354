import { Component, Injector } from '@angular/core';
import { ProgressBarDesignOptions } from '@storefront/ng.themes/lib/themes/default/default-progress-bar/models';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent extends BaseComponent {
  //#region variables
  desginOptions: ProgressBarDesignOptions;
  //#end variables

  constructor(injector: Injector) {
    super(injector);
  }
  ngOnInit() {
    this.setIonicThemeOptions();
  }
  setIonicThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };
  }

  isActive(path: string): boolean {
    return this.router.url.includes(path);
  }
}
