import { Component, Injector, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from './shared/components/base.component';
import { InternalPlugin1 } from './plugins';
import { TajerPlugin } from './plugins/core';
import { environment } from 'src/environments/environment';
import { DeliveryMethodsComponent, DeliveryMethodsComponentContext } from './pages/delivery-methods/delivery-methods.component';
import { DialogRef, overlayConfigFactory } from 'ngx-modialog-7';
import { GoogleAnalyticsPlugin } from './plugins/google-analytics-plugin';
import { FacebookPixelPlugin } from './plugins/faceboook-pixel-plugin';
import { SnapChatPlugin } from './plugins/snap-chat-plugin';
import { TictookPlugin } from './plugins/tictook-plugin';
import { GoogleConsolePlugin } from './plugins/google-console-plugin';
import { GoogleAdWordsPlugin } from './plugins/google-ad-words-plugin';
import { CrazyEggPlugin } from './plugins/crazy-egg-plugin';
import { HotJarPlugin } from './plugins/hot-jar-plugin';
import { GoogleTagManagerPlugin } from './plugins/google-tag-manager-plugin';
import { TidioPlugin } from './plugins/tidio-plugin';
import { FacebookDVKPlugin } from './plugins/facebook-dVK-plugin';
import { NavigationEnd, Router } from '@angular/router';
import { ScreenBreakpointsService } from '@storefront/ng.core';
import { StorefrontAppService } from './shared/app-services/storefront-app.service';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';

declare global {
  interface Window { tidioChatApi: any; }
  interface Document { tidioChatApi: any, tidioChatLang: any; }
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnDestroy {

  //#region variables
  stylesLoaded$: Observable<any>;
  scriptsLoaded$: Observable<any>;
  scriptsAndStylesLoaded$: Observable<any>;

  private subscription: Subscription;
  cuurenciesMenuOpen=false;
  @ViewChild('currenciesLoadingModalRef', { static: false })
  currenciesLoadingModalRef: TemplateRef<any>;
  dialogRef: DialogRef<any>;
  //#endregion

  constructor(
    injector: Injector,
    private googleAnalyticsPlugin: GoogleAnalyticsPlugin,
    private facebookPixelPlugin: FacebookPixelPlugin,
    private snapChatPlugin: SnapChatPlugin,
    private tictookPlugin: TictookPlugin,
    private googleAdWordsPlugin: GoogleAdWordsPlugin,
    private googleConsolePlugin: GoogleConsolePlugin,
    private crazyEggPlugin: CrazyEggPlugin,
    private hotJarPlugin: HotJarPlugin,
    private tidioPlugin: TidioPlugin,
    private googleTagManagerPlugin: GoogleTagManagerPlugin,
    private facebookDVKPlugin: FacebookDVKPlugin,
    public screenBreakpointsService: ScreenBreakpointsService,
    public storefrontAppService: StorefrontAppService,
  ) {
    super(injector);

    this.populateStoreConfig();

    if (this.isBrowser) {
      this.customerAppService.getCustomerInfo();
    }

    if (this.isBrowser) {
      this.trsnalteApp.routerEvents?.subscribe(() => {
        this.htmlDocApp.init(this.trsnalteApp.currentLang, this.trsnalteApp.dir,this.storeAppService.storeConfig?.icon);
        this.trsnalteApp.getAllTranslations();
      });
      this.storefrontAppService.loadThemeDependenciesInBrowser();
      this.RegisterAndLoadPlugins();
      this.implementPageViewAnalyticsTools(this.router);
      this.htmlDocApp.init(this.trsnalteApp.currentLang, this.trsnalteApp.dir, this.storeAppService.storeConfig?.icon);
      //first time get translations
      this.trsnalteApp.getAllTranslations();
      //to be activated after solving translations in server
      //this.trsnalteApp.getBrowserAllTranslations();
    }

    if (this.isServer) {
      this.trsnalteApp.performLanguageInitialization();
      this.htmlDocApp.init(this.trsnalteApp.currentLang, this.trsnalteApp.dir, this.storeAppService.storeConfig?.icon);
      this.trsnalteApp.getServerAllTranslations();
      //this.loadThemeDependenciesInServer();
    }
  }
  
  ngOnInit(): void {
    this.entityUtilityService.populateLanguagesDisplayKeys();
    this.subscribeOpenAddAddressModal();
    if (this.isBrowser && window && window.tidioChatApi) {
      configureTidio(this.trsnalteApp.currentLang);
    }
  }

  private RegisterAndLoadPlugins() {
    // Define the app data.
    const appData = {
      key: "value",
      // Other data as needed.
    };

    // Register and load plugins.
    this.loadAndRegisterPlugins();
    this.pluginRegistry.loadPlugins(appData);

    // Load and register multiple external plugins.
    // this.loadAndRegisterExternalPlugins(appData);
  }

  private loadAndRegisterPlugins() {
    this.pluginRegistry.register(new InternalPlugin1());
    if (this.externalServices?.googleAnalyticId) {
      this.pluginRegistry.register(this.googleAnalyticsPlugin);
    }
    if (this.externalServices?.facebookPixelId) {
      this.pluginRegistry.register(this.facebookPixelPlugin);
    }
    if (this.externalServices?.snapChatId) {
      this.pluginRegistry.register(this.snapChatPlugin);
    }
    if (this.externalServices?.tiktookId) {
      this.pluginRegistry.register(this.tictookPlugin);
    }
    if (this.externalServices?.googleConsoleId) {
      this.pluginRegistry.register(this.googleConsolePlugin);
    }
    if (this.externalServices?.crazyEggId) {
      this.pluginRegistry.register(this.crazyEggPlugin);
    }
    if (this.externalServices?.hotJarId) {
      this.pluginRegistry.register(this.hotJarPlugin);
    }
    // if (this.externalServices?.tawkSiteId && externalServices?.tawkRegionId) {
    //   seoService.addTawkScript(externalServices?.tawkSiteId, externalServices?.tawkRegionId);
    // }
    if (this.externalServices?.tidioId) {
      this.pluginRegistry.register(this.tidioPlugin);
    }
    if (this.externalServices?.googleTagManagerId) {
      this.pluginRegistry.register(this.googleTagManagerPlugin);
    }
    if (this.externalServices?.googleAdWordsId) {
      this.pluginRegistry.register(this.googleAdWordsPlugin);
    }
    if (this.externalServices?.facebookDVK) {
      this.pluginRegistry.register(this.facebookDVKPlugin);
    }
  }

  private loadAndRegisterExternalPlugins(appData: any) {
    // Load plugin core file
    this.htmlDocApp.loadScript('assets/plugins/core/plugin.js', () => {
      const plugins = [ // Load from server
        { name: 'plugin1', url: 'assets/plugins/external-plugin-1.js' },
      ]
      plugins.forEach(plugin => {
        this.loadAndRegisterExternalPlugin(plugin.url, plugin.name, appData);
      });
    });
  }

  private loadAndRegisterExternalPlugin(src: string, pluginId: string, appData: Record<string, any>): void {
    this.htmlDocApp.loadScript(src, () => {
      if (typeof (window as any).pluginRegistry[pluginId] === 'function') {
        const externalPlugin: TajerPlugin = (window as any).pluginRegistry[pluginId]();
        this.pluginRegistry.register(externalPlugin);
        this.pluginRegistry.loadPlugins(appData);
      } else {
        console.error(`The external plugin at ${src} does not expose a unique identifier: ${pluginId}`);
      }
    });
  }

  private populateStoreConfig() {
    this.storefrontAppService.populateStoreDetailsConfig();
    this.storefrontAppService.populateThemeOptions();
    this.storefrontAppService.populateStoreConfig();
  }

  show = false
  openDrower() {
    this.show = !this.show
  }
  ngAfterViewInit() {
    if (this.segmentService.isSegmentEnabled) {
      if (environment.log) {
        console.log('analytics page');
        console.log(this.segmentService.analyticsUser);
      }
      if (this.currentCustomer?.userId) {
        this.analyticsIdentify();
      } else if (this.segmentService.analyticsUser?.userId) {
        if (environment.log) {
          console.log('analytics reset');
        }
        this.segmentService.reset();
      }
    }
  }

  analyticsIdentify() {
    this.segmentService.analyticsIdentify(
      this.storeAppService.storeConfig?.tenantId,
      this.storeAppService.storeConfig?.name,
      this.currentCustomer,
      this.getRequestHostUrl(),
      this.storeAppService.storeConfig?.country?.name,
    );
  }

  subscribeOpenAddAddressModal() {
    this.subscription = this.orderAppService.openAddAddressModal$.subscribe(
      data => {
        this.orderAppService.checkoutLoading = false;
        this.openAddAddressModal(true, true);
      }
    )
  }

  async openAddAddressModal(editAddress: boolean, editDeliveryMethod: boolean) {
    const componentContext = <DeliveryMethodsComponentContext>{
      isDeliveryMethodsModal: true,
      editAddress: editAddress,
      editDeliveryMethod: editDeliveryMethod,
      size: !editAddress ? 'sm' : 'lg',
    };

    const dialogRef = this.modal.open(
      DeliveryMethodsComponent,
      overlayConfigFactory(componentContext)
    );

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  sideMenuCartOpen() {
    if (this.cartAppService.sideMenuCartOpened) {
      if (this.isSegmentEnabled) { this.segmentService.implementViewCartEvent(this.cartAppService?.shoppingCart?.cartLines) }
      if (!this.orderAppService.isShoppingCartEmpty && this.configFeatures?.loyaltyProgram) {
        this.cartAppService.loadingPoints = true;
        this.cartAppService.getEstimateRewordPoints(true)
      }
      if (this.isSegmentEnabled) { this.segmentService.implementEnterPageEvent("Cart", "Side cart") }
    }
  }

  closeCartSidebar(){
    if(this.cartAppService.sideMenuCartOpened && this.screenBreakpointsService.isSizeMdOrUp){
      this.cartAppService.sideMenuCartOpened=false;
    }
  }

  closeMenuSidebar(){
    if(this.htmlDocApp.sideMenuOpened){
      this.htmlDocApp.sideMenuOpened=false;
    }
  }

  private implementPageViewAnalyticsTools(router: Router) {
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        try {
          this.pluginRegistry.trigger("implementPageViewEvent",y)
        } catch (error) {
        }
      }
    })
  }

  closeSidebar(){
    if(this.htmlDocApp.sideMenuOpened && this.screenBreakpointsService.isSizeMdOrUp){
      this.htmlDocApp.sideMenuOpened=false;
    }
  }

  onChangeCurrency(currencyId: string) {
    this.currnetCurrnecyId = currencyId;
    window.location.reload();
    this.openCurrenciesLoadingModal();
    //this.onRefresh();
  }

  openCurrenciesLoadingModal() {
    const componentContext = <BSModalContext>{
      inElement: true,
      dialogClass: 'modal-x-sm',
      isBlocking: true,
    };

    this.dialogRef = this.modal.open(
      this.currenciesLoadingModalRef,
      overlayConfigFactory(componentContext)
    );

    this.dialogRef.onDestroy.subscribe(() => {
      if (this.isBrowser) {
        document.body.classList.remove('modal-open');
      }
    });

    this.dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  get currenciesMenuColor(){
    return this.storeDesignOptions?.currenciesMenuColor??this.storeDesignOptions?.color;
  }

  get currenciesMenuFrontColor(){
    return this.storeDesignOptions?.currenciesMenuFrontColor??this.storeDesignOptions?.frontColor;
  }

  closeItemQuickView(){
    this.htmlDocApp.showItemQuickView = false;
  }

}

function configureTidio(lang) {
  // window.tidioChatApi = window.tidioChatApi || {};
  // document.tidioChatApi = document.tidioChatApi || {};
  // document.tidioChatLang = document.tidioChatLang || {};

  function onTidioChatApiReady() {
    window.tidioChatApi.open();
  }
  if (window.tidioChatApi) {
    window.tidioChatApi.on('ready', onTidioChatApiReady);
    document.tidioChatLang = lang;
  } else {
    document.addEventListener('tidioChat-ready', onTidioChatApiReady);
  }
};