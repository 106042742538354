<!-- <div *ngIf="isServer || (scriptsAndStylesLoaded$ | async)"></div> -->

<router-outlet *ngIf="isServer || (currentTheme != 'ionic' && currentTheme != 'customizedIonic' && storeActive) || (!storefrontAppService.loading && storeActive && (currentTheme == 'ionic' || currentTheme == 'customizedIonic'))">
    <div *ngIf="!isServer && (currentTheme == 'ionic' || currentTheme == 'customizedIonic')" class="ds-ripple-container" [style.color]="storeDesignOptions?.frontColor"
        [style.background-color]="storeDesignOptions?.color">
        <div class="storeLoading">
            <div>
                {{translations?.StoreLoadingHint}}
            </div>
            <div class="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</router-outlet>

<div *ngIf="!isServer && storefrontAppService.loading && (currentTheme == 'ionic' || currentTheme == 'customizedIonic')" class="ds-ripple-container" [style.color]="storeDesignOptions?.frontColor"
    [style.background-color]="storeDesignOptions?.color">
    <div class="storeLoading">
        <div>
            {{translations?.Loading}}
        </div>
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
</div>

<div *ngIf="!isServer && storeUnderMaintenance && !storefrontAppService.loading" class="text-align-center">
    <div class="not-found-icon-container">
        <img src="assets/images/under-maintenance.gif">
    </div>
    <div>
        <h3 translate>StoreUnderMaintenance</h3>
    </div>
</div>
<!-- <router-outlet *ngIf="isServer || (scriptsAndStylesLoaded$ | async)"></router-outlet>
<div *ngIf="!isServer && !(scriptsAndStylesLoaded$ | async)">
    Loading...
</div> -->

<ngx-nav-drawer *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'" class="side-menu-cart" (clickOutside)="closeCartSidebar()" [lang]="'ar'"
    (openChange)="sideMenuCartOpen()" [hidden]="!cartAppService.sideMenuCartOpened" [fixed]="true"
    [(open)]="cartAppService.sideMenuCartOpened">
    <app-side-menu-cart>
    </app-side-menu-cart>
</ngx-nav-drawer>

<ngx-nav-drawer class="control-panel-side-bar" [lang]="'ar'" [hidden]="!drawerAppService.profileSideMenuOpened"
    [fixed]="true" [(open)]="drawerAppService.profileSideMenuOpened">
    <app-control-panel-side-bar></app-control-panel-side-bar>
</ngx-nav-drawer>

<div *ngIf="cartAppService.sideMenuCartOpened && (currentTheme == 'merch' || currentTheme == 'shopper' || currentTheme == 'restaurants')"
    (clickOutside)="closeCartSidebar()" style="z-index: 111111;"
    class="fixed top-0 flex h-screen w-full max-w-[400px] flex-col justify-between overflow-y-auto bg-white shadow-three"
    [class.right-0]="isRtl" [class.left-0]="!isRtl">
    <app-side-menu-cart>
    </app-side-menu-cart>
</div>
<!-- ====== Drawer Section End -->

<ngx-nav-drawer *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'" class="side-menu-cart" (clickOutside)="closeSidebar()" [lang]="'ar'"
    [hidden]="!htmlDocApp.sideMenuOpened" [fixed]="true" [(open)]="htmlDocApp.sideMenuOpened">
    <app-side-menu>
    </app-side-menu>
</ngx-nav-drawer>
<div *ngIf="htmlDocApp.sideMenuOpened && currentTheme == 'merch'" (clickOutside)="closeMenuSidebar()"
  style="z-index: 111111;"
  class="fixed top-0 flex h-screen w-full max-w-[350px] flex-col justify-between overflow-y-auto bg-white shadow-three"
  [class.right-0]="isRtl" [class.left-0]="!isRtl">
  <app-side-menu>
  </app-side-menu>
</div>

<div *ngIf="currentTheme == 'merch' && multiCurrenciesEnabled" class="float relative z-20"
  [style.color]="currenciesMenuFrontColor" [style.background]="currenciesMenuColor">
  <button style="display: flex;" [class.text-left]="!isRtl" [class.text-right]="isRtl"
    class="items-center px-1 text-left md:flex" (click)="cuurenciesMenuOpen = !cuurenciesMenuOpen">
    <span *ngIf="currnetCurrnecy.flagImage" class="mx-1 max-w-[40px] overflow-hidden">
      <img style="height: 30px;" class="header-logo w-full" [src]="currnetCurrnecy.flagImage" alt="flag" />
    </span>
    <span class="text-md font-medium">
      {{entityUtilityService.getlocalSymbol(currnetCurrnecy)}}
    </span>
    <span class="px-2">
      <svg width="12" height="7" viewBox="0 0 12 7" class="fill-current">
        <path
          d="M0.564864 0.879232C0.564864 0.808624 0.600168 0.720364 0.653125 0.667408C0.776689 0.543843 0.970861 0.543844 1.09443 0.649756L5.82517 5.09807C5.91343 5.18633 6.07229 5.18633 6.17821 5.09807L10.9089 0.649756C11.0325 0.526192 11.2267 0.543844 11.3502 0.667408C11.4738 0.790972 11.4562 0.985145 11.3326 1.10871L6.60185 5.55702C6.26647 5.85711 5.73691 5.85711 5.41917 5.55702L0.670776 1.10871C0.600168 1.0381 0.564864 0.967492 0.564864 0.879232Z">
        </path>
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M1.4719 0.229332L6.00169 4.48868L10.5171 0.24288C10.9015 -0.133119 11.4504 -0.0312785 11.7497 0.267983C12.1344 0.652758 12.0332 1.2069 11.732 1.50812L11.7197 1.52041L6.97862 5.9781C6.43509 6.46442 5.57339 6.47872 5.03222 5.96853C5.03192 5.96825 5.03252 5.96881 5.03222 5.96853L0.271144 1.50833C0.123314 1.3605 -5.04223e-08 1.15353 -3.84322e-08 0.879226C-2.88721e-08 0.660517 0.0936127 0.428074 0.253705 0.267982C0.593641 -0.0719548 1.12269 -0.0699964 1.46204 0.220873L1.4719 0.229332ZM5.41917 5.55702C5.73691 5.85711 6.26647 5.85711 6.60185 5.55702L11.3326 1.10871C11.4562 0.985145 11.4738 0.790972 11.3502 0.667408C11.2267 0.543844 11.0325 0.526192 10.9089 0.649756L6.17821 5.09807C6.07229 5.18633 5.91343 5.18633 5.82517 5.09807L1.09443 0.649756C0.970861 0.543844 0.776689 0.543843 0.653125 0.667408C0.600168 0.720364 0.564864 0.808624 0.564864 0.879232C0.564864 0.967492 0.600168 1.0381 0.670776 1.10871L5.41917 5.55702Z">
        </path>
      </svg>
    </span>
  </button>

  <div (clickOutside)="cuurenciesMenuOpen=false" *ngIf="cuurenciesMenuOpen" style="top: -270px;"
    class="absolute top-full right-0 mb-5 w-[330px]">
    <div class="currencies-list rounded-lg border border-[#e7e7e7] bg-white p-2 shadow-card">
      <div class="border-b border-[#e7e7e7] pb-3">
        <div *ngFor="let currency of storeCurrencies"
          class="currencies-item w-full -mx-1 flex items-center justify-between pb-4">
          <div (click)="onChangeCurrency(currency.id)" class="flex items-center px-1">
            <div class="mx-3 max-w-[40px] overflow-hidden rounded">
              <img *ngIf="currency.flagImage" width="50px" height="50px"
                [src]="currency.flagImage" alt="currency flag" class="w-full">
            </div>
            <div class="w-full">
              <p class="text-lg font-medium text-black">
                {{entityUtilityService.getlocalName(currency)}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #currenciesLoadingModalRef>
  <div class="modal-content">
    <!--body-->
    <div class="modal-body">
      <div class="header-loading-container">
        {{translations?.Loading}}
        <div class="header-loading">
          <div class="header-loader" [style.border-color]="storeDesignOptions?.color"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-item-quick-view *ngIf="currentTheme == 'anvogue' && htmlDocApp.showItemQuickView"
  [itemLink]="htmlDocApp.itemQuickViewLink" (close)="closeItemQuickView()"></app-item-quick-view>