import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DeliveryTypeAndTimeComponent } from './delivery-type-and-time.component';
import { OutletListModule } from '../outlet-list/outlet-list.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AddressCardModule } from '../address-card/address-card.module';
import { DeliveryMethodCardModule } from '../delivery-method-card/delivery-method-card.module';
import { DefaultDeliveryTypeAndTimeModule } from '@storefront/ng.themes';
import { ChooseTableModalModule } from '../choose-table-modal/choose-table-modal.module';
import { PickupDatePickerModule } from '../pickup-date-picker/pickup-date-picker.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DeliveryMethodsModule } from 'src/app/pages/delivery-methods/delivery-methods.module';

@NgModule({
  declarations: [DeliveryTypeAndTimeComponent],
  imports: [
    CommonModule,
    OutletListModule,
    SharedModule,
    AddressCardModule,
    DeliveryMethodCardModule,
    DefaultDeliveryTypeAndTimeModule,
    ChooseTableModalModule,
    PickupDatePickerModule,
    ReactiveFormsModule,
    DeliveryMethodsModule
  ],
  providers: [
    DatePipe
  ],
  exports: [DeliveryTypeAndTimeComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA 
  ],
})
export class DeliveryTypeAndTimeModule {}
