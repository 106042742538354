import { Theme } from 'src/app/shared/models/theme';

let theme = new Theme();
theme.name = 'restaurants';
theme.addStyleDependency(
  'restaurants-tailwind',
  '/assets/tailwind.min.css',
  '3.0.1',
  'tailwind'
);

theme.addScriptDependency('restaurants-script', 'https://cdn.tailwindcss.com');

// theme.addStyleDependency(
//   'restaurants-flowrift',
//   'https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.2.0/flowbite.min.css'
// );

theme.addFontDependency('tailwind-font', 'Inter');
export const restaurantsTheme = theme;
