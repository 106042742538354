import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { DefaultLoadingModule } from '@storefront/ng.themes';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@NgModule({
  declarations: [LoadingComponent],
  imports: [CommonModule, SharedModule, DefaultLoadingModule],
  exports: [LoadingComponent],
})
export class LoadingModule {}
