import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { DefaultProgressBarModule } from '@storefront/ng.themes';

@NgModule({
  declarations: [ProgressBarComponent],
  imports: [CommonModule, SharedModule, DefaultProgressBarModule],
  exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
