import { StoreAppService } from "@storefront/ng.core";
import { TajerPlugin } from "./core/tajer-plugin";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
    providedIn: 'root',
})
export class GoogleTagManagerPlugin extends TajerPlugin {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private storeAppService: StoreAppService) {
        super();
    }

    override init(appData: Record<string, any>): void {
        super.init(appData);

        //add google analytics
        this.addGoogleTagManagerScript(this.storeAppService.storeConfig.externalServices?.googleTagManagerId);

        // Register event handlers
    }

    addGoogleTagManagerScript(googleTagManagerId: string) {
        var script = this.document.createElement('script');
        const googleManagerScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagManagerId}');`;
        script.innerHTML = googleManagerScript;
        this.document.head.appendChild(script);

        var noscript = this.document.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        var s = this.document.body.firstChild;
        s.parentNode.insertBefore(noscript, s);
    }
}
