<lib-default-subscribe-form
  *ngIf="currentTheme != 'merch' && currentTheme != 'shopper' && currentTheme != 'restaurants'"
  [desginOptions]="desginOptions" [configuration]="configuration" [translations]="translations"
  [showAsGrid]="showAsGrid" [subscribeLoading]="subscribeLoading" [subscribeDone]="subscribeDone" [error]="error"
  [headColor]="headColor" [frontColor]="frontColor" (subscribtion)="subscribe($event)"
  (rstSubDone)="resetSubDone($event)">
</lib-default-subscribe-form>

<!-- ====== Merch Subscribe-form Section Start -->
<lib-merch-subscribe-form *ngIf="currentTheme == 'merch'" [designOptions]="desginOptions" [merchConfiguration]="merchConfiguration"
  [translations]="translations" [subscribeLoading]="subscribeLoading" [subscribeDone]="subscribeDone" [error]="error"
  [headColor]="headColor" [currentLang]="currentLang" (subscribtion)="subscribe($event)">
</lib-merch-subscribe-form>

<!-- ====== Shopper Subscribe-form Section Start -->
<lib-shopper-subscribe-form *ngIf="currentTheme == 'shopper'" [shopperConfiguration]="shopperConfiguration"
  [translations]="translations" [subscribeLoading]="subscribeLoading" [subscribeDone]="subscribeDone" [error]="error"
  [isRtl]="isRtl" [headColor]="headColor" (subscribtion)="subscribe($event)">
</lib-shopper-subscribe-form>

<!-- ====== Restaurants Subscribe-form Section Start -->
<lib-restaurants-subscribe-form *ngIf="currentTheme == 'restaurants'"
  [restaurantsDesginOptions]="restaurantsDesginOptions"
  [restaurantsConfiguration]="restaurantsConfiguration"
  [footerFrontColor]="footerFrontColor"
  [translations]="translations"
  [subscribeLoading]="subscribeLoading"
  [subscribeDone]="subscribeDone"
  [error]="error"
  [headColor]="headColor"
  (subscribtion)="subscribe($event)"
>
</lib-restaurants-subscribe-form>